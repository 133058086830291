import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type EmailFromScratchState = {
  emailType?: string;
  chosenSeason?: string;
  events?: string;
  promoOfferDiscount?: string;
  promotionDuration?: string;
  emailSubject?: string;
  emailLength?: string;
  cta?: string;
};

const initialState: EmailFromScratchState = {
  emailType: '',
  chosenSeason: '',
  events: '',
  promoOfferDiscount: '',
  promotionDuration: '',
  emailSubject: '',
  emailLength: '',
  cta: '',
};

const emailFromScratchSlice = createSlice({
  name: 'emailFromScratch',
  initialState,
  reducers: {
    setEmailScratchInfo: (
      state,
      { payload }: PayloadAction<EmailFromScratchState>
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setEmailScratchInfo } = emailFromScratchSlice.actions;

export default emailFromScratchSlice.reducer;
