/* eslint-disable no-case-declarations */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import Zoom from 'chartjs-plugin-zoom';
import {
  addDays,
  format,
  getDaysInMonth,
  lastDayOfMonth,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import React from 'react';
import { Line } from 'react-chartjs-2';

import { StatsProps } from '@/shared/types/affiliate';

export const getDaysForPeriod = (period: string) => {
  const today = new Date();
  switch (period) {
    case 'this_month':
      return getDaysInMonth(today);
    case 'last_month':
      return getDaysInMonth(subMonths(today, 1));
    case 'last_90_days':
      return 90;
    default:
      return getDaysInMonth(today);
  }
};

export function LineChart({ invited, trial, paid, period }: StatsProps) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Zoom
  );

  const duration = getDaysForPeriod(period);
  function setInitialAndLastLabels(index: number) {
    const today = new Date();
    const durationValue = duration - 1;
    const dateFormat = 'dd-MM-yyyy';
    const lastMonth = subMonths(today, 1);

    const getPeriodDate = (startDate: Date, endDate: Date) =>
      index === durationValue
        ? format(lastDayOfMonth(endDate), dateFormat)
        : format(startOfMonth(startDate), dateFormat);

    switch (period) {
      case 'this_month':
        return getPeriodDate(today, today);
      case 'last_month':
        return getPeriodDate(lastMonth, lastMonth);
      case 'last_90_days':
        return index === durationValue
          ? format(lastDayOfMonth(today), dateFormat)
          : format(subDays(lastDayOfMonth(today), durationValue), dateFormat);
      default:
        return '';
    }
  }

  const tooltipDateFromIndex = (label: string) => {
    const labelNumber = parseInt(label, 10);
    const today = new Date();
    let firstDay = startOfMonth(today);
    if (period === 'last_month') {
      firstDay = startOfMonth(subMonths(today, 1));
    }
    if (period === 'last_90_days') {
      firstDay = subDays(lastDayOfMonth(today), 89);
    }

    return format(addDays(firstDay, labelNumber), 'MMMM do');
  };

  const options: any = {
    scaleShowValues: true,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
        grid: {
          color: '#262626',
        },
      },
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          callback: (_val: string, index: number) => {
            if (index % (duration - 1) !== 0) return '';
            return setInitialAndLastLabels(index);
          },
        },
        grid: {
          color: '#262626',
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          pinch: {
            enabled: true,
          },
          wheel: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          title(context: Record<string, any>[]) {
            const { label } = context?.[0] ?? {};
            return tooltipDateFromIndex(label);
          },
        },
      },
    },
  };

  const labels = Array.from({ length: duration }, (_, i) => i);
  const data = {
    labels,
    datasets: [
      {
        label: 'Paid',
        data: paid ?? [],
        borderColor: 'rgb(94, 196, 53)',
        backgroundColor: 'rgba(94, 196, 53, 0.5)',
      },
      {
        label: 'Trial',
        data: trial ?? [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Invited',
        data: invited ?? [],
        borderColor: 'rgb(162, 56, 213)',
        backgroundColor: 'rgba(162, 56, 213, 0.5)',
      },
    ],
  };

  return <Line options={options} data={data} className="!w-full !h-auto" />;
}
export default LineChart;
