/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretDown } from 'react-icons/fa';
import { GrLanguage } from 'react-icons/gr';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuGroup,
} from '@/components/common/DropdownMenu';

function LanguageButton() {
  const { i18n } = useTranslation();
  const languages: Record<string, any> = {
    fr: { name: 'French' },
    en: { name: 'English' },
  };
  return (
    <div className="lg:mr-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            className="border border-primary/50 px-3 py-2 text-sm border-solid rounded-lg outline-none bg-transparent text-white cursor-pointer flex items-center"
            type="button"
          >
            <GrLanguage className="svg:!border-none mr-3" />{' '}
            {i18n?.language === 'en' ? 'English' : 'French'}
            <FaCaretDown className="fill-white ml-2" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56 bg-black">
          <DropdownMenuGroup>
            {Object.keys(languages).map((language) => (
              <DropdownMenuItem
                key={language}
                onClick={() => {
                  // Personal language prefer
                  localStorage.setItem('language_prefer', language);
                  i18n.changeLanguage(language);
                  // currency changes base on language (USD and EURO)
                }}
                disabled={i18n.resolvedLanguage === language}
                className={`${i18n.resolvedLanguage !== language ? `text-white focus:bg-defaultBlack focus:text-white` : `!opacity-100 hover: bg-primary text-white`}`}
              >
                {languages[language].name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export default LanguageButton;
