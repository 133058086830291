import { apiSlice } from './apiSlice';

export interface termAndLegal {
  siteUrl: string;
  lastUpdate: string;
  siteOperatorType: string;
  productType: string;
  returnCostOn: string;
  operatorPhone: string;
  customerServiceHours: string;
  customerServiceEmail: string;
  mediatorContract: string;
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  companyName: string;
  amountOfShare: string;
  PlaceOfRCS: string;
  RSCNumber: string;
  officeAddress: string;
  representativeFullName: string;
  VATNumber: string;
  hostingProviderName: string;
  hostingProviderFullAddress: string;
  hostingProviderPhone: string;
  directorFullName: string;
  siteOperatorPhone: string;
  siteOperatorEmail: string;
  postalAddress: string;
}

const applicationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApplication: builder.query<any, any>({
      query: () => ({
        url: `application`,
        method: 'GET',
      }),
    }),
    postTermAndLegal: builder.mutation<termAndLegal, any>({
      query: (credentials) => ({
        url: 'application/create',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useLazyGetApplicationQuery, usePostTermAndLegalMutation } =
  applicationSlice;
