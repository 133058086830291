import {
  RadioGroupItemProps,
  RadioGroupItem,
  Indicator,
  RadioGroup,
  RadioGroupProps,
} from '@radix-ui/react-radio-group';
import { format } from 'date-fns';
import uniqueId from 'lodash/uniqueId';
import words from 'lodash/words';
import * as React from 'react';
import { useState } from 'react';

import { EditedLabelItem } from './EditedLabelItem';

import { Badge } from '../common/Badge';
import { Input } from '../common/Input';
import { Label } from '../common/Label';

import { cn } from '@/lib/utils';

interface EditedRadioGroupProps extends RadioGroupProps {
  onSelectChange: (value: string) => void;
  separator?: string;
  selectValue: string;
}

interface EditedRadioItemProps extends RadioGroupItemProps {
  separator?: string;
  textField?: boolean;
  badge?: any;
  dateField?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onSelectChange?: (value: string) => void;
}

const EditedRadioGroup = React.forwardRef(
  (
    { className, onSelectChange, selectValue, ...props }: EditedRadioGroupProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <RadioGroup
      className={cn('grid gap-2', className)}
      {...props}
      value={selectValue}
      onValueChange={(value) => {
        onSelectChange(value);
      }}
      ref={ref}
    />
  )
);

const EditedRadioItem = React.forwardRef(
  (
    {
      className,
      id,
      value,
      separator = '~',
      textField = false,
      badge,
      dateField = false,
      inputProps,
      onSelectChange,
      ...props
    }: EditedRadioItemProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const [itemValue, setItemValue] = useState(value);

    const valueParts = (valueString: string) =>
      /*
       * This part will split this sentence
       *    During %12 hours %23 minutes
       * to
       *    ['During', '%12', 'hours', '%23', 'minutes']
       */
      words(valueString, /[^, ]+/g);

    const updatedValue = (inputValue: string, index: number) => {
      const updateValue = valueParts(itemValue);
      updateValue[index] = `${separator}${inputValue}`;
      setItemValue(updateValue.join(' '));
      if (onSelectChange) onSelectChange(updateValue.join(' '));
    };

    const handleInputValueChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      index: number
    ) => {
      const { value: inputValue } = event.target;
      if (!inputValue || inputValue === '') return;
      updatedValue(inputValue, index);
    };

    const handleTextfieldChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { value: inputValue } = event.target;
      setItemValue(inputValue ?? '');
      if (onSelectChange) onSelectChange(inputValue ?? '');
    };

    const handleDateValueChange = (day: Date, index: number) => {
      const dateString = format(day, 'dd/MM/yyyy');
      updatedValue(dateString, index);
    };
    return (
      <Label
        htmlFor={id}
        className="flex text-white items-center py-3 px-5 border-solid rounded-xl border-2 border-neutral-800 mb-2 cursor-pointer text-sm"
      >
        <RadioGroupItem
          ref={ref}
          className={cn(
            'relative aspect-square h-7 w-7 rounded-full border-[3px] border-white border-solid text-primary shadow-none bg-transparent outline-none drop-shadow-none disabled:cursor-not-allowed disabled:opacity-50 mr-3',
            className
          )}
          value={itemValue}
          id={id}
          {...props}
        >
          <Indicator className="bg-primary h-full w-full rounded-full top-0 left-0 absolute" />
        </RadioGroupItem>
        {textField ? (
          <Input
            type="text"
            value={itemValue}
            onChange={handleTextfieldChange}
            className="p-0 m-0 h-5 border-0 border-b rounded-none indent-0 border-dotted border-zinc-700 w-full bg-white bg-opacity-10 text-white outline-none text-sm font-bold"
            {...inputProps}
          />
        ) : (
          valueParts(itemValue).map((text, index) => (
            <EditedLabelItem
              key={uniqueId()}
              value={text}
              index={index}
              handleDateValueChange={handleDateValueChange}
              handleInputValueChange={handleInputValueChange}
              separator={separator}
              dateField={dateField}
              {...inputProps}
            />
          ))
        )}
        {badge && <Badge {...badge}>{badge.text}</Badge>}
      </Label>
    );
  }
);
EditedRadioItem.displayName = RadioGroupItem.displayName;

export { EditedRadioItem, EditedRadioGroup };
