/* eslint-disable import/prefer-default-export */
export const subscriptionPlans = [
  {
    name: 'Starter',
    monthlyPrice: 19.99,
    monthlyPriceUSD: 19.99,
    annualPrice: 9.99,
    annualPriceUSD: 9.99,
    features: [
      3,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    name: 'Standard',
    monthlyPrice: 29.99,
    monthlyPriceUSD: 29.99,
    annualPrice: 14.99,
    annualPriceUSD: 14.99,
    features: [
      10,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    name: 'Pro',
    monthlyPrice: 59.99,
    monthlyPriceUSD: 59.99,
    annualPrice: 29.99,
    annualPriceUSD: 29.99,
    features: [
      50,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
  },
  {
    name: 'Lifetime',
    originalPrice: 599.99,
    originalPriceUSD: 599.99,
    discountPrice: 239.99,
    discountPriceUSD: 239.99,
    features: [
      9999,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
  },
];
