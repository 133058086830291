import { logsTheActions } from './logs.service';

import { apiErrorHandler } from '../utils/helpers';

export const cancelSubscription = async (userId) => {
  const payload = {
    user: {
      id: userId,
    },
  };
  const logPayload = {
    page: window.location.href,
    functionName: 'cancelSubscription',
    payload,
  };
  try {
    const data = await fetch(
      `${process.env.VITE_APP_API_URL}/subscription/cancel`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('productly')}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const response = await data.text();
    logsTheActions({
      ...logPayload,
      apiResult: response,
    });
    return data;
  } catch (error) {
    logsTheActions({
      ...logPayload,
      apiResult: error,
    });
    apiErrorHandler(error);
  }
  return null;
};

export default cancelSubscription;
