import { uniqueId } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import type { Swiper as SwiperType } from 'swiper';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { Button } from '@/components/common/shadcn-ui/Button';
import { RootState } from '@/shared/reduxStore';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';

function ImportProductImages() {
  const { currentProductId } = useSelector(
    (state: RootState) => state?.products
  );

  const { data: shopifyTemplateData } = useGetShopifyTemplateDataQuery(
    {
      productId: currentProductId,
    },
    {
      skip: !currentProductId,
    }
  );

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const sliderRef = useRef<SwiperRef>(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="col-span-12 w-full xl:col-span-6">
      <div className="flex w-full flex-col items-center justify-center">
        <Swiper
          loop
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="h-[300px] w-full xs:max-w-[300px]"
        >
          {shopifyTemplateData.productImages.map((image: string) => (
            <SwiperSlide className="text-center" key={uniqueId('image')}>
              <img className="h-full" src={image} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="w-full flex items-center mt-4">
          <Button
            className="bg-transparent border-0 hover:bg-transparent shadow-none px-2"
            onClick={handlePrev}
          >
            <MdKeyboardArrowLeft className="text-xl" />
          </Button>
          <Swiper
            ref={sliderRef}
            onSwiper={setThumbsSwiper}
            freeMode
            slidesPerView={3}
            watchSlidesProgress
            modules={[FreeMode, Navigation, Thumbs]}
            className="h-[100px] w-full xs:max-w-[300px] [&_.swiper-slide-thumb-active]:!opacity-100"
          >
            {shopifyTemplateData.productImages.map((image: string) => (
              <SwiperSlide
                className="opacity-40 text-center border-0"
                key={uniqueId('thumb')}
              >
                <img
                  className="h-[100px] w-[100px] bg-no-repeat bg-contain bg-center"
                  // eslint-disable-next-line react/forbid-dom-props
                  style={{ backgroundImage: `url(${image})` }}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Button
            className="bg-transparent border-0 hover:bg-transparent shadow-none px-2"
            onClick={handleNext}
          >
            <MdKeyboardArrowRight className="text-xl" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ImportProductImages;
