/* eslint-disable jsx-a11y/control-has-associated-label */

import { useCopyToClipboard } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Dashboard } from './Dashboard';
import { RefererManage } from './RefererManage';

import { useGetUserRefererQuery } from '@/shared/slices/affiliateSlice';

export function AffiliateMain() {
  const [_, copyToClipboard] = useCopyToClipboard();
  const handleCopy = (value: string) => {
    if (value !== '') {
      copyToClipboard(value);
      toast.success('Copié dans le presse-papiers avec succès !');
    }
  };
  const [period, setPeriod] = useState<string>('this_month');
  const { data: refererData, isLoading } = useGetUserRefererQuery({
    period,
  });

  const handlePeriodChange = (value: string) => {
    if (value === '') return;
    setPeriod(value);
  };

  const stats = refererData?.stats ?? undefined;
  const coupons = refererData?.coupons ?? undefined;

  return (
    <div className="grid gap-4 pb-10 mt-10 text-white border border-solid rounded-lg sm:grid-cols-1 md:grid-cols-2 border-grayLight">
      <Dashboard
        stats={stats}
        period={period}
        onPeriodChange={handlePeriodChange}
      />
      <RefererManage
        isLoading={isLoading}
        coupons={coupons ?? []}
        handleCopy={handleCopy}
      />
    </div>
  );
}

export default AffiliateMain;
