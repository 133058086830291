/* eslint-disable react/no-danger */
import React from 'react';

interface ProductHeaderProps {
  name: string;
  utility: string;
  image_url?: string;
}

function ProductHeader({
  name,
  utility,
  image_url: imageUrl,
}: ProductHeaderProps) {
  return (
    <div className="product-container">
      <div className="product-text">
        <h1 className="product-name-head">{name}</h1>
        <p>{utility}</p>
      </div>
      {imageUrl && <img src={imageUrl} alt={name} className="product-image" />}
    </div>
  );
}

export default ProductHeader;
