import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import ArrowToRightIcon from '../common/svg/ArrowToRight';

interface renderAccessButtonProps {
  className?: string;
  iconSize: string;
}

function RenderAccessButton({ className, iconSize }: renderAccessButtonProps) {
  // If the current location is "/app", don't render the button or render something else
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const location = useLocation();

  if (location?.pathname?.includes('app')) {
    return (
      <NavLink
        to="/account"
        className={`bg-accessHeaderAppButton flex justify-center items-center no-underline cursor-pointer px-6 py-3 text-lg rounded-xl border-solid border border-accessHeaderAppBorderButton ${className}`}
      >
        {t('accessAccount')}
        <span className="flex ms-1">
          <ArrowToRightIcon
            className="fill-white stroke-1 stroke-black"
            iconSize={iconSize}
          />
        </span>
      </NavLink>
    ); // or return null to hide
  }
  return (
    <NavLink
      to="/app"
      className={`flex justify-center items-center no-underline cursor-pointer px-6 py-3 text-lg rounded-xl bg-access-header-button ${className}`}
    >
      {t('access')}
      <span className="flex ms-1">
        <ArrowToRightIcon className="fill-white" iconSize={iconSize} />
      </span>
    </NavLink>
  );
}

export default RenderAccessButton;
