import { useCallback, useEffect, useState } from 'react';

function useCountDown(number: number) {
  const [timeDown, setTimeDown] = useState<number>(number || 0);

  const resendEmailTimeOutCallback = useCallback(
    () => setTimeDown((currTimer: number) => currTimer - 1),
    []
  );

  useEffect(() => {
    if (timeDown && timeDown > 0) {
      setTimeout(resendEmailTimeOutCallback, 1000);
    }
  }, [timeDown, resendEmailTimeOutCallback]);

  return { timeDown, setTimeDown };
}

export default useCountDown;
