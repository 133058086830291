import Animation from 'components/common/Animation';
import Button from 'components/common/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '../../components/Header';

import { removeCredential } from '@/shared/reducers/authReducer';
import { RootState } from '@/shared/rootReducers';

function ChangePasswordPage() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const { user } = useSelector((state: RootState) => state?.auth);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    setEmail(user.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage(
        'Le nouveau mot de passe et le mot de passe de confirmation ne sont pas identiques'
      );
      return;
    }

    const token = localStorage.getItem('productly');
    try {
      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/auth/change-password`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email,
            oldPassword,
            newPassword,
            confirmPassword,
          }),
        }
      );

      if (response.status === 200) {
        dispatch(removeCredential());
        navigate('/login');
        toast.success(`Le mot de passe a été modifie avec succès`);
      } else {
        const error = await response.text();
        setErrorMessage(error);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Une erreur est survenue');
    }
  };

  return (
    <div>
      <div className="absolute w-full h-full pointer-events-none bg-change-password-gradient-background" />
      <Header />
      <Animation className="form-container">
        <div className="form-parent">
          <form className="form-log-reg">
            <h3>Changement de mot de passe</h3>
            <input
              type="password"
              id="oldPassword"
              name="oldPassword"
              placeholder="Mot de passe actuel"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              placeholder="Nouveau mot de passe"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              placeholder="Confirmer le nouveau mot de passe"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              type="button"
              text="Modifier let mot de passe"
              className="submit-button"
              onClick={handleChangePassword}
            />
            <Button
              type="button"
              text="Annuler"
              className="submit-button"
              onClick={() => navigate('/account')}
            />
            {errorMessage && <div className="error-box">{errorMessage}</div>}
          </form>
        </div>
      </Animation>
    </div>
  );
}

export default ChangePasswordPage;
