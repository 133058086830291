/* eslint-disable react/no-danger */
import React, { ChangeEvent, useState } from 'react';
import { MdModeEdit } from 'react-icons/md';

import { FormFieldsSettingProps } from '../app/shopify/ImportTemplate';
import { Input } from '../common/Input';
import { InputDiv } from '../common/InputDiv';
import { Button } from '../common/shadcn-ui/Button';
import { Textarea } from '../common/shadcn-ui/Textarea';

import cn from '@/utils/cn';

interface EditableTextProps extends FormFieldsSettingProps {
  onInputChange: ({ value, id, name }: any) => void;
}

function EditableText({
  id,
  name,
  text,
  textClass,
  editFieldClass,
  displayBtnClass,
  inputType = 'input',
  onInputChange,
  editable = true,
}: EditableTextProps) {
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState('');
  const [inputChange, setInputChange] = useState<Record<string, any>>({});

  const handleValueChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { value: inputValue, id: inputId, name: inputName } = event.target;
    if (!inputValue || inputValue === '') {
      inputValue =
        event.currentTarget.innerText.split('\n').join('</br>') ?? '';
      inputId = (event.currentTarget?.id as string) ?? '';
      inputName =
        (event.currentTarget?.getAttribute('data-name') as string) ?? '';
    }
    if (!inputValue || inputValue?.length <= 0) {
      setError('This field cannot be empty');
      return;
    }
    setError('');
    setInputChange({ value: inputValue, id: inputId, name: inputName });
  };
  return (
    <div>
      {!editable && (
        <div
          className={cn(
            textClass,
            inputType === 'input' && 'text-defaultBlack h-auto mt-2',
            inputType === 'textarea' && 'box-border mt-2 border'
          )}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
      {editable && !edit && (
        <Button
          type="button"
          onClick={() => setEdit(true)}
          className={cn(
            'pt-3 border-0 text-wrap text-left p-0 m-0 bg-transparent hover:bg-transparent shadow-none cursor-pointer h-fit',
            displayBtnClass
          )}
        >
          <div className="flex hover:border hover:border-dashed hover:border-zinc-400 [&>_svg]:hover:flex">
            <p
              className={cn('w-fit m-0', textClass)}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
            <MdModeEdit className="text-lg text-primary hidden ml-2 w-[24px]" />
          </div>
        </Button>
      )}
      {edit && (
        <>
          {inputType === 'input' && (
            <Input
              className={cn(
                'text-defaultBlack indent-3 h-auto mt-2',
                editFieldClass
              )}
              defaultValue={text}
              onChange={handleValueChange}
              id={id}
              name={name}
            />
          )}
          {inputType === 'textarea' && (
            <Textarea
              className={cn('box-border mt-2 border', editFieldClass)}
              defaultValue={text}
              onChange={handleValueChange}
              id={id}
              name={name}
            />
          )}
          {inputType === 'contentEditable' && (
            <InputDiv
              id={id}
              data-name={name}
              maxLength={500}
              className={cn(
                'box-border mt-2 border border-solid p-2 outline-none text-sm',
                editFieldClass
              )}
              onBlur={handleValueChange}
              value={
                <span
                  className={cn('w-fit m-0', textClass)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              }
            />
          )}
          <span className="text-red-600 text-xs">{error}</span>
          <div className="flex justify-end mt-2">
            <Button
              type="button"
              onClick={() => {
                setEdit(false);
                setError('');
              }}
              className="text-zinc-600 border-0 bg-zinc-200 hover:bg-zinc-100 text-xs py-2 h-fit shadow-none mr-2"
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                setEdit(false);
                if (!inputChange) return;
                setError('');
                onInputChange(inputChange);
              }}
              className="text-white border-0 text-xs py-2 h-fit shadow-none"
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default EditableText;
