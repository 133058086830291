import clsx from 'clsx';
import React from 'react';

function ReloadSvgIcon({ isLoading }: { isLoading: boolean }) {
  return (
    <svg
      className={clsx(isLoading && 'svg-animate')}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_133_17)">
        <path
          d="M0.916992 3.66667V9.16667H6.41699"
          stroke="#B3B3B3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.083 18.3333V12.8333H15.583"
          stroke="#B3B3B3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7828 8.25C18.3179 6.93622 17.5278 5.76162 16.4861 4.8358C15.4445 3.90998 14.1853 3.26312 12.8261 2.95557C11.4668 2.64802 10.0518 2.68981 8.71307 3.07704C7.37433 3.46427 6.15551 4.18432 5.17033 5.17L0.916992 9.16666M21.0837 12.8333L16.8303 16.83C15.8451 17.8157 14.6263 18.5357 13.2876 18.923C11.9489 19.3102 10.5338 19.352 9.17459 19.0444C7.81534 18.7369 6.55614 18.09 5.5145 17.1642C4.47286 16.2384 3.68273 15.0638 3.21783 13.75"
          stroke="#B3B3B3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_133_17">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReloadSvgIcon;
