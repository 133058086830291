/* eslint-disable @typescript-eslint/no-unused-vars */
import FeatureSection from 'components/FeatureSection';
import React from 'react';

import Faq from '../components/faq';
import Header from '../components/Header';
import PresentationVideo from '../components/PresentationVideo';
import Subscriptions from '../components/subscription';
import TryIt from '../components/tryit';

function Home() {
  return (
    <div>
      <div className="absolute w-full lg:h-[5125px] xs:h-[6800px] sm:h-[5670px] md:h-[5900px] pointer-events-none sm:bg-home-gradient-background-sm lg:bg-home-gradient-background xs:bg-home-gradient-background-xs" />
      <div className="head">
        <Header />
        <FeatureSection />
      </div>
      <TryIt />
      <PresentationVideo />
      {/* <Reviews /> */}
      <Subscriptions />
      <Faq />
    </div>
  );
}

export default Home;
