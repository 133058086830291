import React from 'react';

import ConnectInstruction from '@/components/app/shopify/connect/Instruction';

export enum StepsIndifier {
  APP_SETTING = 'appSetting',
  CREATE_APP = 'createApp',
  CONFIG_APP = 'configApp',
  INSTALL_APP = 'installApp',
  GET_KEY = 'getKey',
}

export const instructionSteps = ({ t }: any) => [
  {
    id: StepsIndifier.APP_SETTING,
    title: 'Apps',
    name: t('shopify.wizardStepStarting'),
    component: <ConnectInstruction step={StepsIndifier.APP_SETTING} />,
    percengate: 0,
  },
  {
    id: StepsIndifier.CREATE_APP,
    title: 'Create App',
    name: t('shopify.wizardStepCreateApp'),
    component: <ConnectInstruction step={StepsIndifier.CREATE_APP} />,
    percengate: 30,
  },
  {
    id: StepsIndifier.CONFIG_APP,
    title: 'Config',
    name: t('shopify.wizardStepAppConfig'),
    component: <ConnectInstruction step={StepsIndifier.CONFIG_APP} />,
    percengate: 60,
  },
  {
    id: StepsIndifier.INSTALL_APP,
    title: 'Install',
    name: t('shopify.wizardStepAppInstall'),
    component: <ConnectInstruction step={StepsIndifier.INSTALL_APP} />,
    percengate: 90,
  },
  {
    id: StepsIndifier.GET_KEY,
    title: 'Finish',
    name: t('shopify.wizardStepGetTheKey'),
    component: <ConnectInstruction step={StepsIndifier.GET_KEY} />,
    percengate: 100,
    hideNextStepBtn: true,
  },
];
