import React from 'react';

import Skeleton from '../common/Skeleton';
import TextLoadingState from '../common/TextLoadingState';

function LoadingSkeleton() {
  return (
    <div>
      <div className="product-container">
        <div className="flex justify-between items-center space-x-4 opacity-20 product-text">
          <div className="product-name-head w-150 md:w-200 xl:w-350 space-y-2">
            <Skeleton className="w-full h-12 mb-4" />
            <Skeleton className="w-full h-6" />
          </div>
          <Skeleton className="h-44 w-44" />
        </div>
      </div>
      <div className="max-w-[800px]">
        <TextLoadingState />
      </div>
    </div>
  );
}

export default LoadingSkeleton;
