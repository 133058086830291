import { apiSlice } from './apiSlice';

import { EarningProps, RefererDataApiProps } from '../types/affiliate';

const affiliateSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserReferer: build.query<RefererDataApiProps, { period: string }>({
      query: ({ period }) => ({
        url: `referer/detail/?period=${period}`,
        method: 'GET',
      }),
    }),
    getUserEarning: build.query<EarningProps, { month: string }>({
      query: ({ month }) => ({
        url: `referer/earning/?month=${month}`,
        method: 'GET',
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetUserRefererQuery, useGetUserEarningQuery } =
  affiliateSlice;
