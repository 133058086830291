import { useLocalStorage } from '@uidotdev/usehooks';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LuLoader2 } from 'react-icons/lu';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Header from '../components/Header';

import Subscriptions from '@/components/subscription/index';
import {
  removeCredential,
  setCredentials,
} from '@/shared/reducers/authReducer';
import { RootState } from '@/shared/rootReducers';

function Upgrade() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state?.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionIdParams = searchParams.get('session_id');
  const [subscriptionIdStorage, setSubscriptionIdStorage] = useLocalStorage<
    string | undefined
  >('subscription_id', undefined);
  const { t } = useTranslation('translation');

  const { subscription_id: subscriptionId } = user || {};
  const refreshSubscriptionInfo = async () => {
    if (!user) return;
    try {
      const tokenResponse = await fetch(
        `${process.env.VITE_APP_API_URL}/generate-new-token`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('productly')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (tokenResponse.status !== 200) {
        throw new Error(t('failTokenGeneration'));
      }
      const { token } = await tokenResponse.json();

      dispatch(setCredentials({ token }));

      const { subscription_id: updatedSubscriptionId } = user;
      if (subscriptionIdStorage !== updatedSubscriptionId) {
        setSubscriptionIdStorage(undefined);
        navigate('/app');
      }
    } catch (error) {
      dispatch(removeCredential());
    }
  };

  useEffect(() => {
    if (!user) return;

    if (sessionIdParams) {
      setSubscriptionIdStorage(subscriptionId);
      setSearchParams('');
    }

    if (typeof subscriptionIdStorage === 'undefined') return;

    const runRefreshSubscriptionInfo = setInterval(async () => {
      await refreshSubscriptionInfo();
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(runRefreshSubscriptionInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionIdStorage, user, sessionIdParams]);

  return (
    <div
      className={clsx(
        typeof subscriptionIdStorage === 'string' ? 'blur-sm' : 'blur-none'
      )}
    >
      <Header />
      <Subscriptions />
      <Modal
        className="modal-container"
        isOpen={typeof subscriptionIdStorage === 'string'}
        overlayClassName="modal-overlay"
      >
        <div className="rounded-lg md:min-w-full max-w-md p-10 px-28 bg-white">
          <div className="animate-pulse">
            <div className="flex text-primary">
              <LuLoader2 className="animate-spin text-lg mt-[2px] mr-2" />
              <h3 className="m-0 mb-2 text-primary">
                {t('upgrade.paymentProcessing')}
              </h3>
            </div>
            <div className="text-sm text-zinc-950">
              {t('upgrade.paymentProcessingWait')}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Upgrade;
