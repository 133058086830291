import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

type AuthState = {
  user?: any;
  token?: any;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token?: string }>
    ) => {
      if (!token) return;
      try {
        localStorage.setItem('productly', token);
        state.user = jwtDecode(token);
        state.token = token;
      } catch (err) {
        console.error(`Can not decode the token at set credentials`);
      }
    },
    getCredential: (state) => {
      const token = localStorage.getItem('productly');
      if (token && token !== '') {
        try {
          state.user = jwtDecode(token);
          state.token = null;
        } catch (err) {
          localStorage.removeItem('productly');
          state.user = null;
          state.token = null;
          console.error(`Can not decode the token at get credentials`);
        }
      }
    },
    removeCredential: (state) => {
      localStorage.removeItem('productly');
      state.user = null;
      state.token = null;
    },
  },
});

export const { setCredentials, getCredential, removeCredential } =
  authSlice.actions;

export default authSlice.reducer;
