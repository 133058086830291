/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React, { useState } from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';

import { InputSentence } from './InputSentence';

import { Calendar } from '../common/Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../common/Popover';

interface EditedLabelItemProps {
  value: any;
  handleDateValueChange: (day: Date, index: number) => void;
  handleInputValueChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  dateField?: boolean;
  separator: string;
  index: number;
  calendarIcon?: boolean;
  className?: string;
}

export function EditedDateField({
  value,
  handleDateValueChange,
  index,
  calendarIcon,
  className,
}: Pick<
  EditedLabelItemProps,
  'value' | 'handleDateValueChange' | 'index' | 'calendarIcon' | 'className'
>) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleContentEditableChange = (e: any) => {
    let inputValue = e.target.textContent.trim();
    const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

    if (regex.test(inputValue)) {
      let [, day, month, year] = inputValue.match(regex);

      // Ensure month is within valid range (1-12)
      month = Math.min(parseInt(month, 10), 12).toString();

      // Ensure day is within valid range for the given month and year
      const maxDays = new Date(year, month, 0).getDate();
      day = Math.min(parseInt(day, 10), maxDays).toString();

      const inputDate: any = new Date(`${year}-${month}-${day}`);

      // Validate the parsed date
      const isValidDate =
        !Number.isNaN(inputDate.getTime()) &&
        parseInt(month, 10) >= 1 &&
        parseInt(month, 10) <= 12 &&
        parseInt(year, 10) >= 1000 &&
        parseInt(year, 10) <= 9999;

      if (isValidDate) {
        setSelectedDate(inputDate);
      }

      // Update the contenteditable element with formatted date
      inputValue = `${day}/${month}/${year}`;
      e.target.textContent = inputValue;
    } else {
      //
    }
  };
  return (
    <Popover>
      <PopoverTrigger
        className={`p-0 w-full px-2 m-0 border-0 border-b border-dotted border-zinc-700 text-center bg-white bg-opacity-10 text-white outline-none text-sm font-semibold cursor-pointer ${className}`}
      >
        <div className="flex justify-between">
          <div
            className="focus:outline-none"
            contentEditable
            suppressContentEditableWarning
            onInput={handleContentEditableChange}
          >
            {value}
          </div>
          {calendarIcon && (
            <div>
              <a className="bg-transparent border-0 hover:bg-transparent">
                <FaRegCalendarAlt className="fill-white" />
              </a>
            </div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={selectedDate}
          defaultMonth={selectedDate}
          onDayClick={handleDateChange}
          onSelect={(day) => {
            if (!day) return;
            handleDateValueChange(day, index);
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

function EditedInputField({
  value,
  handleInputValueChange,
  index,
  ...inputProps
}: Pick<EditedLabelItemProps, 'value' | 'handleInputValueChange' | 'index'> &
  React.InputHTMLAttributes<HTMLInputElement>) {
  const copyOfInputProps = { ...inputProps };
  const extractedVal = value.match(/\d+/i);
  const typeofVal = extractedVal?.[0]
    ? typeof parseInt(extractedVal[0], 10)
    : 'string';

  if (!inputProps?.maxLength) {
    if (typeofVal === 'number') {
      copyOfInputProps.maxLength = 5;
    }
    if (typeofVal === 'string') {
      copyOfInputProps.maxLength = 10;
    }
  }

  return (
    <InputSentence
      initialValue={value}
      {...copyOfInputProps}
      onBlur={(event) => handleInputValueChange(event, index)}
    />
  );
}

// eslint-disable-next-line import/prefer-default-export
export function EditedLabelItem({
  value,
  handleDateValueChange,
  handleInputValueChange,
  dateField,
  separator,
  index,
  ...inputProps
}: EditedLabelItemProps) {
  const isEditedField = value.includes(separator);
  const valueFormatted = value.replace(separator, '');

  if (!isEditedField) {
    return <span className="mr-1">{valueFormatted}</span>;
  }

  return (
    <span className="mr-1">
      {dateField ? (
        <EditedDateField
          value={valueFormatted}
          handleDateValueChange={handleDateValueChange}
          index={index}
        />
      ) : (
        <EditedInputField
          value={valueFormatted}
          handleInputValueChange={handleInputValueChange}
          index={index}
          {...inputProps}
        />
      )}
    </span>
  );
}
