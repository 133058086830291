import _findIndex from 'lodash/findIndex';
import React from 'react';

import { Label } from './Label';
import { Input } from './shadcn-ui/Input';

export interface InputLabelProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  inputRef?: React.Ref<HTMLInputElement>;
}

const InputLabel = React.forwardRef<HTMLInputElement, InputLabelProps>(
  ({
    className,
    type,
    name,
    placeholder,
    value,
    onChange,
    inputRef,
    ...props
  }) => (
    <>
      <Label className="font-bold text-base" htmlFor={name}>
        {name}
      </Label>
      <Input
        id={name}
        name={name}
        type={type}
        className={`${className} w-[-webkit-fill-available] border-solid`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required
        ref={inputRef}
        {...props}
      />
    </>
  )
);

export default InputLabel;
