/* eslint-disable react/no-unused-prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@/components/common/Card';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import {
  OverviewTextData,
  setFromScratch,
} from '@/shared/reducers/fromScratchReducer';
import { RootState } from '@/shared/reduxStore';

interface OverviewProps {
  headerText?: string;
}

function Overview({ headerText }: OverviewProps) {
  const { t } = useTranslation();
  const { projectName, generateService } = useSelector(
    (state: RootState) => state?.fromScratch
  );
  const dispatch = useDispatch();
  const { overViewData } = useSelector((state: RootState) => state.fromScratch);
  const handleProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event?.target ?? {};
    dispatch(setFromScratch({ projectName: value ?? '' }));
  };

  useEffect(() => {
    let stepperNextBtnDisable = true;
    if (projectName !== '' && generateService !== '') {
      stepperNextBtnDisable = false;
    }
    dispatch(setFromScratch({ stepperNextBtnDisable }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, generateService]);

  return (
    <>
      <Label htmlFor="projectName">
        <h2 className="font-normal">{t('scratch.projectName')}</h2>
      </Label>
      <Input
        id="projectName"
        placeholder={t('scratch.projectNamePlaceholder')}
        onBlur={handleProjectNameChange}
        defaultValue={projectName ?? ''}
      />
      <h2 className="font-normal">{headerText ?? t('common.overview')}</h2>
      <Card className="bg-transparent border-white border border-solid border-opacity-30">
        <CardContent className="py-5 text-white">
          {overViewData &&
            overViewData.map(
              ({ title, subTitle }: OverviewTextData, index: number) => (
                <div key={title}>
                  <h3>
                    {index + 1}-{title}
                  </h3>
                  <p>{subTitle}</p>
                </div>
              )
            )}
        </CardContent>
      </Card>
    </>
  );
}

export default Overview;
