import clsx from 'clsx';
import { useIntersectionObserver } from 'components/hooks/useIntersectionObserver';
import _findIndex from 'lodash/findIndex';
import React, { useRef } from 'react';
import { sectionAnimationConfig } from 'utils/config';

interface UseAnimationProps {
  className?: string;
  id?: string;
  children: React.JSX.Element;
}
function Animation({ className, id, children }: UseAnimationProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    ...sectionAnimationConfig,
    freezeOnceVisible: true,
  });

  return (
    <div ref={ref}>
      <div
        className={clsx([
          className,
          `transition-all duration-1000 opacity-100 ease-in-out delay-150 -translate-x-6`,
          !!entry?.isIntersecting && 'opacity-100 translate-x-0',
        ])}
        id={id}
      >
        {children}
      </div>
    </div>
  );
}

export default Animation;
