import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import CreateAp12 from '@/assets/shopify/wizard-create-app-1-2.png';
import CreateAp10 from '@/assets/shopify/wizard-create-app-10.png';
import CreateAp11 from '@/assets/shopify/wizard-create-app-11.png';
import CreateAp12a from '@/assets/shopify/wizard-create-app-12.png';
import CreateAp3 from '@/assets/shopify/wizard-create-app-3.png';
import CreateAp4 from '@/assets/shopify/wizard-create-app-4.png';
import CreateAp56 from '@/assets/shopify/wizard-create-app-5-6.png';
import CreateAp7 from '@/assets/shopify/wizard-create-app-7.png';
import CreateAp89 from '@/assets/shopify/wizard-create-app-8-9.png';
import { StepsIndifier } from '@/shared/configs/shopifyConnectSteppers';

interface ConnectInstructionProps {
  step: StepsIndifier;
}
function ConnectInstruction({ step }: ConnectInstructionProps) {
  const { t } = useTranslation();
  const [swiperInstance, setWwiperInstance] = useState<SwiperClass>();

  let stepData: Record<string, any> = {};
  switch (step) {
    case StepsIndifier.APP_SETTING:
      stepData = {
        texts: [t('shopify.wizardAppSetting1'), t('shopify.wizardAppSetting2')],
        images: [CreateAp12],
      };
      break;
    case StepsIndifier.CREATE_APP:
      stepData = {
        texts: [
          t('shopify.wizardCreateApp3'),
          t('shopify.wizardCreateApp4'),
          t('shopify.wizardCreateApp5'),
          t('shopify.wizardCreateApp6'),
        ],
        images: [CreateAp3, CreateAp4, CreateAp56],
      };
      break;
    case StepsIndifier.CONFIG_APP:
      stepData = {
        texts: [
          t('shopify.wizardConfig7'),
          t('shopify.wizardConfig8'),
          t('shopify.wizardConfig9'),
        ],
        images: [CreateAp7, CreateAp89],
      };
      break;
    case StepsIndifier.INSTALL_APP:
      stepData = {
        texts: [
          t('shopify.wizardInstallApp10'),
          t('shopify.wizardInstallApp11'),
        ],
        images: [CreateAp10, CreateAp11, CreateAp12a],
      };
      break;
    case StepsIndifier.GET_KEY:
      stepData = {
        texts: [
          t('shopify.wizardGetKey12'),
          t('shopify.wizardGetKey13'),
          t('shopify.wizardGetKey14'),
          t('shopify.wizardGetKey15'),
        ],
        images: [CreateAp12a],
      };
      break;
    default:
  }

  useEffect(() => {
    if (!swiperInstance) return;
    swiperInstance.slideTo(0);
  }, [step, swiperInstance]);

  return (
    <div className="[&_strong]:text-primary text-sm">
      <div className="m-0 mb-5 px-7 space-y-1">
        {stepData.texts.map((text: string) => (
          <p
            key={text}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        ))}
      </div>
      <Swiper
        slidesPerView={1}
        pagination={{
          type: 'fraction',
        }}
        navigation
        modules={[Pagination, Navigation]}
        className="w-full ht-fit [&_.swiper-button-next]:text-white [&_.swiper-button-prev]:text-white"
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => setWwiperInstance(swiper)}
      >
        {stepData.images.map((img: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={index}>
            <img
              className="w-full h-full box-border"
              alt="Productly"
              src={img}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ConnectInstruction;
