import { apiSlice } from './apiSlice';

const languageSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLanguage: build.query<{ country: string }, void>({
      query: () => ({
        url: `lang`,
        method: 'GET',
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetLanguageQuery } = languageSlice;
