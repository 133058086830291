/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import HeaderWithTooltip from '@/components/common/HeaderWithTooltip';
import { SelectCustom } from '@/components/common/Select';
import { setEmailScratchInfo } from '@/shared/reducers/emailFromScratchReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { RootState } from '@/shared/rootReducers';
import { useGetCTAQuery } from '@/shared/slices/emailFromScratchSlice';

function CTA() {
  const {
    t,
    i18n: { language: languagei18 },
  } = useTranslation();
  const {
    emailFromScratch: { cta: ctaState },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const [ctaList, setCtaList] = useState<Record<string, any>[]>([]);

  const { data: ctaData } = useGetCTAQuery();

  const handleSelectChange = (value: string) => {
    dispatch(setEmailScratchInfo({ cta: value ?? '' }));
  };

  useEffect(() => {
    if (ctaData) {
      const ctaFormat = ctaData.map((item: Record<string, any>) => ({
        text: item[`text_${languagei18}`],
        value: item.text_en,
      }));
      setCtaList(ctaFormat);
    }
  }, [languagei18, ctaData]);

  useEffect(() => {
    const isDisable = ctaState ? ctaState?.length > 0 : false;
    dispatch(setFromScratch({ stepperNextBtnDisable: !isDisable }));
  }, [ctaState]);

  return (
    <>
      <HeaderWithTooltip tooltipText={t('scratch.ctaTooltip')} text="CTA" />
      <SelectCustom
        optionsData={ctaList as any}
        id="cta"
        inputPlaceholder={t('scratch.ctaPlaceholder')}
        defaultValue={ctaState}
        value={ctaState}
        onSelectData={(value) => handleSelectChange(value)}
        setOptionsData={setCtaList}
        addOption
        keepSelected
        scrollable
      />
      <ReactTooltip
        id="cta-tooltip"
        place="bottom-end"
        content={t('scratch.ctaTooltip')}
        className="!z-[9999] !text-center !text-sm !text-black !font-normal !rounded-xl sm:!px-10 sm:!py-7 !p-5 !bg-white !opacity-100 !max-w-[25rem] !fixed !leading-normal"
      />
    </>
  );
}

export default CTA;
