import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiSolidInfoCircle } from 'react-icons/bi';
import { LuLoader2 } from 'react-icons/lu';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { z } from 'zod';

import useInfluencerFieldsConfigs from './hook/useInfluencerFieldsConfig';
import useTermAndLegalConfigs from './hook/useTermAndLegalFieldsConfigs';
import Influencer from './Influencer';
import TermAndLegal from './TermAndLegal';

import Button from '@/components/common/Button';
import CheckBoxGroupLTR from '@/components/common/CheckBoxGroupLTR';
import { Form } from '@/components/common/Form';
import { setApplicationState } from '@/shared/reducers/applicationReducer';
import { usePostTermAndLegalMutation } from '@/shared/slices/applicationSlice';

function GenerateForm() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'application',
  });
  const { language } = i18n;

  const [term, setTerm] = useState<boolean>(true);
  const [influencer, setInfluencer] = useState<boolean>(false);
  const [resultModalOpen, setResultModalOpen] = useState<boolean>(false);

  const [
    postTermAndLegalData,
    {
      isSuccess: responseSuccess,
      isLoading: termAndLegalCreationLoading,
      isError: termAndLegalCreationError,
    },
  ] = usePostTermAndLegalMutation();

  const {
    individualsFields,
    companyFields,
    specificFields: termAnhLegalSpecificFields,
    termAndLegalFields,
  } = useTermAndLegalConfigs();

  const {
    specificFields: influencerSpecificFields,
    influencerFields,
    influencerIndividualEntity,
    influencerCompanyEntity,
    isSoleTraderField,
  } = useInfluencerFieldsConfigs();

  const [requireTermFields, setRequireTermFields] = useState({});
  const [requireInfluFields, setRequireInfluFields] = useState({});

  useEffect(() => {
    if (term) {
      setRequireTermFields({
        siteOperatorType: z.string(),
        productType: z.string().min(3, {
          message: 'Veuillez fournir le type',
        }),
        returnCostOn: z.string().min(1, {
          message: 'Veuillez fournir le coût de retour à la charge de',
        }),
        operatorPhone: z.string().min(3, {
          message: "Veuillez fournir le numéro de téléphone de l'opérateur",
        }),
        customerServiceHours: z.string().min(3, {
          message: 'Veuillez fournir les heures de service client',
        }),
        customerServiceEmail: z.string().email(),
        mediatorContract: z.string().min(3, {
          message: 'Veuillez fournir le contrat de médiation',
        }),
        title: z.string().optional(),
        firstName: z.string().optional(),
        lastName: z.string().optional(),
        address: z.string().optional(),
        companyName: z.string().optional(),
        amountOfShare: z.string().optional(),
        PlaceOfRCS: z.string().optional(),
        RSCNumber: z.string().optional(),
        officeAddress: z.string().optional(),
        representativeFullName: z.string().min(3, {
          message: 'Veuillez fournir le nom complet du représentant',
        }),
        siteOperatorVAT: z.string().optional(),
        VATNumber: z.string().optional(),
        hostingProviderName: z.string().min(3, {
          message: "Veuillez fournir le nom de l'hébergeur",
        }),
        hostingProviderFullAddress: z.string().min(3, {
          message: "Veuillez fournir l'adresse complète de l'hébergeur",
        }),
        hostingProviderPhone: z.string().min(3, {
          message: "Veuillez fournir le numéro de téléphone de l'hébergeur",
        }),
        directorFullName: z.string().min(3, {
          message: 'Veuillez fournir le nom complet du directeur',
        }),
        siteOperatorPhone: z.string().min(3, {
          message: "Veuillez fournir le numéro de téléphone de l'opérateur",
        }),
        siteOperatorEmail: z.string().email(),
        postalAddress: z.string().min(3, {
          message: "Veuillez fournir l'adresse postale",
        }),
      });
    } else {
      setRequireTermFields({});
    }

    if (influencer) {
      setRequireInfluFields({
        brandOrCompany: z.string().min(3, {
          message: 'Veuillez fournir la marque ou entreprise',
        }),
        corporateName: z.string().min(3, {
          message: "Veuillez fournir le nom de l'entreprise",
        }),
        legalForm: z.string().min(3, {
          message: 'Veuillez fournir la forme juridique',
        }),
        capitalAmount: z.string().min(3, {
          message: 'Veuillez fournir le montant du capital',
        }),
        tradeRegisteredNumberAndCity: z.string().min(3, {
          message: "Veuillez fournir le numéro d'enregistrement et la ville",
        }),
        representativeName: z.string().min(3, {
          message: 'Veuillez fournir le nom du représentant',
        }),
        representativePosition: z.string().min(3, {
          message: 'Veuillez fournir le poste du représentant',
        }),
        isSoleTrader: z.string().optional(),
        sireneNumber: z.string().optional(),
        serviceDescription: z.string().min(3, {
          message: 'Veuillez fournir la description du service',
        }),
        specificPromotionGoals: z.string().min(3, {
          message: 'Veuillez fournir les objectifs de promotion spécifiques',
        }),
        publicationsNumber: z.string().min(3, {
          message: 'Veuillez fournir le nombre de publications',
        }),
        typeOfContent: z.string().min(3, {
          message: 'Veuillez fournir le type de contenu',
        }),
        period: z.string().min(1, {
          message: 'Veuillez fournir la période',
        }),
        amountNumbersLetters: z.string().min(1, {
          message: 'Veuillez fournir le montant en chiffres et en lettres',
        }),
        socialMediaPlatform: z.string().min(1, {
          message: `La plateforme sur laquelle l'influenceur accepte de publier le contenu est requise.`,
        }),
        payDaysNumber: z.string().min(1, {
          message: 'Veuillez fournir le nombre de jours de paiement',
        }),
        freeService: z.string().min(3, {
          message: 'Veuillez fournir le service gratuit',
        }),
        contractPeriodYearNumber: z.string().min(1, {
          message: 'Veuillez fournir la durée du contrat en années',
        }),
        contractTerminateNotifyDaysNumber: z.string().min(1, {
          message:
            'Veuillez fournir le nombre de jours de préavis de rupture de contrat',
        }),
        location: z.string().min(3, {
          message: "Veuillez fournir l'emplacement",
        }),
        influencer: z.string().min(1, {
          message: "Veuillez fournir l'influenceur",
        }),
        influLegalEntityType: z.string().optional(),
        influencerTitle: z.string().optional(),
        influencerFirstname: z.string().optional(),
        influencerLastname: z.string().optional(),
        influencerAddress: z.string().optional(),
        influencerCountry: z.string().optional(),
        influencerEmail: z.string().optional(),
        influencerCorporateName: z.string().optional(),
        influencerLegalForm: z.string().optional(),
        influencerCapitalAmount: z.string().optional(),
        influencerCorporateAddress: z.string().optional(),
        influencerCorporateRSCAndCity: z.string().optional(),
        influencerCorporateRepresentativeName: z.string().optional(),
        influencerCorporateRepresentativePosition: z.string().optional(),
      });
    } else {
      setRequireInfluFields({});
    }
  }, [term, influencer]);

  const formSchema = z
    .object({
      language: z.string().optional(),
      generateTerm: z.string().optional(),
      lastUpdate: z.string().optional(),
      siteUrl: z.string().url().min(3, {
        message: "Veuillez fournir l'URL du site",
      }),
      ...requireTermFields,
    })
    // eslint-disable-next-line consistent-return
    .superRefine((input: Record<string, any>, refinementContext) => {
      if (
        input.siteOperatorVAT === '1' &&
        (input.VATNumber === '' || input.VATNumber === undefined)
      ) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You need to provide VAT Number',
          path: ['VATNumber'],
        });
      }

      const validationRefine = ({ typeFields, messagePrefix }: any) => {
        typeFields.forEach((field: string) => {
          if (input[field] === '' || !input[field]) {
            refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: `${messagePrefix} ${field}`,
              path: [field],
            });
          }
        });
      };
      const individualTypeFields = [
        'title',
        'firstName',
        'lastName',
        'address',
      ];
      const companyTypeFields = [
        'companyName',
        'amountOfShare',
        'PlaceOfRCS',
        'RSCNumber',
        'officeAddress',
      ];

      if (input.siteOperatorType === '0') {
        validationRefine({
          typeFields: individualTypeFields,
          messagePrefix: `Le type d'opérateur du site est individuel, vous devez fournir`,
        });
      }
      if (input.siteOperatorType === '1') {
        validationRefine({
          typeFields: companyTypeFields,
          messagePrefix: `Le type d'opérateur du site est une entreprise, vous devez fournir`,
        });
      }
    });

  const formSchemaInflu = z
    .object({
      language: z.string().optional(),
      generateInfluencer: z.string().optional(),
      lastUpdate: z.string().optional(),
      siteUrl: z.string().url().min(3, {
        message: "Veuillez fournir l'URL du site",
      }),
      ...requireInfluFields,
    })
    // eslint-disable-next-line consistent-return
    .superRefine((input: Record<string, any>, refinementContext) => {
      const validationRefine = ({ typeFields, messagePrefix }: any) => {
        typeFields.forEach((field: string) => {
          if (input[field] === '' || !input[field]) {
            refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: `${messagePrefix} ${field}`,
              path: [field],
            });
          }
        });
      };

      const influencerIndividualEntityFields = [
        'influencerTitle',
        'influencerFirstname',
        'influencerLastname',
        'influencerAddress',
        'influencerCountry',
        'influencerEmail',
      ];

      const influencerCompanyEntityFields = [
        'influencerCorporateName',
        'influencerLegalForm',
        'influencerCapitalAmount',
        'influencerCorporateAddress',
        'influencerCorporateRSCAndCity',
        'influencerCorporateRepresentativeName',
        'influencerCorporateRepresentativePosition',
      ];

      const influencerIsSoleTraderFields = ['sireneNumber'];

      if (input.influLegalEntityType === '0') {
        validationRefine({
          typeFields: influencerIndividualEntityFields,
          messagePrefix: `Veuillez fournir le nom complet`,
        });
      }
      if (input.influLegalEntityType === '1') {
        validationRefine({
          typeFields: influencerCompanyEntityFields,
          messagePrefix: `Veuillez fournir le nom complet`,
        });
      }
      if (input.isSoleTrader === '1') {
        validationRefine({
          typeFields: influencerIsSoleTraderFields,
          messagePrefix: `Veuillez fournir le nom complet`,
        });
      }
    });

  const allTermAndLabelFields = {
    ...termAndLegalFields,
    ...individualsFields,
    ...companyFields,
    ...termAnhLegalSpecificFields,
  };

  const allInfluencerFields = {
    ...influencerSpecificFields,
    ...influencerFields,
    ...influencerIndividualEntity,
    ...influencerCompanyEntity,
    ...isSoleTraderField,
  };

  const defaultTermAndLabelValues = Object.keys(allTermAndLabelFields).reduce(
    (initialObject: Record<string, any>, key) => {
      const reAssign = initialObject;
      reAssign[key] = allTermAndLabelFields[key].defaultValue;
      return initialObject;
    },
    {}
  );

  const defaultInfluencerValues = Object.keys(allInfluencerFields).reduce(
    (initialObject: Record<string, any>, key) => {
      const reAssign = initialObject;
      reAssign[key] = allInfluencerFields[key].defaultValue;
      return initialObject;
    },
    {}
  );

  const termAndLabelForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultTermAndLabelValues,
  });

  const influencerForm = useForm<z.infer<typeof formSchemaInflu>>({
    resolver: zodResolver(formSchemaInflu),
    defaultValues: defaultInfluencerValues,
  });

  const handleOnSubmitTerm = async (data: z.infer<typeof formSchema>) => {
    setResultModalOpen(true);
    await postTermAndLegalData(data);
  };

  const handleOnSubmitInflu = async (data: z.infer<typeof formSchemaInflu>) => {
    setResultModalOpen(true);
    await postTermAndLegalData(data);
  };

  const modalCloseAllow = responseSuccess || termAndLegalCreationError;

  useEffect(() => {
    if (language && term) termAndLabelForm.setValue('language', language);
    if (language && influencer) influencerForm.setValue('language', language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <>
      <div className="text-left max-w-[800px] w-full">
        <h2 className="font-normal">{t('chooseDocumentType')}</h2>
        <CheckBoxGroupLTR
          id="term"
          className="mt-5"
          text={t('generateTermAndLegal')}
          icon={
            <BiSolidInfoCircle className="text-xl text-white bg-[#a238d5] rounded-full p-1" />
          }
          tooltipText={t('termAndLegalTooltip')}
          checked={term ?? false}
          onCheckedChange={(checked: boolean) => {
            setTerm(checked);
            termAndLabelForm.setValue('generateTerm', checked ? '1' : '0');
          }}
        />
        <CheckBoxGroupLTR
          id="influencer"
          className="mt-5 mb-10"
          text={t('generateInfluencer')}
          icon={
            <BiSolidInfoCircle className="text-xl text-white bg-[#a238d5] rounded-full p-1" />
          }
          tooltipText={t('influencerTooltip')}
          checked={influencer ?? false}
          onCheckedChange={(checked: boolean) => {
            setInfluencer(checked);
            influencerForm.setValue('generateInfluencer', checked ? '1' : '0');
          }}
        />
        <Form {...termAndLabelForm}>
          <form
            onSubmit={termAndLabelForm.handleSubmit(handleOnSubmitTerm)}
            className="w-full flex flex-col gap-4"
          >
            {term && (
              <>
                <TermAndLegal form={termAndLabelForm} />
                <Button
                  type="submit"
                  className="bg-primary border-0 outline-none text-white rounded-lg py-4 px-4"
                  text={t('button.generateDocumentButton')}
                />
              </>
            )}
          </form>
        </Form>
        <Form {...influencerForm}>
          <form
            onSubmit={influencerForm.handleSubmit(handleOnSubmitInflu)}
            className="w-full flex flex-col gap-4"
          >
            {influencer && (
              <>
                <Influencer form={influencerForm} />
                <Button
                  type="submit"
                  className="bg-primary border-0 outline-none text-white rounded-lg py-4 px-4"
                  text={t('button.generateDocumentButton')}
                />
              </>
            )}
          </form>
        </Form>
      </div>
      <Modal
        className="modal-container"
        isOpen={resultModalOpen}
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          if (modalCloseAllow) setResultModalOpen(false);
        }}
        onAfterClose={() => {
          if (responseSuccess)
            dispatch(setApplicationState({ generateFormActive: false }));
        }}
      >
        <div className="relative flex items-center justify-center max-w-md bg-white rounded-lg md:min-w-full p-7">
          {modalCloseAllow && (
            <button
              className="bg-transparent border-none text-3xl !mt-0 cursor-pointer h-fit absolute top-0 right-0"
              type="button"
              onClick={() => setResultModalOpen(false)}
            >
              &times;
            </button>
          )}

          <div className="flex-col">
            {termAndLegalCreationError && (
              <h4>Something was wrong, please try again</h4>
            )}
            {termAndLegalCreationLoading && (
              <div className="animate-pulse">
                <div className="flex text-primary">
                  <LuLoader2 className="animate-spin text-lg mt-[2px] mr-2" />
                  <h3 className="m-0 mb-2 text-primary leading-7">
                    Your application E-commerce documents are generating...
                  </h3>
                </div>
              </div>
            )}
            {responseSuccess && (
              <>
                <h2>Application E-commerce documents generate successfully!</h2>
                <Button
                  className="bg-primary border-0 outline-none text-white rounded-lg py-4 px-4"
                  text="Back To Application List"
                  iconPosition="before"
                  icon={<MdKeyboardArrowLeft className="text-xl mr-2" />}
                  onClick={() =>
                    dispatch(setApplicationState({ generateFormActive: false }))
                  }
                />
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default GenerateForm;
