import Animation from 'components/common/Animation';
import Button from 'components/common/Button';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '@/components/Header';
import { loginSessionCheck } from '@/utils/helpers';

enum ResetSteps {
  PROVIDE_EMAIL = 'provide-email',
  VERIFICATION_CODE = 'verification-code',
}

function Forgot() {
  const navigate = useNavigate();
  const [resetEmail, setResetEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetStage, setResetStage] = useState<ResetSteps>(
    ResetSteps.PROVIDE_EMAIL
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const handleVerifyCode = async () => {
    try {
      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/verify-reset-code`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: resetEmail,
            code: resetCode,
          }),
        }
      );
      if (response.status === 200) {
        setIsCodeVerified(true);
        setErrorMessage(null);
      }
      if (response.status === 400) {
        setErrorMessage(null);
        setErrorMessage(`${t('errors.invalidOrExpiredCode')}`);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(`${t('errors.errorOccur')}`);
      setIsCodeVerified(false);
    }
  };

  const handleRequestResetCode = async () => {
    try {
      const reCaptchaToken = captchaRef.current?.getValue();

      if (!resetEmail) {
        setErrorMessage(`${t('errors.emptyResetEmail')}`);
        return;
      }

      if (!reCaptchaToken) {
        setErrorMessage(`${t('errors.emptyRecaptcha')}`);
        return;
      }

      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/request-reset-code`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: resetEmail,
            recaptchaToken: reCaptchaToken,
          }),
        }
      );
      captchaRef.current?.reset();
      const error = await response.text();

      if (response.status === 200) {
        setErrorMessage(null);
        setResetStage(ResetSteps.VERIFICATION_CODE);
        toast.success(`${t('sendResetcodeEmail')}`);
      } else if (error === 'User not found') {
        setErrorMessage(`${t('errors.userNotRegister')}`);
      }
    } catch (error: any) {
      setErrorMessage(`${t('errors.errorOccur')}`);
    }
  };

  const handleResetPassword = async () => {
    if (!newPassword) {
      setErrorMessage(`${t('errors.newPasswordError')}`);
      return;
    }

    if (!confirmPassword) {
      setErrorMessage(`${t('errors.confirmPasswordError')}`);
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage(`${t('errors.unMatchPasswordError')}`);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/auth/reset-password`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: resetEmail,
            code: resetCode,
            newPassword,
          }),
        }
      );
      if (response.status === 200) {
        toast.success(`${t('resetPasswordSuccess')}`);
        navigate('/login');
      } else {
        setErrorMessage(`${t('errors.newPasswordDuplicate')}`);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(`${t('errorOccur')}`);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('productly');
    const isLogin = loginSessionCheck(token);
    if (token && isLogin) {
      navigate('/app');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="absolute w-full h-[1050px] pointer-events-none bg-forgot-gradient-background" />
      <Header />
      <Animation className="form-container">
        <div className="form-parent">
          <form className="form-log-reg">
            <h3>{t('forgotPasswordPageTitle')}</h3>
            {resetStage === ResetSteps.PROVIDE_EMAIL && (
              <>
                <label htmlFor="email" className="!flex flex-col">
                  Email
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                  />
                </label>
                {errorMessage && (
                  <div className="error-box">{errorMessage}</div>
                )}
                <div className="mt-8 mb-4 ml-5 transform scale-112">
                  <ReCAPTCHA
                    theme="dark"
                    ref={captchaRef}
                    sitekey={process.env.VITE_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
                  />
                </div>
                <Button
                  className="submit-button"
                  type="button"
                  onClick={handleRequestResetCode}
                  text={t('getTheCode')}
                />
              </>
            )}
            {!isCodeVerified && resetStage === ResetSteps.VERIFICATION_CODE && (
              <>
                <label htmlFor="resetCode" className="!flex flex-col">
                  Code
                  <input
                    type="text"
                    id="resetCode"
                    placeholder={t('digitCode')}
                    value={resetCode}
                    onChange={(e) => setResetCode(e.target.value)}
                  />
                </label>
                {errorMessage && (
                  <div className="error-box">{errorMessage}</div>
                )}
                <Button
                  type="button"
                  className="submit-button"
                  onClick={handleVerifyCode}
                  text="Verify Code"
                />
              </>
            )}
            {isCodeVerified && (
              <>
                <label htmlFor="newPassword" className="!flex flex-col">
                  {t('newPassword')}
                  <input
                    type="password"
                    id="newPassword"
                    placeholder={t('newPassword')}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </label>
                <label htmlFor="confirmPassword" className="!flex flex-col">
                  {t('confirmNewPassword')}
                  <input
                    type="password"
                    id="confirmPassword"
                    placeholder={t('confirmNewPassword')}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </label>
                {errorMessage && (
                  <div className="error-box">{errorMessage}</div>
                )}
                <Button
                  className="submit-button"
                  type="button"
                  onClick={handleResetPassword}
                  text={t('resetPassword')}
                />
              </>
            )}
          </form>
        </div>
      </Animation>
    </div>
  );
}

export default Forgot;
