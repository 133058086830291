import clsx from 'clsx';
import React from 'react';

import GoogleIcon from 'components/common/svg/GoogleIcon';

interface GoogleLoginBtnProps {
  className?: string;
  onClick: () => void;
}

function GoogleLoginBtn({ className, onClick }: GoogleLoginBtnProps) {
  return (
    <button
      type="button"
      className={clsx('social bg-transparent p-0', className)}
      onClick={onClick}
    >
      <div className="go">
        <GoogleIcon />
        Google
      </div>
    </button>
  );
}

export default GoogleLoginBtn;
