/* eslint-disable react/forbid-dom-props */
import React from 'react';

function BlinkStarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={{ marginRight: '3px' }}
    >
      <path
        d="M13.3789 11.638C13.7579 11.554 14.0539 11.258 14.1379 10.879L15.0239 6.89299C15.2559 5.84899 16.7439 5.84899 16.9759 6.89299L17.8619 10.879C17.9459 11.258 18.2419 11.554 18.6209 11.638L22.6069 12.524C23.6509 12.756 23.6509 14.244 22.6069 14.476L18.6209 15.362C18.2419 15.446 17.9459 15.742 17.8619 16.121L16.9759 20.107C16.7439 21.151 15.2559 21.151 15.0239 20.107L14.1379 16.121C14.0539 15.742 13.7579 15.446 13.3789 15.362L9.39286 14.476C8.34886 14.244 8.34886 12.756 9.39286 12.524L13.3789 11.638Z"
        fill="white"
      />
      <path
        d="M8.0612 19.82C8.4402 19.736 8.73619 19.44 8.82019 19.061L9.09119 17.841C9.18819 17.403 9.8122 17.403 9.9092 17.841L10.1802 19.061C10.2642 19.44 10.5602 19.736 10.9392 19.82L12.1592 20.091C12.5972 20.188 12.5972 20.812 12.1592 20.909L10.9392 21.18C10.5602 21.264 10.2642 21.56 10.1802 21.939L9.9092 23.159C9.8122 23.597 9.18819 23.597 9.09119 23.159L8.82019 21.939C8.73619 21.56 8.4402 21.264 8.0612 21.18L6.8412 20.909C6.4032 20.812 6.4032 20.188 6.8412 20.091L8.0612 19.82Z"
        fill="white"
      />
    </svg>
  );
}

export default BlinkStarIcon;
