import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { setPlanState } from '@/shared/reducers/planReducer';
import { RootState } from '@/shared/reduxStore';
import { useCheckoutMutation } from '@/shared/slices/subscriptionSlice';

export interface CheckoutHandleProps {
  planName: string;
  isPlanDisabled: boolean;
  isAnnual?: boolean;
  trialEnable?: boolean;
  shopifyPurchase?: boolean;
  currency?: string;
}

export const useUpgradePlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state?.auth);
  const [checkoutCreate, { isLoading }] = useCheckoutMutation();
  const { t } = useTranslation('translation', { keyPrefix: 'upgrade' });

  const handleUpgradePlan = async (args: CheckoutHandleProps) => {
    const {
      isPlanDisabled,
      planName,
      isAnnual = false,
      trialEnable = true,
      shopifyPurchase = false,
      currency,
    } = args;
    if (isPlanDisabled) return;
    if (!user) {
      navigate('/login');
      return;
    }
    try {
      const checkOut = await checkoutCreate({
        planName,
        annuel: isAnnual,
        userId: user?.id,
        trialEnable,
        shopifyPurchase,
        currency,
      }).unwrap();

      if (!checkOut?.success) {
        toast.error(`${t('error.stripeCheckoutError')}`);
        return;
      }
      const { sessionId: stripeSessionId } = checkOut.data;
      const stripe = (await loadStripe(
        process.env.VITE_APP_STRIPE_PUBLIC_KEY as string
      )) as Stripe;
      stripe.redirectToCheckout({ sessionId: stripeSessionId });
    } catch (error: any) {
      if (error?.status === 500) {
        dispatch(setPlanState({ subscriptionCurrencyNoti: true }));
        return;
      }
      toast.error(error);
    }
  };

  return [handleUpgradePlan, { isLoading }] as const;
};
