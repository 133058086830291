/* eslint-disable import/prefer-default-export */
import React from 'react';

import cn from '@/utils/cn';

interface CustomInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  value?: any;
}

export const InputDiv = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ className, value, maxLength, ...props }, ref) => {
    const defaultLimit = maxLength ?? 40;
    return (
      <div
        ref={ref}
        onKeyDown={(event) => {
          const {
            currentTarget: { textContent },
            key,
          } = event;
          if (
            textContent &&
            textContent?.length >= defaultLimit &&
            !['Backspace', 'Delete'].includes(key)
          ) {
            event.preventDefault();
          }
        }}
        role="textbox"
        tabIndex={0}
        contentEditable
        suppressContentEditableWarning
        className={cn(
          `cursor-text`,
          props.placeholder && `empty:before:content-[attr(placeholder)]`,
          className
        )}
        spellCheck="false"
        {...props}
      >
        {value}
      </div>
    );
  }
);
