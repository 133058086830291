/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod';
import { Tag, TagInput } from 'emblor';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircleSharp, IoCloseOutline } from 'react-icons/io5';
import { LuLoader2 } from 'react-icons/lu';
import { TbUserUp } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import Button from '@/components/common/Button';
import FormFieldInput from '@/components/common/FormFields/Input';
import { MultiSelect } from '@/components/common/MultipleSelect';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/common/shadcn-ui/Form';
import { Textarea } from '@/components/common/shadcn-ui/Textarea';
import Skeleton from '@/components/common/Skeleton';
import Tiptap from '@/components/common/Tiptap';
import { cn } from '@/lib/utils';
import { RootState } from '@/shared/reduxStore';
import { useLazyGetFormattedInfoQuery } from '@/shared/slices/productSlice';
import {
  useGetShopifyDataQuery,
  useImportProductToShopifyMutation,
} from '@/shared/slices/shopifySlice';
import { stripTags } from '@/utils/helpers';

function ImportBasic() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state?.auth);
  const { shopifyImport, currentProductId, products } = useSelector(
    (state: RootState) => state?.products
  );

  const formSchema = z.object({
    title: z.string().min(2, {
      message: t('shopify.productTitleEmptyError'),
    }),
    descriptionHtml: z.string().min(2, {
      message: t('shopify.productDescriptionEmptyError'),
    }),
    collections: z.string().optional(),
    productType: z.string().optional(),
    vendor: z.string().optional(),
    tags: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      descriptionHtml: '',
      collections: '',
      productType: '',
      vendor: '',
      tags: '',
    },
  });

  const [tags, setTags] = React.useState<Tag[]>([]);
  const [descriptionHtml, setDescriptionHtml] = useState('');
  const [activeTagIndex, setActiveTagIndex] = React.useState<number | null>(
    null
  );
  const [selectedCollections, setSelectedCollections] = useState<string[]>([]);
  const { data: shopifyData } = useGetShopifyDataQuery();
  const collections = shopifyData?.collections
    ? shopifyData?.collections.map(({ value, text }: any) => ({
        value,
        label: text,
      }))
    : [];

  const [importToShopify, { data: addToShopify, isLoading: postingToShopify }] =
    useImportProductToShopifyMutation();
  const { success: addSuccess, message: addMessage } = addToShopify ?? {};

  const [
    getFormatedProductDetail,
    { data: formattedInfo, isFetching: formattedInfoLoading },
  ] = useLazyGetFormattedInfoQuery();

  const currentProduct = products?.find(
    (product) => product.id === currentProductId
  );

  useEffect(() => {
    if (!shopifyImport) return;
    if (currentProduct?.name && user?.plan !== 'free') {
      form.setValue('title', currentProduct.name);
      getFormatedProductDetail({ productId: currentProductId }, true);
    }

    if (formattedInfo?.content) {
      setDescriptionHtml(formattedInfo.content);
      form.setValue('descriptionHtml', stripTags(formattedInfo.content));
    }
  }, [currentProduct, formattedInfo, shopifyImport]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    const formValueFull = {
      ...values,
      productId: currentProductId!,
      descriptionHtml,
      collections: selectedCollections,
      tags: tags?.length > 0 ? tags.map((tag) => tag.text).join(',') : '',
    };
    importToShopify(formValueFull);
  }

  return (
    <Form {...form}>
      <div className="w-full bg-transparent text-white max-w-[800px] p-0 m-0">
        {addSuccess ||
          (postingToShopify && (
            <div
              className={cn(
                'text-primary border border-solid p-10 space-y-5 border-primary bg-defaultBlack rounded-lg',
                postingToShopify && 'animate-pulse'
              )}
            >
              <div className="flex w-full justify-center">
                {postingToShopify && (
                  <LuLoader2 className="animate-spin text-5xl mt-[2px] mr-2" />
                )}
                {addSuccess && (
                  <IoCheckmarkCircleSharp className="text-5xl mt-[2px] mr-2" />
                )}
              </div>
              <div className="flex w-full justify-center text-center">
                <h3 className="m-0 mb-2 text-white font-semibold">
                  {postingToShopify && t('shopify.addingLoading')}
                  {addSuccess && (
                    <p
                      className="[&>_a]:!text-primary [&>_a]::hover:text-opacity-90"
                      dangerouslySetInnerHTML={{
                        __html: t('shopify.addingSuccess', {
                          interpolation: { escapeValue: false },
                          url: addToShopify.adminUrl,
                        }),
                      }}
                    />
                  )}
                </h3>
              </div>
            </div>
          ))}
        <div className="relative">
          {user?.plan === 'free' && (
            <Button
              className="border border-solid border-[#ffffff4d] rounded-lg uppercase outline-none py-3 px-4 absolute top-1/2 xs:left-1/4 md:left-1/3 bg-defaultBlack bg-opacity-80 text-white z-50"
              text={`${t('shopify.upgradeYourPlan')}`}
              icon={<TbUserUp className="text-xl mr-3" />}
              iconPosition="before"
              onClick={() => navigate('/upgrade')}
            />
          )}
          <div className={cn(user?.plan === 'free' && 'blur-[8px] p-5')}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-8 xs:w-full lg:min-w-[800px]"
            >
              <FormFieldInput
                control={form.control}
                name="title"
                label={t('shopify.productTitle')}
                placeholder={t('shopify.productTitlePlaceholder')}
              />
              <FormField
                control={form.control}
                name="descriptionHtml"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('shopify.productDescription')}</FormLabel>
                    <FormControl>
                      <>
                        <Textarea className="hidden" {...field} />
                        {formattedInfoLoading ? (
                          <div className="space-y-2 mt-3">
                            <Skeleton className="w-full h-5 bg-neutral-500" />
                            <Skeleton className="w-8/12 h-5 bg-neutral-500" />
                            <Skeleton className="w-full h-100 bg-neutral-500" />
                          </div>
                        ) : (
                          <Tiptap
                            content={formattedInfo?.content ?? ''}
                            onChange={(value) => {
                              setDescriptionHtml(value);
                              form.setValue(
                                'descriptionHtml',
                                stripTags(value)
                              );
                            }}
                            disabled={postingToShopify}
                          />
                        )}
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-3">
                <FormField
                  control={form.control}
                  name="collections"
                  render={() => (
                    <FormItem>
                      <FormLabel>{t('shopify.collection')}</FormLabel>
                      <FormControl>
                        <MultiSelect
                          options={collections}
                          onValueChange={setSelectedCollections}
                          defaultValue={selectedCollections}
                          placeholder={t('shopify.collectionPlaceholder')}
                          className="bg-defaultBlack text-white border-solid border-primary h-auto py-[13px] data-[has-item=true]:py-[9px]"
                          variant="inverted"
                          maxCount={1}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormFieldInput
                  control={form.control}
                  name="productType"
                  placeholder=""
                  label={t('shopify.productType')}
                  inputClass="bg-defaultBlack h-max py-[13px] indent-4 text-sm"
                />
                <FormFieldInput
                  control={form.control}
                  name="vendor"
                  placeholder=""
                  label={t('shopify.vendor')}
                  inputClass="bg-defaultBlack h-max py-[13px] indent-4 text-sm"
                />
              </div>
              <FormField
                control={form.control}
                name="tags"
                render={() => (
                  <FormItem>
                    <FormLabel>{t('shopify.tags')}</FormLabel>
                    <FormDescription className="text-zinc-400">
                      {t('shopify.tagsSubtitle')}
                    </FormDescription>
                    <FormControl>
                      <div className="border border-solid border-primary shadow-sm ring-offset-background rounded-md [&>div>div>div]:bg-transparent [&>div>div>div]:p-[6px] mt-2">
                        <TagInput
                          inlineTags
                          id="tags"
                          placeholder={t('shopify.tagsPlaceholder')}
                          // eslint-disable-next-line react/no-unstable-nested-components
                          customTagRenderer={(tag) => (
                            <div
                              key={tag.id}
                              className="px-2 py-1 bg-primary rounded-lg text-white text-xs mr-1 flex items-center font-normal"
                            >
                              {tag.text}
                              <Button
                                text=""
                                icon={<IoCloseOutline />}
                                className="bg-transparent outline-none border-none text-white p-0 pl-2"
                                onClick={() =>
                                  setTags((prev) =>
                                    prev.filter((tag2) => tag2.id !== tag.id)
                                  )
                                }
                              />
                            </div>
                          )}
                          className="shadow-none bg-transparent text-white placeholder:text-white"
                          tags={tags}
                          setTags={(newTags) => {
                            setTags(newTags);
                          }}
                          activeTagIndex={activeTagIndex}
                          setActiveTagIndex={setActiveTagIndex}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {!postingToShopify && !addSuccess && user?.plan !== 'free' && (
                <div className="flex justify-end mt-5">
                  <Button
                    type="submit"
                    disabled={
                      Boolean(form.getFieldState('title').error) ||
                      stripTags(descriptionHtml) === '' ||
                      postingToShopify
                    }
                    loading={postingToShopify}
                    loadingText={t('shopify.postToShopify')}
                    className="border-none bg-primary px-4 text-sm rounded-sm py-2 hover:bg-shopify text-white transition-all disabled:bg-zinc-200 disabled:text-zinc-500 disabled:cursor-not-allowed"
                  >
                    {t('shopify.addToShopify')}
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default ImportBasic;
