import * as Select from '@radix-ui/react-select';
import { useWindowSize } from '@uidotdev/usehooks';
import clsx from 'clsx';
import React, { Ref, useEffect, useId, useRef, useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { ScrollArea } from './ScrollArea';
import ChevronIcon from './svg/ChevronIcon';

import cn from '@/utils/cn';

type ItemProps = {
  children: React.ReactNode;
  className?: string;
  tooltipText?: string;
  selected?: boolean;
  keepSelected?: boolean;
  isOption?: boolean;
};

export const SelectValue = Select.Value;

export const SelectItem = React.forwardRef(
  (
    {
      children,
      className,
      tooltipText,
      selected,
      keepSelected = false,
      isOption = true,
      ...props
    }: ItemProps & Select.SelectItemProps,
    forwardedRef: Ref<HTMLDivElement>
  ) => {
    const id = useId();
    const Item = isOption ? Select.Item : 'div';
    const Indicator = isOption ? Select.ItemIndicator : 'div';
    const ItemText = isOption ? Select.ItemText : 'div';
    return (
      <Item
        aria-disabled
        className={clsx(
          'relative text-sm text-white font-medium bg-defaultBlack py-1 cursor-pointer [&[data-state="checked"]_.checkbg]:bg-primary',
          {
            '!px-0': !isOption,
          },
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <div
          className={clsx('relative rounded-md px-6 py-3', {
            'border-[1px] border-solid border-white': selected && keepSelected,
          })}
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <div className="size-5 rounded-full border-4 border-solid border-white">
                <Indicator className="flex items-center justify-center">
                  <div
                    className={cn(
                      'checkbg size-5 rounded-full',
                      selected && 'bg-primary'
                    )}
                  />
                </Indicator>
              </div>
              <ItemText>{children}</ItemText>
            </div>
            {tooltipText && (
              <>
                <button
                  type="button"
                  aria-label="info"
                  data-tooltip-id={id}
                  className="bg-transparent border-[1px] border-opacity-15 border-solid border-white outline-none rounded-full flex items-center p-0"
                >
                  <AiFillInfoCircle className="text-white p-1 bg-opacity-10 rounded-full bg-white" />
                </button>
                <ReactTooltip
                  id={id}
                  place="bottom-end"
                  content={tooltipText}
                  className="!z-[9999] !text-center !text-sm !text-black !font-normal !rounded-xl sm:!px-10 sm:!py-7 !p-5 !bg-white !opacity-100 !max-w-[25rem] !fixed !leading-normal"
                />
              </>
            )}
          </div>
        </div>
      </Item>
    );
  }
);

type OptionData = {
  value: string;
  text: string;
  tooltipText?: string;
};

type SelectProps = {
  placeholder?: string;
  optionsData: OptionData[];
  setOptionsData?: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
  addOption?: boolean;
  defaultOpen?: boolean;
  inputPlaceholder?: string;
  triggerClassName?: string;
  contentParams?: Select.SelectContentProps;
  contentClassName?: string;
  onSelectData?: (value: string) => void;
  defaultValue?: string;
  keepSelected?: boolean;
  scrollable?: boolean;
};

export function SelectCustom({
  addOption = false,
  defaultOpen = false,
  setOptionsData,
  inputPlaceholder,
  triggerClassName,
  contentParams,
  contentClassName,
  onSelectData,
  optionsData,
  keepSelected = false,
  scrollable = false,
  ...props
}: SelectProps & Select.SelectTriggerProps) {
  const elementRef: React.MutableRefObject<HTMLButtonElement | null> =
    useRef(null);
  const { width: windowWidth } = useWindowSize();
  const [width, setWidth] = useState<number | null>(null);
  const [open, setOpen] = useState(defaultOpen);
  const [selectedData, setSelectedData] = useState<OptionData>();
  const [inputVal, setInputVal] = useState('');

  const inputRef = useRef<null | HTMLInputElement>(null);

  const updateWidth = () => {
    if (elementRef.current) {
      const newWidth = elementRef.current?.offsetWidth;
      setWidth(newWidth);
    }
  };

  const pushNewOption = () => {
    if (!inputVal || !setOptionsData) return;
    if (optionsData.find((select) => select.value === inputVal)) return;
    setSelectedData({ value: inputVal, text: inputVal });
    if (onSelectData) onSelectData(inputVal);
    setOptionsData((prev: Record<string, any>[]) => [
      { value: inputVal, text: inputVal },
      ...prev,
    ]);
    setInputVal('');
  };

  const handleSelectChange = (value: string) => {
    const selectedOption = optionsData.find((option) => option.value === value);
    if (selectedOption) setSelectedData(selectedOption);
  };

  useEffect(() => {
    updateWidth();
  }, [windowWidth]);

  useEffect(() => {
    if (open) inputRef.current?.focus();
  }, [open]);

  useEffect(() => {
    handleSelectChange((props.value as string) ?? '');
  });

  return (
    <>
      <div className="relative">
        {addOption && (
          <input
            ref={inputRef}
            placeholder={inputPlaceholder}
            className={cn(
              !defaultOpen
                ? 'absolute z-50 w-10/12 h-full bg-transparent outline-none border-none text-sm text-white placeholder:text-white pl-6'
                : 'w-full border border-primary border-solid border-b-0 bg-defaultBlack outline-none text-sm text-white placeholder:text-white p-4 rounded-t-lg box-border'
            )}
            autoComplete="do-not-suggest"
            onInput={(e) => setInputVal(e.currentTarget.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                pushNewOption();
              }
            }}
            onFocus={() => setOpen(true)}
            onBlur={pushNewOption}
            value={inputVal}
          />
        )}

        {!defaultOpen && (
          <Select.Root
            {...(props.defaultValue && { defaultValue: props.defaultValue })}
            open={open}
            onOpenChange={setOpen}
            onValueChange={(value) => {
              if (value?.length <= 0) return;
              if (onSelectData) onSelectData(value);
              handleSelectChange(value);
            }}
            value={selectedData?.value}
          >
            <Select.Trigger
              className={clsx(
                `relative flex justify-between items-center rounded-md border-[0.6px] border-solid
           border-[#8829C1] p-4 px-6 leading-none bg-defaultBlack
             outline-none text-white text-sm w-full text-left`,
                {
                  '!rounded-b-none !border-b-0': open,
                },
                triggerClassName
              )}
              {...props}
              ref={elementRef}
            >
              {!addOption && (
                <SelectValue placeholder={props?.placeholder ?? ''} />
              )}
              <Select.Icon
                className={clsx('flex items-center justify-center ml-auto', {
                  'rotate-180': !open,
                })}
              >
                <ChevronIcon className="size-3" />
              </Select.Icon>
            </Select.Trigger>
            <Select.Content
              // eslint-disable-next-line react/forbid-component-props
              style={{
                width: width as number,
              }}
              className={clsx(
                `rounded-xl rounded-t-none overflow-hidden border-[0.6px] border-solid border-[#8829C1] z-[9999]`,
                open && 'border-t-0',
                contentClassName
              )}
              {...contentParams}
            >
              <ScrollArea className={cn(scrollable && 'h-[500px]')}>
                <Select.Group>
                  {optionsData?.length > 0 &&
                    optionsData?.map((item) => (
                      <SelectItem
                        key={item.value}
                        value={item.value}
                        {...(item.tooltipText && {
                          tooltipText: item.tooltipText,
                        })}
                      >
                        {item.text}
                      </SelectItem>
                    ))}
                </Select.Group>
              </ScrollArea>
            </Select.Content>
          </Select.Root>
        )}
      </div>
      {defaultOpen && (
        <div className="border border-primary border-t-0 border-solid p-2 rounded-b-lg bg-defaultBlack">
          {optionsData?.length > 0 &&
            optionsData?.map((item) => (
              <SelectItem
                key={item.value}
                value={item.value}
                {...(item.tooltipText && { tooltipText: item.tooltipText })}
                isOption={false}
                onClick={(e) => {
                  handleSelectChange(item.value);
                  if (onSelectData)
                    onSelectData(e.currentTarget.getAttribute('value')!);
                }}
                selected={selectedData?.value === item.value}
              >
                {item.text}
              </SelectItem>
            ))}
        </div>
      )}
      {keepSelected && selectedData && !open && (
        <SelectItem
          tooltipText={selectedData?.tooltipText}
          className="mt-4"
          value=""
          isOption={false}
          selected
          keepSelected
        >
          {selectedData?.text}
        </SelectItem>
      )}
    </>
  );
}
