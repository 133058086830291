import _findIndex from 'lodash/findIndex';
import React from 'react';

import { Label } from './Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './shadcn-ui/Select';

export interface SelectLabelProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  inputData: any;
  name?: string;
  placeholder?: string;
  onValueChange?: any;
}

function SelectLabel({
  className,
  inputData,
  name,
  onValueChange,
  placeholder,
}: SelectLabelProps) {
  return (
    <>
      <Label className="font-bold text-base" htmlFor={name}>
        {name}
      </Label>
      <Select onValueChange={onValueChange} name={name}>
        <SelectTrigger className={`${className} py-5 border-solid`}>
          <SelectValue
            className="!bg-white !text-black"
            placeholder={placeholder}
          />
        </SelectTrigger>
        <SelectContent position="popper">
          {inputData &&
            inputData.map((data: any) => (
              <SelectItem value={data.value}>{data.language}</SelectItem>
            ))}
        </SelectContent>
      </Select>
    </>
  );
}

export default SelectLabel;
