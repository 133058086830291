import React from 'react';
import './components/app/account.css';

function NotFound() {
  return (
    <div>
      <div className="blur app"> </div>
      <div className="glow app">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="190"
          height="190"
          viewBox="0 0 190 190"
          fill="none"
        >
          <circle cx="95" cy="95" r="95" fill="#8628C0" />
        </svg>
      </div>

      <div className="glowsec app">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="190"
          height="190"
          viewBox="0 0 190 190"
          fill="none"
        >
          <circle cx="95" cy="95" r="95" fill="#8628C0" />
        </svg>
      </div>
      <div className="secglow app">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="350"
          height="60"
          viewBox="0 0 350 60"
          fill="none"
        >
          <rect x="5" y="5" width="350" height="60" fill="#8628C0" />
        </svg>
      </div>
      <div className="secglowe app">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="60"
          viewBox="0 0 100 60"
          fill="none"
        >
          <rect x="5" y="5" width="100" height="60" fill="#FFF" />
        </svg>
      </div>
      <div className="secgloweee app">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="60"
          viewBox="0 0 100 60"
          fill="none"
        >
          <rect x="5" y="5" width="100" height="60" fill="#FFF" />
        </svg>
      </div>
      <div className="glowsece acc">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="190"
          height="190"
          viewBox="0 0 190 190"
          fill="none"
        >
          <circle cx="95" cy="95" r="95" fill="#8628C0" />
        </svg>
      </div>
      <div className="maintenance" />
    </div>
  );
}

export default NotFound;
