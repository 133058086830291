/* eslint-disable react-hooks/exhaustive-deps */
import _findIndex from 'lodash/findIndex';
import reduce from 'lodash/reduce';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';
import { LuLoader2 } from 'react-icons/lu';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/common/Button';
import Stepper, { StepDataProps } from '@/components/common/Stepper';
import ProductAppLayout from '@/components/products/Layout';
import ProductHeader from '@/components/products/services/Header';
import { GenerateType } from '@/shared/configs/generateServices';
import {
  stepperOneClicksConfigs,
  stepperCustomizeConfigs,
} from '@/shared/configs/scratchSteppers';
import {
  OverviewTextData,
  setFromScratch,
} from '@/shared/reducers/fromScratchReducer';
import { RootState } from '@/shared/rootReducers';
import {
  useGetCTAQuery,
  useGetEmailTypeQuery,
  usePostGenerateEmailFromScratchMutation,
} from '@/shared/slices/emailFromScratchSlice';
import { useLazyGetServiceDetailQuery } from '@/shared/slices/productSlice';
import cn from '@/utils/cn';

interface GenerateFromScratchProps {
  productDetail: any;
  setSelectedService: React.Dispatch<React.SetStateAction<string | null>>;
}

function GenerateFromScratch({
  productDetail,
  setSelectedService,
}: GenerateFromScratchProps) {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { data: emailTypeData } = useGetEmailTypeQuery();
  const { data: ctaData } = useGetCTAQuery();
  const [getServiceDetail] = useLazyGetServiceDetailQuery();

  const { emailFromScratch, fromScratch } = useSelector(
    (state: RootState) => state
  );
  const { productId, stepperNextBtnDisable, projectName } = fromScratch;

  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [
    generateFromScratch,
    { data: generateResponse, isLoading: generating },
  ] = usePostGenerateEmailFromScratchMutation();

  const [stepperData, setStepperData] = useState(
    stepperOneClicksConfigs({ t })
  );
  const [currentStep, setCurrentStep] = useState<StepDataProps>(stepperData[0]);

  const handleLastStep = async () => {
    setLoadingModal(true);
    const response = await generateFromScratch({
      ...emailFromScratch,
      ...fromScratch,
    }).unwrap();

    if (!response?.success) {
      setLoadingModal(false);
      return;
    }

    await getServiceDetail({
      productId,
      service: 'email',
    });

    setSelectedService('email');
    setLoadingModal(false);
    dispatch(setFromScratch({ active: false }));
  };

  useEffect(() => {
    dispatch(
      setFromScratch({
        productId: productDetail?.id,
      })
    );
  }, []);

  useEffect(() => {
    if (!emailTypeData || !emailFromScratch) return;
    const overViewDataFormat: OverviewTextData[] = reduce(
      emailFromScratch,
      (result: OverviewTextData[], value, key) => {
        if (typeof value === 'string' && value !== '') {
          let subTitle;
          // eslint-disable-next-line default-case
          switch (key) {
            case 'emailType':
              subTitle = emailTypeData.find(
                (item: Record<string, any>) => item.text_en === value
              );
              break;
            case 'cta':
              subTitle = ctaData.find(
                (item: Record<string, any>) => item.text_en === value
              );
              break;
            case 'chosenSeason':
              subTitle = fromScratch?.seasonsData.find(
                (item: Record<string, any>) => item.text_en === value
              );
              break;
          }

          result.push({
            title: t(`scratch.${key}`),
            subTitle: subTitle ? subTitle[`text_${language}`] : value,
          });
        }
        return result;
      },
      []
    );

    dispatch(setFromScratch({ overViewData: overViewDataFormat }));
  }, [language, emailFromScratch, emailTypeData, ctaData]);

  useEffect(() => {
    const { generateType } = fromScratch;
    if (generateType === GenerateType.CUSTOMIZE) {
      setStepperData(stepperCustomizeConfigs({ t }));
    }
    if (generateType === GenerateType.ONE_CLICK) {
      setStepperData(stepperOneClicksConfigs({ t }));
    }
  }, [fromScratch.generateType, language]);

  const generatingText = t('scratch.isGenerating', {
    text: projectName,
  });
  const generatedSuccessText = t('scratch.generatedSuccess', {
    text: projectName,
  });

  const isGeneratingFailed = !generating && !generateResponse?.success;
  return (
    <ProductAppLayout>
      <>
        <ProductHeader {...productDetail} />
        <div className="xl:max-w-[800px] xl:w-[800px] xs:w-full">
          <Stepper
            stepData={stepperData}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            lastStepAction={handleLastStep}
            nextBtnDisabled={stepperNextBtnDisable}
          />
        </div>
        <Modal
          className="modal-container"
          isOpen={loadingModal}
          overlayClassName="modal-overlay"
          {...(isGeneratingFailed && {
            onRequestClose: () => setLoadingModal(false),
          })}
        >
          <div className="rounded-2xl md:min-w-full max-w-md p-7 bg-white">
            {isGeneratingFailed && (
              <Button
                text=""
                className="bg-transparent border-0 outline-none w-full !justify-end"
                icon={<IoCloseCircleSharp className="text-3xl" />}
                onClick={() => setLoadingModal(false)}
              />
            )}
            <div className={cn(generating && 'animate-pulse')}>
              <div className="text-primary">
                <div className="flex w-full justify-center">
                  {generating ? (
                    <LuLoader2 className="animate-spin text-9xl mt-[2px] mr-2" />
                  ) : (
                    <IoCheckmarkCircleSharp className="text-9xl mt-[2px] mr-2" />
                  )}
                </div>
                <div className="flex w-full justify-center text-center">
                  <h1 className="m-0 mb-2 text-black font-semibold">
                    {generating && generatingText}
                    {generateResponse?.success && generatedSuccessText}
                    {isGeneratingFailed && t('scratch.generationFailed')}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </ProductAppLayout>
  );
}

export default GenerateFromScratch;
