import React from 'react';
import { BiSolidInfoCircle } from 'react-icons/bi';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface HeaderWithTooltipProps {
  text: string;
  tooltipText?: string;
}

function HeaderWithTooltip({ tooltipText, text }: HeaderWithTooltipProps) {
  return (
    <div className="flex items-center">
      <h2 className="font-normal mr-2">{text}</h2>
      <BiSolidInfoCircle
        data-tooltip-id="cta-tooltip"
        className="text-xl text-white bg-[#292729] rounded-full p-1 border border-white border-solid border-opacity-[0.15]"
      />
      <ReactTooltip
        id="cta-tooltip"
        place="bottom-end"
        content={tooltipText}
        className="!z-[9999] !text-center !text-sm !text-black !font-normal !rounded-xl sm:!px-10 sm:!py-7 !p-5 !bg-white !opacity-100 !max-w-[25rem] !fixed !leading-normal"
      />
    </div>
  );
}

export default HeaderWithTooltip;
