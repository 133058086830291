import React from 'react';
import './css/try.css';
import { useTranslation } from 'react-i18next';

import Presentation from './assets/presentation-productly.mp4';
import Animation from './common/Animation';

import { cn } from '@/lib/utils';

interface PresentationVideoProps {
  showHeader?: boolean;
  animationClass?: string;
  videoClass?: string;
}

function PresentationVideo({
  showHeader = true,
  animationClass = '',
  videoClass,
}: PresentationVideoProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  return (
    <Animation
      className={cn(
        'z-[2] relative items-center p-[200px_200px_100px_200px] text-white justify-center flex flex-col xs:p-[200px_20px_100px] lg:p-[200px_200px_100px_200px]',
        animationClass
      )}
      id="presentation-vid"
    >
      <>
        {showHeader && (
          <h1 className="text-center xs:text-[1.6rem] sm:text-[1.8] md:text-[2rem]">
            {t('videoTitle1')}{' '}
            <span className="text-[#a238d5] inline-block relative">
              {t('videoTitle2')}
            </span>{' '}
            {t('videoTitle3')}
          </h1>
        )}

        <video
          className={cn(
            'w-full',
            videoClass ??
              'max-w-[100%] 2xl:max-w-[90%] 3xl:w-[80%] 4xl:w-[70%] 5xl:w-[60%] 6xl:w-[50%] 7xl:w-[40%] 8xl:w-[20%]'
          )}
          controls
        >
          <source src={Presentation} type="video/mp4" />
          <track kind="captions" />
        </video>
      </>
    </Animation>
  );
}

export default PresentationVideo;
