import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

function useTermAndLegalFieldsConfigs() {
  const { t } = useTranslation('translation', { keyPrefix: 'application' });

  const individualsFields: Record<string, any> = {
    title: {
      label: t('termAndLegal.title.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.title.placeHolder'),
    },
    firstName: {
      label: t('termAndLegal.firstName.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.firstName.placeHolder'),
    },
    lastName: {
      label: t('termAndLegal.lastName.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.lastName.placeHolder'),
    },
    address: {
      label: t('termAndLegal.address.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.address.placeHolder'),
    },
  };

  const companyFields: Record<string, any> = {
    companyName: {
      label: t('termAndLegal.companyName.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.companyName.placeHolder'),
    },
    amountOfShare: {
      label: t('termAndLegal.amountOfShare.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.amountOfShare.placeHolder'),
    },
    PlaceOfRCS: {
      label: t('termAndLegal.PlaceOfRCS.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.PlaceOfRCS.placeHolder'),
      tooltip: t('termAndLegal.PlaceOfRCS.tooltip'),
    },
    RSCNumber: {
      label: t('termAndLegal.RSCNumber.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.RSCNumber.placeHolder'),
    },
    officeAddress: {
      label: t('termAndLegal.officeAddress.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.officeAddress.placeHolder'),
    },
  };

  const specificFields: Record<string, any> = {
    language: {
      label: '',
      defaultValue: 'fr',
      placeHolder: '',
    },
    lastUpdate: {
      label: t('termAndLegal.lastUpdate.label'),
      defaultValue: format(new Date(), 'dd/MM/yyyy'),
      placeHolder: t('termAndLegal.lastUpdate.placeHolder'),
    },
    siteOperatorType: {
      label: t('termAndLegal.siteOperatorType.label'),
      defaultValue: '0',
      placeHolder: t('termAndLegal.siteOperatorType.placeHolder'),
    },
    siteUrl: {
      label: t('termAndLegal.siteUrl.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.siteUrl.placeHolder'),
    },
    returnCostOn: {
      label: t('termAndLegal.returnCostOn.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.returnCostOn.placeHolder'),
    },
  };

  const termAndLegalFields: Record<string, any> = {
    productType: {
      label: t('termAndLegal.productType.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.productType.placeHolder'),
    },
    operatorPhone: {
      label: t('termAndLegal.operatorPhone.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.operatorPhone.placeHolder'),
      tooltip: t('termAndLegal.operatorPhone.tooltip'),
    },
    customerServiceHours: {
      label: t('termAndLegal.customerServiceHours.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.customerServiceHours.placeHolder'),
    },
    customerServiceEmail: {
      label: t('termAndLegal.customerServiceEmail.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.customerServiceEmail.placeHolder'),
    },
    mediatorContract: {
      label: t('termAndLegal.mediatorContract.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.mediatorContract.placeHolder'),
      subLabel: t('termAndLegal.mediatorContract.subLabel'),
    },
    representativeFullName: {
      label: t('termAndLegal.representativeFullName.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.representativeFullName.placeHolder'),
    },
    hostingProviderName: {
      label: t('termAndLegal.hostingProviderName.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.hostingProviderName.placeHolder'),
    },
    hostingProviderFullAddress: {
      label: t('termAndLegal.hostingProviderFullAddress.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.hostingProviderFullAddress.placeHolder'),
    },
    hostingProviderPhone: {
      label: t('termAndLegal.hostingProviderPhone.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.hostingProviderPhone.placeHolder'),
    },
    directorFullName: {
      label: t('termAndLegal.directorFullName.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.directorFullName.placeHolder'),
      tooltip: t('termAndLegal.directorFullName.tooltip'),
    },
    siteOperatorPhone: {
      label: t('termAndLegal.siteOperatorPhone.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.siteOperatorPhone.placeHolder'),
    },
    siteOperatorEmail: {
      label: t('termAndLegal.siteOperatorEmail.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.siteOperatorEmail.placeHolder'),
    },
    postalAddress: {
      label: t('termAndLegal.postalAddress.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.postalAddress.placeHolder'),
    },
  };

  return {
    individualsFields,
    companyFields,
    specificFields,
    termAndLegalFields,
  };
}

export default useTermAndLegalFieldsConfigs;
