import React from 'react';

import ScratchCategory from '@/components/app/scratch/Category';
import CTA from '@/components/app/scratch/emailMarketing/CTA';
import EmailLength from '@/components/app/scratch/emailMarketing/EmailLength';
import EmailType from '@/components/app/scratch/emailMarketing/EmailType';
import Subject from '@/components/app/scratch/emailMarketing/Subject';
import Overview from '@/components/app/scratch/Overview';

export enum StepsIndifier {
  CATEGORY = 'category',
  EMAIL_TYPE = 'emailType',
  HEADER = 'header',
  EMAIL_LENGTH = 'emailLength',
  CTA_STEP = 'cta',
  OVERVIEW = 'overView',
  GENERATE = 'generate',
}

const requireStartSteps = ({ t }: any) => [
  {
    id: StepsIndifier.CATEGORY,
    title: t('scratch.choseCategory'),
    name: t('common.category'),
    component: <ScratchCategory />,
    percengate: 20,
  },
  {
    id: StepsIndifier.EMAIL_TYPE,
    title: t('scratch.enterInfo'),
    name: t('scratch.info'),
    component: <EmailType />,
    percengate: 40,
  },
];

const requireEndingSteps = ({ t }: any) => [
  {
    id: StepsIndifier.OVERVIEW,
    name: t('scratch.preview'),
    component: <Overview headerText={t('scratch.overview')} />,
    percengate: 99,
    nextButtonText: t('common.generate'),
  },
  {
    id: StepsIndifier.GENERATE,
    name: t('common.generate'),
    component: <Overview />,
    percengate: 100,
    nextButtonText: t('common.confirm'),
  },
];

export const stepperOneClicksConfigs = ({ t }: any) => [
  ...requireStartSteps({ t }),
  ...requireEndingSteps({ t }),
];

export const stepperCustomizeConfigs = ({ t }: any) => [
  ...requireStartSteps({ t }),
  {
    id: StepsIndifier.HEADER,
    title: 'Header',
    name: 'Header Name',
    component: <Subject />,
    percengate: 70,
    hideMark: true,
  },
  {
    id: StepsIndifier.EMAIL_LENGTH,
    title: 'Email Length',
    name: 'Header Name',
    component: <EmailLength />,
    percengate: 80,
    hideMark: true,
  },
  {
    id: StepsIndifier.CTA_STEP,
    title: 'CTA',
    name: 'CTA',
    component: <CTA />,
    percengate: 90,
    hideMark: true,
  },
  ...requireEndingSteps({ t }),
];
