/* eslint-disable react-hooks/exhaustive-deps */
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { addMonths, format } from 'date-fns';
import _camelCase from 'lodash/camelCase';
import _map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidInfoCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';

import {
  RadioButtonGroup,
  RadioButtonGroupItem,
} from '@/components/Buttons/RadioButtonGroup';
import CheckBoxGroup from '@/components/common/CheckBoxGroup';
import { Label } from '@/components/common/Label';
import { SelectCustom, SelectItem } from '@/components/common/Select';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/common/Tabs';
import OptionGroup from '@/components/DynamicOptionsGroup/OptionGroup';
import {
  EditedRadioGroup,
  EditedRadioItem,
} from '@/components/EditedRadioGroup';
import { EmailTypes, GenerateType } from '@/shared/configs/generateServices';
import { setEmailScratchInfo } from '@/shared/reducers/emailFromScratchReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { RootState } from '@/shared/rootReducers';
import { useGetEmailTypeQuery } from '@/shared/slices/emailFromScratchSlice';
import { useGetEventsMutation } from '@/shared/slices/scratchEventsSlice';

const formatMonthEvents = ({ month, languagei18, eventsData }: any) => {
  if (!eventsData) return [];
  return eventsData.classicEvents.groupByMonth[month].map(
    ({ id, ...rest }: any) => ({
      id,
      event: rest[`event_${languagei18}`],
    })
  );
};

function EmailType() {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language: languagei18 },
  } = useTranslation();

  const { data: emailTypeData } = useGetEmailTypeQuery();
  const {
    emailType: emailTypeState,
    events,
    chosenSeason,
    promoOfferDiscount,
    promotionDuration,
  } = useSelector(
    (emailScratchState: RootState) => emailScratchState?.emailFromScratch
  );
  const {
    seasonsData,
    generateType,
    productId,
    language: languageState,
  } = useSelector((scratchState: RootState) => scratchState?.fromScratch);

  const [emailTypes, setEmailTypes] = useState<Record<string, any>[]>([]);
  const [seasons, setSeasons] = useState<Record<string, any>[]>([]);
  const [selectedMonth, setSelectedMonth] = useState('January');
  const [staticOptionsHeader, setStaticOptionsHeader] = useState('');
  const [activeOfferDiscount, setActiveOfferDiscount] = useState(false);
  const [promoOfferDiscountOptions, setPromoOfferDiscountOptions] = useState<
    Record<string, any>[]
  >([
    { value_en: `~10% off`, value_fr: `~10 % de réduction`, id: 'r1' },
    { value_en: 'Free Shipping', value_fr: `Livraison gratuite`, id: 'r2' },
    {
      value_en: `Buy ~1 get ~2`,
      value_fr: `Achetez ~1, obtenez ~2`,
      id: 'r3',
    },
    {
      value_en: `~25$ gift Voucher`,
      value_fr: `Bon cadeau de ~25$`,
      id: 'r4',
    },
    {
      value_en: 'Others',
      id: 'r6',
      value_fr: `Autres`,
      textField: true,
    },
  ]);

  const dateFormat = 'dd/MM/yyyy';
  const nextMonth = format(addMonths(new Date(), 1), dateFormat);
  const today = format(new Date(), dateFormat);
  const [promotionDurationOptions, setPromotionDurationOptions] = useState<
    Record<string, any>[]
  >([
    {
      value_en: `Until ~${nextMonth}`,
      value_fr: `Jusqu'à ~${nextMonth}`,
      id: 'p1',
      dateField: true,
    },
    { value_en: 'During ~2 Hours', value_fr: 'Pendant ~2 heures', id: 'p2' },
    {
      value_en: `From ~${today}`,
      value_fr: `De ~${today}`,
      id: 'p3',
      dateField: true,
    },
    {
      value_en: `To ~${nextMonth}`,
      value_fr: `À ~${nextMonth}`,
      id: 'p4',
      dateField: true,
    },
    {
      value_en: 'Until Sold Out',
      value_fr: `Jusqu'à épuisement des stocks`,
      id: 'p5',
    },
    {
      value_en: 'Others',
      value_fr: 'Autres',
      id: 'p6',
      textField: true,
    },
  ]);

  const [monthsList, setMonthsList] = useState<
    Record<string, any>[] | undefined
  >();

  const [getEventsData, { data: eventsData, isLoading: getEventsLoading }] =
    useGetEventsMutation();

  const [classicEvents, setClassicEvents] = useState();
  const aiEvents = eventsData?.aiPropositions.headLines;

  const isSeasonalPromotion = emailTypeState === 'Seasonal Promotions';

  useEffect(() => {
    const changeOptionlanguagei18 = (options: Record<string, any>[]) =>
      options.map((option) => ({
        ...option,
        value: languagei18 === 'fr' ? option.value_fr : option.value_en,
      }));

    setPromoOfferDiscountOptions((prevOptions) =>
      changeOptionlanguagei18(prevOptions)
    );
    setPromotionDurationOptions((prevOptions) =>
      changeOptionlanguagei18(prevOptions)
    );
  }, [languagei18]);

  const loadAiProposional = () => {
    if (!productId || !emailTypeState) return;
    getEventsData({
      productId,
      emailType: emailTypeState,
      chosenSeason,
      language: languageState,
    });
  };

  const handleMonthChange = (month: string) => {
    setSelectedMonth(month);
    setClassicEvents(formatMonthEvents({ month, languagei18, eventsData }));
  };

  const onPromotionOfferChange = (value: string) => {
    dispatch(setEmailScratchInfo({ promoOfferDiscount: value }));
  };

  const onPromotionDurationChange = (value: string) => {
    dispatch(setEmailScratchInfo({ promotionDuration: value }));
  };

  useEffect(() => {
    if (emailTypeData) {
      const emailTypesFormat = emailTypeData.map(
        (item: Record<string, any>) => ({
          text: item[`text_${languagei18}`],
          value: item.text_en,
          tooltipText: item[`description_${languagei18}`],
        })
      );
      setEmailTypes(emailTypesFormat);
    }

    if (seasons) {
      const seasonsFormat = seasonsData.map((item: Record<string, any>) => ({
        text: item[`text_${languagei18}`],
        value: item.text_en,
      }));
      setSeasons(seasonsFormat);
    }
  }, [languagei18, emailTypeData]);

  useEffect(() => {
    if (!productId || !emailTypeState) return;
    if (generateType === GenerateType.CUSTOMIZE) {
      loadAiProposional();
    }
    if (generateType === GenerateType.ONE_CLICK) {
      setActiveOfferDiscount(false);
      dispatch(
        setEmailScratchInfo({
          events: '',
          promoOfferDiscount: '',
          promotionDuration: '',
          emailSubject: '',
          emailLength: '',
          cta: '',
        })
      );
    }
  }, [generateType, emailTypeState, chosenSeason, languagei18]);

  // Check require fields to enable/disable the continue button
  useEffect(() => {
    let stepperNextBtnDisable = false;
    switch (generateType) {
      case GenerateType.ONE_CLICK:
        stepperNextBtnDisable =
          emailTypeState === '' || (isSeasonalPromotion && chosenSeason === '');
        break;
      case GenerateType.CUSTOMIZE:
        stepperNextBtnDisable =
          events === '' ||
          (activeOfferDiscount &&
            (promoOfferDiscount === '' || promotionDuration === ''));
        break;
      default:
        break;
    }
    dispatch(setFromScratch({ stepperNextBtnDisable }));
  }, [
    activeOfferDiscount,
    events,
    emailTypeState,
    promoOfferDiscount,
    promotionDuration,
    isSeasonalPromotion,
    chosenSeason,
    generateType,
  ]);

  // Conditioning to display the classic events
  useEffect(() => {
    if (getEventsLoading) dispatch(setEmailScratchInfo({ events: '' }));
    if (!eventsData) return;

    setStaticOptionsHeader('');
    setMonthsList(undefined);

    switch (emailTypeState) {
      case EmailTypes.SEASONAL_PROMOTIONS:
        setMonthsList(
          _map(eventsData?.classicEvents.groupByMonth, (monthObj, month) => ({
            value: month,
            text: monthObj[0][`month_${languagei18}`],
          }))
        );
        handleMonthChange('January');
        setStaticOptionsHeader(
          t('scratch.orClassicEvents', {
            emailType: t('scratch.seasonalPromotions'),
          })
        );
        break;
      case EmailTypes.LIMITED_TIME_OFFERS:
      case EmailTypes.EXCLUSIVE_SALES:
        setClassicEvents(
          eventsData.classicEvents.map(({ id, ...rest }: any) => ({
            id,
            event: rest[`event_${languagei18}`],
          }))
        );
        setStaticOptionsHeader(
          t('scratch.orClassicEvents', {
            emailType: t(`scratch.${_camelCase(emailTypeState)}`),
          })
        );
        break;
      default:
    }
  }, [eventsData, getEventsLoading, languagei18]);

  const handleEmailTypeChange = (value: string) => {
    if (value === '') return;
    dispatch(setEmailScratchInfo({ emailType: value, chosenSeason: '' }));
  };

  const handleSeasonChange = (value: string) => {
    if (value === '') return;
    dispatch(setEmailScratchInfo({ chosenSeason: value }));
  };

  return (
    <div className="w-full flex justify-center">
      <Tabs defaultValue="sell" className="flex flex-col items-center w-full">
        <TabsList>
          <TabsTrigger value="sell">
            <InfoOutlinedIcon className="ml-1 me-2" />
            {t('scratch.sellsConversions')}
          </TabsTrigger>
          <TabsTrigger value="info" disabled>
            {t('scratch.informationRelational')}
          </TabsTrigger>
        </TabsList>
        <TabsContent className="text-white w-full" value="sell">
          {/* Email type */}
          <Label htmlFor="emailType">
            <h2 className="font-normal">{t('scratch.emailType')}</h2>
          </Label>
          <SelectCustom
            optionsData={(emailTypes as any) ?? []}
            setOptionsData={setEmailTypes}
            id="emailType"
            inputPlaceholder={t('scratch.emailTypePlaceHolder')}
            defaultValue={emailTypeState}
            onSelectData={(value) => handleEmailTypeChange(value)}
            addOption
            defaultOpen
          />
          {emailTypeState && (
            <>
              {isSeasonalPromotion && (
                <>
                  <Label htmlFor="season">
                    <h2 className="font-normal">{t('scratch.chosenSeason')}</h2>
                  </Label>
                  <SelectCustom
                    optionsData={(seasons as any) ?? []}
                    id="season"
                    placeholder={t('scratch.emailTypePlaceHolder')}
                    defaultValue={chosenSeason}
                    onSelectData={(value) => handleSeasonChange(value)}
                  >
                    {seasons?.map(({ value, text }) => (
                      <SelectItem key={value} value={value}>
                        {text}
                      </SelectItem>
                    ))}
                  </SelectCustom>
                </>
              )}
              <h2 className="font-normal">{t('scratch.emailType')}</h2>
              <RadioButtonGroup
                defaultValue={GenerateType.ONE_CLICK}
                className="flex"
                value={generateType}
                onValueChange={(value) =>
                  dispatch(setFromScratch({ generateType: value }))
                }
              >
                <div className="flex items-center space-x-2">
                  <RadioButtonGroupItem
                    value={GenerateType.ONE_CLICK}
                    id={GenerateType.ONE_CLICK}
                  >
                    <Label htmlFor={GenerateType.ONE_CLICK}>
                      {t('scratch.oneClickGeneration')}
                    </Label>
                  </RadioButtonGroupItem>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioButtonGroupItem
                    value={GenerateType.CUSTOMIZE}
                    id={GenerateType.CUSTOMIZE}
                  >
                    <Label htmlFor={GenerateType.CUSTOMIZE}>
                      {t('scratch.customizedGeneration')}
                    </Label>
                  </RadioButtonGroupItem>
                </div>
              </RadioButtonGroup>
            </>
          )}
          {generateType === GenerateType.CUSTOMIZE && (
            <>
              <Label htmlFor="emailType">
                <h2 className="font-normal">
                  {t('scratch.customizedGenerationHeader')}
                </h2>
              </Label>
              <OptionGroup
                headerText={t('scratch.customSeasonalPromotion')}
                aiGeneratedHeader={t('scratch.aiGeneratedHeader')}
                inputPlaceholder={t('scratch.manualTextPlaceholder')}
                staticOptionsHeader={staticOptionsHeader}
                optionEditable
                aiGeneratedOptions={aiEvents}
                selectedMonth={selectedMonth}
                staticOptions={classicEvents}
                monthList={monthsList}
                handleMonthChange={handleMonthChange}
                handleResetAiProposional={loadAiProposional}
                isLoading={getEventsLoading}
                onOptionChange={(value) =>
                  dispatch(setEmailScratchInfo({ events: value ?? '' }))
                }
              />
              <CheckBoxGroup
                className="w-full mt-5"
                text={t('scratch.promoOfferDiscount')}
                icon={
                  <BiSolidInfoCircle className="text-xl text-white bg-[#a238d5] rounded-full p-1" />
                }
                tooltipText="Promo Info"
                checked={activeOfferDiscount ?? false}
                onCheckedChange={(checked: boolean) =>
                  setActiveOfferDiscount(checked)
                }
              />
            </>
          )}
          {activeOfferDiscount && (
            <>
              <EditedRadioGroup
                selectValue={promoOfferDiscount ?? ''}
                onSelectChange={onPromotionOfferChange}
                className="mt-5"
              >
                {promoOfferDiscountOptions.map((item) => (
                  <EditedRadioItem
                    value={item.value}
                    key={item.value}
                    onSelectChange={onPromotionOfferChange}
                    {...item}
                  />
                ))}
              </EditedRadioGroup>
              <Label htmlFor="emailType">
                <h2 className="font-normal">
                  {t('scratch.promotionDuration')}
                </h2>
              </Label>
              <EditedRadioGroup
                selectValue={promotionDuration ?? ''}
                onSelectChange={onPromotionDurationChange}
              >
                {promotionDurationOptions.map((item) => (
                  <EditedRadioItem
                    value={item.value}
                    key={item.value}
                    onSelectChange={onPromotionDurationChange}
                    {...item}
                  />
                ))}
              </EditedRadioGroup>
            </>
          )}
        </TabsContent>
        <TabsContent className="text-white" value="info">
          Make changes to your Infomation & Relationel here.
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default EmailType;
