import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

function useInfluencerFieldsConfigs() {
  const { t } = useTranslation('translation', { keyPrefix: 'application' });

  const specificFields: Record<string, any> = {
    influLegalEntityType: {
      label: 'influLegalEntityType',
      defaultValue: '0',
      placeHolder: '',
    },
    isSoleTrader: {
      label: 'isSoleTrader',
      defaultValue: '0',
      placeHolder: '',
    },
    siteUrl: {
      label: t('termAndLegal.siteUrl.label'),
      defaultValue: '',
      placeHolder: t('termAndLegal.siteUrl.placeHolder'),
    },
    lastUpdate: {
      label: t('termAndLegal.lastUpdate.label'),
      defaultValue: format(new Date(), 'dd/MM/yyyy'),
      placeHolder: t('termAndLegal.lastUpdate.placeHolder'),
    },
  };

  const influencerFields: Record<string, any> = {
    brandOrCompany: {
      label: t('influencer.brandOrCompany.label'),
      defaultValue: '',
      placeHolder: t('influencer.brandOrCompany.placeHolder'),
    },
    corporateName: {
      label: t('influencer.corporateName.label'),
      defaultValue: '',
      placeHolder: t('influencer.corporateName.placeHolder'),
    },
    legalForm: {
      label: t('influencer.legalForm.label'),
      defaultValue: '',
      placeHolder: t('influencer.legalForm.placeHolder'),
    },
    capitalAmount: {
      label: t('influencer.capitalAmount.label'),
      defaultValue: '',
      placeHolder: t('influencer.capitalAmount.placeHolder'),
    },
    tradeRegisteredNumberAndCity: {
      label: t('influencer.tradeRegisteredNumberAndCity.label'),
      defaultValue: '',
      placeHolder: t('influencer.tradeRegisteredNumberAndCity.placeHolder'),
    },
    representativeName: {
      label: t('influencer.representativeName.label'),
      defaultValue: '',
      placeHolder: t('influencer.representativeName.placeHolder'),
    },
    representativePosition: {
      label: t('influencer.representativePosition.label'),
      defaultValue: '',
      placeHolder: t('influencer.representativePosition.placeHolder'),
    },
    serviceDescription: {
      label: t('influencer.serviceDescription.label'),
      defaultValue: '',
      placeHolder: t('influencer.serviceDescription.placeHolder'),
    },
    specificPromotionGoals: {
      label: t('influencer.specificPromotionGoals.label'),
      defaultValue: '',
      placeHolder: t('influencer.specificPromotionGoals.placeHolder'),
    },
    publicationsNumber: {
      label: t('influencer.publicationsNumber.label'),
      defaultValue: '',
      placeHolder: t('influencer.publicationsNumber.placeHolder'),
    },
    typeOfContent: {
      label: t('influencer.typeOfContent.label'),
      defaultValue: '',
      placeHolder: t('influencer.typeOfContent.placeHolder'),
    },
    period: {
      label: t('influencer.period.label'),
      defaultValue: '',
      placeHolder: t('influencer.period.placeHolder'),
    },
    amountNumbersLetters: {
      label: t('influencer.amountNumbersLetters.label'),
      defaultValue: '',
      placeHolder: t('influencer.amountNumbersLetters.placeHolder'),
    },
    socialMediaPlatform: {
      label: t('influencer.socialMediaPlatform.label'),
      defaultValue: '',
      placeHolder: t('influencer.socialMediaPlatform.placeHolder'),
    },
    payDaysNumber: {
      label: t('influencer.payDaysNumber.label'),
      defaultValue: '',
      placeHolder: t('influencer.payDaysNumber.placeHolder'),
    },
    freeService: {
      label: t('influencer.freeService.label'),
      defaultValue: '',
      placeHolder: t('influencer.freeService.placeHolder'),
    },
    contractPeriodYearNumber: {
      label: t('influencer.contractPeriodYearNumber.label'),
      defaultValue: '',
      placeHolder: t('influencer.contractPeriodYearNumber.placeHolder'),
    },
    contractTerminateNotifyDaysNumber: {
      label: t('influencer.contractTerminateNotifyDaysNumber.label'),
      defaultValue: '',
      placeHolder: t(
        'influencer.contractTerminateNotifyDaysNumber.placeHolder'
      ),
    },
    location: {
      label: t('influencer.location.label'),
      defaultValue: '',
      placeHolder: t('influencer.location.placeHolder'),
    },
    influencer: {
      label: t('influencer.influencer.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencer.placeHolder'),
    },
  };

  const influencerIndividualEntity: Record<string, any> = {
    influencerTitle: {
      label: t('influencer.influencerTitle.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerTitle.placeHolder'),
    },
    influencerFirstname: {
      label: t('influencer.influencerFirstname.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerFirstname.placeHolder'),
    },
    influencerLastname: {
      label: t('influencer.influencerLastname.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerLastname.placeHolder'),
    },
    influencerAddress: {
      label: t('influencer.influencerAddress.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerAddress.placeHolder'),
    },
    influencerCountry: {
      label: t('influencer.influencerCountry.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerCountry.placeHolder'),
    },
    influencerEmail: {
      label: t('influencer.influencerEmail.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerEmail.placeHolder'),
    },
  };

  const influencerCompanyEntity: Record<string, any> = {
    influencerCorporateName: {
      label: t('influencer.influencerCorporateName.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerCorporateName.placeHolder'),
    },
    influencerLegalForm: {
      label: t('influencer.influencerLegalForm.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerLegalForm.placeHolder'),
    },
    influencerCapitalAmount: {
      label: t('influencer.influencerCapitalAmount.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerCapitalAmount.placeHolder'),
    },
    influencerCorporateAddress: {
      label: t('influencer.influencerCorporateAddress.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerCorporateAddress.placeHolder'),
    },
    influencerCorporateRSCAndCity: {
      label: t('influencer.influencerCorporateRSCAndCity.label'),
      defaultValue: '',
      placeHolder: t('influencer.influencerCorporateRSCAndCity.placeHolder'),
    },
    influencerCorporateRepresentativeName: {
      label: t('influencer.influencerCorporateRepresentativeName.label'),
      defaultValue: '',
      placeHolder: t(
        'influencer.influencerCorporateRepresentativeName.placeHolder'
      ),
    },
    influencerCorporateRepresentativePosition: {
      label: t('influencer.influencerCorporateRepresentativePosition.label'),
      defaultValue: '',
      placeHolder: t(
        'influencer.influencerCorporateRepresentativePosition.placeHolder'
      ),
    },
  };

  const isSoleTraderField: Record<string, any> = {
    sireneNumber: {
      label: t('influencer.sireneNumber.label'),
      defaultValue: '',
      placeHolder: t('influencer.sireneNumber.placeHolder'),
    },
  };

  return {
    specificFields,
    influencerFields,
    influencerIndividualEntity,
    influencerCompanyEntity,
    isSoleTraderField,
  };
}

export default useInfluencerFieldsConfigs;
