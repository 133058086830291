/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/forbid-component-props */
import { FileCopyOutlined } from '@material-ui/icons';
import Button from 'components/common/Button';
import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Skeleton from '@/components/common/Skeleton';
import { RefererManageProps } from '@/shared/types/affiliate';

export function RefererManage({
  isLoading,
  coupons,
  handleCopy,
}: RefererManageProps) {
  return (
    <div className="referer-manage">
      <h1 className="account-headd xs:!px-6 md:!px-60">Referer URL & Coupon</h1>
      <div className="xs:px-6 sm:px-16">
        <p className="text-xs text-gray-500">
          Vous pouvez partager l&apos;URL suivante avec les utilisateurs pour
          les inviter à rejoindre votre programme de parrainage.
        </p>
        {isLoading ? (
          <>
            <Skeleton className="w-full h-14 opacity-5 bg-defaultBlackLight mb-2" />
            <Skeleton className="w-full h-14 opacity-5 bg-defaultBlackLight mb-2" />
            <Skeleton className="w-full h-14 opacity-5 bg-defaultBlackLight mb-2" />
          </>
        ) : (
          <>
            {coupons?.length <= 0 && (
              <div className="p-5 text-sm [&>_p]:m-0 [&>_p]:leading-7 mt-2 bg-white bg-opacity-10 rounded-lg border border-solid border-grayLight">
                You don&apos;t have any coupon right now!
              </div>
            )}
            {coupons?.length > 0 && (
              <Scrollbar style={{ height: 451 }}>
                {coupons.map(
                  ({
                    referer_url: refUrl,
                    promotion_code: promotionCode,
                    coupon_name: couponName,
                    discount_percent: discount,
                  }) => (
                    <div
                      className="group mb-2 bg-neutral-800 border border-neutral-700 rounded-lg overflow-hidden"
                      key={couponName}
                    >
                      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
                        <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
                          <div className="space-y-2 w-full">
                            <div className="flex space-x-2">
                              <span className="xs:hidden md:block max-h-[14px] mt-1 whitespace-nowrap ml-2 px-2.5 py-1 rounded-full font-medium text-xs text-white bg-primary">
                                {discount}% OFF
                              </span>
                              <div className="flex flex-col">
                                <h2 className="inline-flex text-base font-medium xs:text-sm m-0">
                                  <span className="line-clamp-2">
                                    <span className="md:hidden font-medium text-xs text-primary bg-transparent">
                                      {discount}% OFF
                                    </span>{' '}
                                    {refUrl}
                                  </span>
                                  <Button
                                    data-tooltip-id="copy-tooltip"
                                    type="button"
                                    className="inline-flex ml-2 items-center border-none cursor-pointer text-xs text-zinc-400 bg-transparent"
                                    onClick={() => handleCopy(refUrl)}
                                    icon={
                                      <FileCopyOutlined
                                        className="mr-2"
                                        fontSize="small"
                                      />
                                    }
                                    text=""
                                  />
                                </h2>
                                <div className="inline-grid grid-cols-3 xs:grid-cols-2 gap-2">
                                  <div className="flex items-center">
                                    <span className="hidden sm:inline-block">
                                      <i className="las la-bed text-lg" />
                                    </span>
                                    <span className="inline-flex text-xs text-neutral-500 dark:text-neutral-400">
                                      <span>Promotion Code: </span>
                                      <Button
                                        type="button"
                                        data-tooltip-id="copy-tooltip"
                                        className="p-0 ml-1 bg-transparent border-none text-primary font-bold"
                                        onClick={() =>
                                          handleCopy(promotionCode)
                                        }
                                        text={promotionCode}
                                      />
                                    </span>
                                  </div>
                                  <div className="flex items-center space-x-2">
                                    <span className="hidden sm:inline-block">
                                      <i className="las la-bath text-lg" />
                                    </span>
                                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                                      Name: <strong>{couponName}</strong>
                                    </span>
                                  </div>
                                </div>
                                <ReactTooltip
                                  id="copy-tooltip"
                                  place="bottom"
                                  content="Click me to copy"
                                  className="!text-xs !font-normal !rounded-md !p-1 !px-2 !bg-primary"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Scrollbar>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default RefererManage;
