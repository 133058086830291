/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
// import Modal from 'react-modal';
// import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';

import './css/faq.css';
import Animation from './common/Animation';
import Skeleton from './common/Skeleton';

import { useGetFaqsInformationQuery } from '@/shared/slices/homePageSlice';

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const { data: questions, isLoading } = useGetFaqsInformationQuery();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'home' });
  // const [whatsappModel, setWhatsappModel] = useState(false);
  const { language } = i18n;

  const toggleAnswer = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <Animation
      className="relative z-[2] p-[3.125rem_0] text-white flex flex-col justify-center items-center"
      id="faq"
    >
      <h2 className="text-[1.5rem] font-semibold">{t('faqTitle')}</h2>
      {isLoading ? (
        <div>
          <div className="text-white flex justify-center mb-3">Loading...</div>
          <Skeleton className="col-span-2 rounded h-[60px] w-[1000px]" />
        </div>
      ) : (
        <div>
          {questions?.map((question, index) => (
            <div
              key={
                language === 'fr' ? question.question_fr : question.question_en
              }
              className="bg-[rgba(217,217,217,0.10)] m-[20px_0px] cursor-pointer rounded-[6px] xs:w-[330px] sm:w-[600px] md:w-[700px] lg:w-[900px]"
            >
              <button
                type="button"
                className="flex items-center justify-between w-full text-base font-medium text-white bg-transparent border-0 rounded-sm question"
                onClick={() => toggleAnswer(index)}
              >
                <span>
                  {language === 'fr'
                    ? question.question_fr
                    : question.question_en}
                </span>
                <span className="material-symbols-outlined question-icon">
                  {activeIndex === index
                    ? 'remove_circle_outline'
                    : 'add_circle_outline'}
                </span>
              </button>
              {activeIndex === index && (
                <div
                  className="ms-5 block text-[1rem] max-w-[760px] p-[0px_20px_20px_20px]"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html:
                      language === 'fr'
                        ? question.answer_fr
                        : question.answer_en,
                  }}
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div
        className="flex flex-col items-center justify-center p-[90px]"
        id="contact"
      >
        <h2 className="mt-[25px] text-center w-full xs:w-[200px] sm:w-full">
          {t('contactTitle1')}&nbsp;
          <span
            className={`inline-block relative ${language === 'fr' && `text-[#a238d5]`}`}
          >
            {language === 'fr'
              ? `${t('contactTitle2')}`
              : `${t('contactTitle3')}`}
            &nbsp;
          </span>
          <span
            className={`inline-block relative ${language === 'en' && `text-[#a238d5]`}`}
          >
            {language === 'fr'
              ? `${t('contactTitle3')}`
              : `${t('contactTitle2')}`}
            &nbsp;
          </span>
        </h2>
        <div className="p-[40px] justify-around w-[800px] flex xs:flex-col xs:w-[335px] sm:flex-row sm:w-[600px] md:w-[800px]">
          <div className="flex-row-reverse font-medium bg-[rgba(255,255,255,0.2)] backdrop-blur-[20px] rounded-[5px] p-[18px_60px] items-center flex mt-[15px] hover:scale-[1.01]">
            <span className="ml-2 material-symbols-outlined option-contacts">
              contact_support
            </span>
            <a
              href="https://wa.me/+33665766743"
              target="_blank"
              type="button"
              rel="noreferrer"
              // onClick={() => setWhatsappModel(true)}
              className="mr-[15px] no-underline text-white cursor-pointer"
            >
              {t('contactButton1')}
            </a>
          </div>
          <div className="flex-row-reverse font-medium bg-[rgba(255,255,255,0.2)] backdrop-blur-[20px] rounded-[5px] p-[18px_60px] items-center flex mt-[15px]  hover:scale-[1.01]">
            <span className="material-symbols-outlined option-contacts">
              mail
            </span>
            <a
              href="mailto:support@productly.app"
              className="mr-[15px] no-underline text-white cursor-pointer"
            >
              {t('contactButton2')}
            </a>
          </div>
        </div>
      </div>
      {/* <Modal
        className="modal-container"
        isOpen={whatsappModel}
        overlayClassName="modal-overlay"
      >
        <div className="max-w-md bg-white rounded-lg md:min-w-full p-7 ">
          <div className="animate-pulse">
            Notre messagerie Whatsapp rencontre un problème temporaire, nous
            vous prions de bien vouloir nous contacter par e-mail en attendant
            que ce problème soit résolu.
          </div>
          <div className="flex justify-center mt-4">
            <Button
              className="p-2 px-8 mr-2 text-white border-none rounded-lg bg-primary"
              text="Fermer"
              type="button"
              onClick={() => setWhatsappModel(false)}
            />
          </div>
        </div>
      </Modal> */}
    </Animation>
  );
}

export default Faq;
