import { apiSlice } from './apiSlice';

const homePageInformationsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFaqsInformation: build.query<any, void>({
      query: () => ({
        url: `faqs`,
        method: 'GET',
      }),
    }),
    getMocksInformation: build.query<any, void>({
      query: () => ({
        url: `mocks`,
        method: 'GET',
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetFaqsInformationQuery, useGetMocksInformationQuery } =
  homePageInformationsSlice;
