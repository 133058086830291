import React from 'react';

import { Input } from '@/components/common/Input';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/common/shadcn-ui/Form';
import { cn } from '@/lib/utils';

export default function FormFieldInput({
  control,
  name,
  label,
  placeholder,
  inputClass,
  messageClass,
}: any) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              className={cn(
                'bg-defaultBlack text-base placeholder:text-muted',
                inputClass
              )}
              {...field}
            />
          </FormControl>
          <FormMessage className={cn('text-red-400 italic', messageClass)} />
        </FormItem>
      )}
    />
  );
}
