/* eslint-disable jsx-a11y/anchor-is-valid */
import useCountDown from 'components/hooks/useCountDown';
import React, { ChangeEvent, useRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import '../../components/css/register.css';
import { toast } from 'react-toastify';
import {
  resendVerifyEmail,
  productionLog,
  loginSessionCheck,
} from 'utils/helpers';

import GoogleLoginBtn from '../../components/Buttons/GoogleLogin';
import Animation from '../../components/common/Animation';
import Button from '../../components/common/Button';

import Header from '@/components/Header';

interface RegisterFormDataProps {
  username: string;
  email: string;
  password: string;
  referer: string;
  recaptchaToken: string;
}

function Register() {
  const [formData, setFormData] = useState<RegisterFormDataProps>({
    username: '',
    email: '',
    password: '',
    referer: '',
    recaptchaToken: '',
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [verifyMessage, setVerifyMessage] = useState<string | null>(null);
  const { timeDown: timeToResendEmail, setTimeDown } = useCountDown(0);
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = async () => {
    try {
      const reCaptchaToken = captchaRef.current?.getValue();
      // not send request if those infos are empty
      const { username, email, password } = formData;
      if (!username || !email || !password) {
        setErrorMessage(`${t('errors.missingInformation')}`);
        return;
      }
      if (!reCaptchaToken) {
        setErrorMessage(`${t('errors.emptyRecaptcha')}`);
        return;
      }
      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/auth/register`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...formData,
            recaptchaToken: reCaptchaToken,
          }),
        }
      );
      captchaRef.current?.reset();
      const errorResponse = await response.text();

      if (response.ok) {
        toast.success(`${t('registerSuccessToast')}`);
        setVerifyMessage('send');
      } else if (errorResponse === 'E-mail dejà utilisé') {
        setErrorMessage(`${t('errors.emailExist')}`);
      } else {
        productionLog(errorResponse);
        setErrorMessage(errorResponse);
      }
    } catch (error) {
      productionLog(error);
      setErrorMessage(`${t('errors.errorOccur')}`);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.VITE_APP_API_URL}/auth/google`;
  };

  useEffect(() => {
    const referer = searchParams.get('ref');
    const token = localStorage.getItem('productly');
    const isLogin = loginSessionCheck(token);
    if (token && isLogin) {
      navigate('/app');
      return;
    }
    if (!referer) return;
    setFormData({ ...formData, referer });
    setSearchParams('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResend = async () => {
    const responseStatus = await resendVerifyEmail(formData?.email);
    if (responseStatus === true) {
      setTimeDown(59);
      toast.success(`${t('resendVerificationEmailSuccess')}`);
    } else {
      toast.error(`${responseStatus}`);
    }
  };

  return (
    <div>
      {!verifyMessage ? (
        <>
          <div className="absolute w-full h-[1050px] pointer-events-none bg-register-gradient-background" />
          <Header />
          <Animation className="form-container">
            <div className="form-parent">
              <form className="form-log-reg">
                <h3>{t('registrationPageTitle')}</h3>
                <label htmlFor="username" className="!flex flex-col">
                  {t('usernameLabel')}
                  <input
                    required
                    type="text"
                    id="username"
                    name="username"
                    placeholder={t('usernameLabel')}
                    value={formData.username}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="email" className="!flex flex-col">
                  Email
                  <input
                    required
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="password" className="!flex flex-col">
                  {t('passwordLabel')}
                  <input
                    required
                    id="password"
                    type="password"
                    name="password"
                    placeholder={t('passwordLabel')}
                    value={formData.password}
                    onChange={handleChange}
                  />
                </label>
                <div className="mt-8 mb-4 ml-5 transform scale-112">
                  <ReCAPTCHA
                    theme="dark"
                    ref={captchaRef}
                    sitekey={process.env.VITE_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
                  />
                </div>
                <Button
                  type="button"
                  className="submit-button"
                  text={t('signUpBtn')}
                  onClick={handleRegister}
                />
                {errorMessage && (
                  <div className="error-box">{errorMessage}</div>
                )}
                <GoogleLoginBtn onClick={handleGoogleLogin} />
                <div className="register-bottom-log-form">
                  <Link to="/login" className="register-button-log">
                    {t('goToLoginPage')}
                  </Link>
                </div>
              </form>
            </div>
          </Animation>
        </>
      ) : (
        <>
          <div className="absolute w-full h-full pointer-events-none bg-register-verify-gradient-background" />
          <Header />
          <Animation className="form-container">
            <div className="flex flex-col text-center text-white form-parent">
              <div className="text-lg">{t('registerSuccess')}</div>
              <div className="mt-4 text-xs">
                <span className="text-gray-500">{t('noEmailSent')}</span>
                {timeToResendEmail > 0 ? (
                  <span> {timeToResendEmail}</span>
                ) : (
                  <a
                    className="text-orange-800 no-underline hover:text-yellow-400"
                    href="#"
                    onClick={handleResend}
                  >
                    {` `}
                    {t('resendEmail')}
                  </a>
                )}
              </div>
              <div className="mt-8">
                <Link to="/login">{t('goToLoginPageRegister')}</Link>
              </div>
            </div>
          </Animation>
        </>
      )}
    </div>
  );
}

export default Register;
