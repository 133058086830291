/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Label } from '@/components/common/Label';
import OptionGroup from '@/components/DynamicOptionsGroup/OptionGroup';
import { setEmailScratchInfo } from '@/shared/reducers/emailFromScratchReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { RootState } from '@/shared/rootReducers';
import { usePostEmailSubjectGenerateMutation } from '@/shared/slices/emailFromScratchSlice';

function Subject() {
  const {
    t,
    i18n: { language: languagei18 },
  } = useTranslation();
  const {
    emailFromScratch: {
      emailType,
      chosenSeason,
      events,
      promoOfferDiscount,
      promotionDuration,
      emailSubject,
    },
    fromScratch: { productId = 0, projectName = '', language: languageState },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [getSubjectGenerate, { data: subjectData, isLoading }] =
    usePostEmailSubjectGenerateMutation();

  const loadAiProposional = () => {
    getSubjectGenerate({
      productId,
      projectName,
      emailType: emailType ?? '',
      chosenSeason: chosenSeason ?? '',
      events: events ?? '',
      promoOfferDiscount: promoOfferDiscount ?? '',
      promotionDuration: promotionDuration ?? '',
      language: languageState ?? languagei18,
    });
  };

  useEffect(() => {
    loadAiProposional();
  }, []);

  useEffect(() => {
    loadAiProposional();
  }, [languagei18]);

  useEffect(() => {
    let stepperNextBtnDisable = false;
    if (!emailSubject || emailSubject?.length <= 0) {
      stepperNextBtnDisable = true;
    }
    dispatch(setFromScratch({ stepperNextBtnDisable }));
  }, [emailSubject]);

  const aiGeneratedSubjects = subjectData?.aiGeneratedSubjects;
  return (
    <>
      <Label htmlFor="emailType">
        <h2 className="font-normal">{t('scratch.emailSubject')}</h2>
      </Label>
      <OptionGroup
        headerText={t('scratch.customEmailSubject')}
        optionEditable
        inputPlaceholder={t('scratch.manualTextPlaceholder')}
        aiGeneratedHeader={t('scratch.aiGeneratedHeader')}
        aiGeneratedOptions={aiGeneratedSubjects?.headLines}
        handleResetAiProposional={loadAiProposional}
        isLoading={isLoading}
        onOptionChange={(value) => {
          dispatch(setEmailScratchInfo({ emailSubject: value }));
        }}
      />
    </>
  );
}

export default Subject;
