import React from 'react';
import { SiShopify } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../common/Button';

import { cn } from '@/lib/utils';
import { setApplicationState } from '@/shared/reducers/applicationReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/reduxStore';

interface ConnectToShopifyButtonProps {
  className?: string;
  text?: any;
}

function ConnectToShopifyButton({
  text,
  className,
}: ConnectToShopifyButtonProps) {
  const { user } = useSelector((state: RootState) => state?.auth);
  const dispatch = useDispatch();
  return (
    <Button
      text={text}
      onClick={() => {
        dispatch(
          setProductsState({
            shopifyConnectWizard: true,
            shopifyImport: false,
          })
        );
        dispatch(setFromScratch({ active: false }));
        dispatch(setApplicationState({ active: false }));
      }}
      icon={<SiShopify className="ml-2 xs:text-lg lg:size-8" />}
      className={cn(
        'w-full flex items-center justify-center font-semibold rounded-sm bg-shopifyDark cursor-pointer py-2.5 px-4 border-none text-white text-[10px] md:text-xs',
        user?.shopify && 'hidden',
        className
      )}
    />
  );
}

export default ConnectToShopifyButton;
