/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/role-has-required-aria-props */

import { CheckIcon } from '@radix-ui/react-icons';
import { CommandList } from 'cmdk';
import * as React from 'react';
import { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa6';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from '@/components/common/Command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/common/Popover';
import { cn } from '@/lib/utils';

interface ClassicEvenButtonProps {
  selectValue?: string;
  monthList: Record<string, any>[];
  handleMonthChange?: (value: string) => void;
  disabled?: boolean;
}

function ClassicEvenButton({
  selectValue,
  monthList,
  handleMonthChange,
  disabled = false,
}: ClassicEvenButtonProps) {
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          type="button"
          role="combobox"
          aria-expanded={open}
          className="flex items-center justify-normal w-[100px] px-3 py-1.5 text-xs font-semibold text-black bg-white border-0 rounded-md disabled:bg-zinc-600"
          disabled={disabled}
        >
          {selectValue}
          <FaAngleDown
            strokeWidth={0.5}
            size={8}
            color="000000"
            className="h-3 w-3 shrink-0"
          />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[100px] p-0">
        <Command>
          <CommandEmpty>No Event Found</CommandEmpty>
          <CommandList className="bg-white">
            <CommandGroup>
              {monthList.map(({ value, text }) => (
                <CommandItem
                  className="text-xs"
                  key={value}
                  value={value}
                  onSelect={(currentValue) => {
                    if (!handleMonthChange) return;
                    handleMonthChange(currentValue);
                    setOpen(false);
                  }}
                >
                  {text}
                  <CheckIcon
                    className={cn(
                      'ml-auto h-4 w-4',
                      selectValue === value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
export default ClassicEvenButton;
