/* eslint-disable react/no-danger */
import React, { useState } from 'react';

import formatLineBreaks from '@/utils/format';

function Admin() {
  const [formState, setFormState] = useState({
    amazonUrl: '',
    numReviews: '',
    questions: [],
    temperatureE: '',
    topP: '',
    temperature: '',
  });

  const [openAIResponses, setOpenAIResponses] = useState([]);
  const handleChangeA = (event: any) => {
    let { value } = event.target;

    // If the form field is the 'questions' textarea, split the value by newline characters to create an array
    if (event.target.name === 'questions') {
      value = value.split('\n');
    }

    setFormState({
      ...formState,
      [event.target.name]: value,
    });
  };

  const adminSUB = async (event: any) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/process-admin`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formState),
        }
      );

      const data = await response.json();

      setOpenAIResponses(data.responses);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="max-w-[800px] w-[600px]  mx-auto shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)]">
      <h3 className="underline text-[#333] mb-5">Admin</h3>
      <form onSubmit={adminSUB} className="grid gap-5">
        {' '}
        <div>
          <label htmlFor="amazonUrl" className="block pb-[5p] font-bold">
            Amazon Product URL:
            <input
              id="amazonUrl"
              name="amazonUrl"
              type="url"
              value={formState.amazonUrl}
              onChange={handleChangeA}
              required
              className="w-full p-2.5 border border-[#ddd] rounded-[3px]"
            />
          </label>
        </div>
        <div>
          <label htmlFor="numReviews" className="block pb-[5p] font-bold">
            Number of Reviews:
            <select
              id="numReviews"
              name="numReviews"
              value={formState.numReviews}
              onChange={handleChangeA}
              required
              className="w-full p-2.5 border border-[#ddd] rounded-[3px]"
            >
              <option value="">--Select--</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="60">60</option>
              <option value="70">70</option>
              <option value="80">80</option>
              <option value="90">90</option>
              <option value="100">100</option>
            </select>
          </label>
        </div>
        <div>
          <label htmlFor="temperatureE" className="block pb-[5p] font-bold">
            Temperature:
            <input
              id="temperatureE"
              name="temperatureE"
              type="number"
              min="0"
              max="1"
              step="0.1"
              value={formState.temperature}
              onChange={handleChangeA}
              required
              className="w-full p-2.5 border border-[#ddd] rounded-[3px]"
            />
          </label>
        </div>
        <div>
          <label htmlFor="topP" className="block pb-[5p] font-bold">
            Top P:
            <input
              id="topP"
              name="topP"
              type="number"
              min="0"
              max="1"
              step="0.1"
              value={formState.topP}
              onChange={handleChangeA}
              required
              className="w-full p-2.5 border border-[#ddd] rounded-[3px]"
            />
          </label>
        </div>
        <div>
          <label htmlFor="questions" className="block pb-[5p] font-bold">
            Questions:
            <textarea
              id="questions"
              name="questions"
              value={formState.questions.join('\n')}
              onChange={handleChangeA}
              required
              className="w-full p-2.5 border border-[#ddd] rounded-[3px]"
            />
          </label>
        </div>
        <button
          type="submit"
          className="px-5 py-2.5 bg-[#5c6bc0] text-white border-none rounded-[3px] cursor-pointer hover:bg-[#3f51b5]"
        >
          Submit
        </button>
      </form>
      <h3 className="underline text-[#333] mb-5">OpenAI Responses</h3>
      {openAIResponses?.map((response) => (
        <p
          className="border border-[#ddd] p-2.5 rounded-[3px]"
          key={Math.random().toString(36).substring(7)}
          dangerouslySetInnerHTML={{
            __html: formatLineBreaks(response),
          }}
        />
      ))}
    </div>
  );
}

export default Admin;
