import jwtDecode from 'jwt-decode';
import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ children }) {
  const location = useLocation();
  const token = localStorage.getItem('productly');

  try {
    jwtDecode(token);
    return children;
  } catch (error) {
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: location.search,
        }}
        state={{ previousPath: location.pathname }}
      />
    );
  }
}

export default PrivateRoute;
