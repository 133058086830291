import React from 'react';

function KeyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className="ml-2"
    >
      <path
        d="M20.0003 1L18.0003 3M18.0003 3L21.0003 6L17.5003 9.5L14.5003 6.5M18.0003 3L14.5003 6.5M10.3903 10.61C10.9066 11.1195 11.3171 11.726 11.598 12.3948C11.879 13.0635 12.0249 13.7813 12.0273 14.5066C12.0297 15.232 11.8887 15.9507 11.6122 16.6213C11.3357 17.2919 10.9293 17.9012 10.4164 18.4141C9.90351 18.9271 9.2942 19.3334 8.62358 19.6099C7.95296 19.8864 7.23427 20.0275 6.50891 20.025C5.78354 20.0226 5.06582 19.8767 4.39707 19.5958C3.72831 19.3148 3.12174 18.9043 2.61227 18.388C1.6104 17.3507 1.05604 15.9614 1.06857 14.5193C1.0811 13.0772 1.65953 11.6977 2.67927 10.678C3.69902 9.65825 5.07849 9.07982 6.52057 9.06729C7.96265 9.05476 9.35196 9.60913 10.3893 10.611L10.3903 10.61ZM10.3903 10.61L14.5003 6.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default KeyIcon;
