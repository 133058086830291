/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/function-component-definition */
import clsx from 'clsx';
import React, { useState } from 'react';
import { EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import FeatureDemo from './FeatureDemo';

import Image3 from '../assets/camera.png'; // Kit élastiques de musculation
import Image5 from '../assets/charging-packback.jpg'; // Sac à dos de voyage antivol
import Image1 from '../assets/hismile.png'; // Dentifrice correcteur de couleur
import Image2 from '../assets/kit-bande.jpg'; // Caméra de surveillance
import Image6 from '../assets/revitalift-500.jpg'; // Revitalift, crème anti-âge
import Image4 from '../assets/smart-watch.jpg'; // Montre connectée
import Skeleton from '../common/Skeleton';

import ProductlyIcon from '@/components/assets/productly.png';
import cn from '@/utils/cn';

interface ProductProps {
  id: number;
  name_fr: string;
  description_fr: string;
  anglesmarketing_fr: string;
  objections_fr: string;
  email1_fr: string;
  email2_fr: string;
  email3_fr: string;
  faq_fr: string;
  briefinflu_fr: string;
  name_en: string;
  description_en: string;
  anglesmarketing_en: string;
  objections_en: string;
  email1_en: string;
  email2_en: string;
  email3_en: string;
  faq_en: string;
  briefinflu_en: string;
}

interface SlideProductsProps {
  products: ProductProps[];
  isProductsLoading: boolean;
}

const DemoLoading = () => (
  <div>
    <div className="flex items-center justify-center opacity-20">
      <Skeleton className="xs:!w-[60px] xs:h-[80px] 3xs:!w-[63px] 4xs:!w-[63px] 4xs:h-[85px] sm:!w-[80px] sm:h-[140px] md:!w-[90px] md:h-[160px] lg:!w-[130px] lg:h-[180px] xl:!w-[200px] xl:h-[250px] object-contain rounded-[10px] bg-white" />
      <Skeleton className="xs:!w-[60px] xs:h-[90px] 3xs:!w-[63px] 4xs:!w-[63px] 4xs:h-[95px] sm:!w-[100px] sm:h-[160px] md:!w-[120px] md:h-[190px] lg:!w-[160px] lg:h-[210px] xl:!w-[250px] xl:h-[300px] object-contain rounded-[10px] bg-white" />
      <Skeleton className="xs:!w-[80px] xs:h-[120px] 3xs:!w-[85px] 4xs:!w-[103px] 4xs:h-[135px] sm:!w-[140px] sm:h-[200px] md:!w-[160px] md:h-[230px] lg:!w-[210px] lg:h-[260px] xl:!w-[290px] xl:h-[350px] object-contain rounded-[10px] bg-white" />
      <Skeleton className="xs:!w-[60px] xs:h-[90px] 3xs:!w-[63px] 4xs:!w-[63px] 4xs:h-[95px] sm:!w-[100px] sm:h-[160px] md:!w-[120px] md:h-[190px] lg:!w-[160px] lg:h-[210px] xl:!w-[250px] xl:h-[300px] object-contain rounded-[10px] bg-white" />
      <Skeleton className="xs:!w-[60px] xs:h-[80px] 3xs:!w-[63px] 4xs:!w-[63px] 4xs:h-[85px] sm:!w-[80px] sm:h-[140px] md:!w-[90px] md:h-[160px] lg:!w-[130px] lg:h-[180px] xl:!w-[200px] xl:h-[250px] object-contain rounded-[10px] bg-white" />
    </div>
    <div className="flex justify-center">
      <div className="mt-[60px] xs:w-[290px] 2xs:w-[320px] 3xs:w-[360px] 4xs:w-[450px] sm:w-[550px] md:w-[650px] lg:w-[850px] rounded-[20px]  flex flex-row items-start justify-center h-[400px] bg-[#121212] text-white border-[0.6px] border-solid border-primary/50 font-['Poppins'] shadow-lg shadow-[#A037D3]/10">
        <div className="h-full text-center border-solid border-[0.6px] border-l-0 border-y-0 border-primary/50 xs:w-[124px] sm:w-[193px] lg:w-[240px]">
          <img
            className="py-[25px]"
            src={ProductlyIcon}
            width={80}
            alt="Productly icon"
          />
          <div className="xs:text-[10px] sm:text-xs text-white max-w-full">
            <div className="flex flex-col px-3 m-0 list-none">
              <div className="w-full py-[6px] my-2 rounded-sm">
                <div className="flex flex-col items-center pl-[2px] cursor-pointer text-white opacity-20">
                  <Skeleton className="w-full h-7 mb-4" />
                  <Skeleton className="w-full h-7 mb-4" />
                  <Skeleton className="w-full h-7 mb-4" />
                  <Skeleton className="w-full h-7 mb-4" />
                  <Skeleton className="w-full h-7" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="border-solid border-x-0 border-t-0 border-[0.6px] border-primary/50">
            <div className="text-center xs:px-2 xs:text-[13px] sm:text-[17px] font-semibold leading-[31.5px] py-4 flex justify-center">
              <Skeleton className="w-3/4 h-7 opacity-20" />
            </div>
          </div>
          <div className="min-h-[240px] xs:px-5 sm:px-10 xs:py-2 sm:py-5 text-xs max-h-[240px] overflow-y-auto overflow-x-hidden xs:leading-[20px] sm:leading-[30px] opacity-20">
            <Skeleton className="w-full h-7 mb-4" />
            <Skeleton className="w-full h-32 mb-4" />
            <Skeleton className="w-full h-7 mb-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SlideProducts: React.FC<SlideProductsProps> = ({
  products,
  isProductsLoading,
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState<ProductProps | null>();

  const handleSlideChange = (index: number) => {
    setActiveSlideIndex(index);
    setSelectedProduct(products[index]);
  };

  const getImage = (id: number) => {
    switch (id) {
      case 0:
        return Image1;
      case 1:
        return Image3;
      case 2:
        return Image2;
      case 3:
        return Image4;
      case 4:
        return Image5;
      case 5:
        return Image6;
      default:
        return Image1;
    }
  };

  return (
    <>
      {isProductsLoading ? (
        <DemoLoading />
      ) : (
        <Swiper
          effect="coverflow"
          slidesPerView="auto"
          loop
          grabCursor
          centeredSlides
          coverflowEffect={{
            rotate: 0,
            stretch: 8,
            depth: 180,
            modifier: 1,
            slideShadows: false,
            scale: 1,
          }}
          modules={[EffectCoverflow]}
          className="xl:w-[1300px] lg:w-[970px] xs:w-[350px] 3xs:w-[380px] 4xs:w-[460px] sm:w-[620px] md:w-[715px] py-[50px] mt-10 xs:border-red-500 sm:border-yellow-500 md:border-blue-500 lg:border-green-500 xl:border-cyan-500"
          onTransitionEnd={(swiper) => {
            const { realIndex } = swiper;
            handleSlideChange(realIndex);
            swiper.loopedSlides = products.length - 1;
          }}
          onAfterInit={(swiper) => {
            swiper.params.slideToClickedSlide = true;
          }}
        >
          {products?.map(({ id }, index) => (
            <SwiperSlide
              key={id}
              className={cn(
                `py-1 xs:!w-[99px] 3xs:!w-[108px] 4xs:!w-[128px] sm:!w-[169px] md:!w-[193px] lg:!w-[257px] xl:!w-[342px] text-center`,
                [0, products.length].includes(index)
                  ? 'cursor:none'
                  : 'cursor-pointer'
              )}
            >
              <svg
                style={{
                  background: `url(${getImage(index)})`,
                  backgroundColor: 'white',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={clsx(
                  `xs:!w-[80px] xs:h-[120px] 3xs:!w-[85px] 4xs:!w-[103px] 4xs:h-[135px] sm:!w-[140px] sm:h-[200px] md:!w-[160px] md:h-[230px] lg:!w-[210px] lg:h-[260px] xl:!w-[290px] xl:h-[350px] object-contain rounded-[10px] bg-white`,
                  index === activeSlideIndex &&
                    'border-solid md:border-2 lg:border-3 xs:border-1 border-[#8729C1] bg-white'
                )}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {selectedProduct && <FeatureDemo product={selectedProduct} />}
    </>
  );
};

export default SlideProducts;
