import React from 'react';

import Star from '@/assets/shopify/star.svg';
import { uniqueId } from 'lodash';

interface RatingStarsProps {
  starCount?: number;
  text?: string;
  starClass?: string;
}

function RatingStars({
  starCount = 5,
  text,
  starClass = '',
}: RatingStarsProps) {
  return (
    <>
      {Array(starCount)
        .fill(0)
        .map(() => (
          <img src={Star} className={starClass} alt="star" key={uniqueId()} />
        ))}
      {text && <p className="pl-3 font-semibold">{text}</p>}
    </>
  );
}

export default RatingStars;
