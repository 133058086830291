import { apiSlice } from './apiSlice';

export interface GenerateContentResponse {
  success?: boolean;
}

export interface ScratchDataProps {
  productId: number | string;
  projectName: string;
  emailType: string;
  chosenSeason?: string;
  events?: string;
  promoOfferDiscount?: string;
  promotionDuration?: string;
  language?: string;
}

const emailFromScratchSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEmailType: build.query<any, void>({
      query: () => ({
        url: `scratch/email/type`,
        method: 'GET',
      }),
    }),
    postEmailSubjectGenerate: build.mutation<any, ScratchDataProps>({
      query: (scratchData) => ({
        url: 'scratch/email/generate-subject',
        method: 'POST',
        body: scratchData,
      }),
    }),
    postGenerateEmailFromScratch: build.mutation<GenerateContentResponse, any>({
      query: (postData) => ({
        url: 'scratch/email/generate',
        method: 'POST',
        body: postData,
      }),
    }),
    getCTA: build.query<any, void>({
      query: () => ({
        url: `scratch/email/cta`,
        method: 'GET',
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetEmailTypeQuery,
  useGetCTAQuery,
  usePostGenerateEmailFromScratchMutation,
  usePostEmailSubjectGenerateMutation,
} = emailFromScratchSlice;
