import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormFieldsSettingProps } from '.';

import RatingStars from '@/components/common/RatingStars';
import EditableText from '@/components/EditableText';
import { RootState } from '@/shared/reduxStore';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';

interface ImportProductLongBenefitsProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportProductReviews({
  fields,
  onInputChange,
}: ImportProductLongBenefitsProps) {
  const { t } = useTranslation();
  const { currentProductId } = useSelector(
    (state: RootState) => state?.products
  );

  const { data: shopifyTemplateData } = useGetShopifyTemplateDataQuery(
    {
      productId: currentProductId,
    },
    {
      skip: !currentProductId,
    }
  );

  return (
    <>
      <p className="text-center text-[35px] font-bold leading-[56px] tracking-tight">
        {t('shopify.whatPeopleSaying')}
      </p>
      <div className="grid md:grid-rows-2 xs:grid-rows-4 grid-flow-col gap-2">
        {shopifyTemplateData.reviews.map(
          ({ title, author }: Record<string, any>, index: number) => {
            const reviewContentField = find(fields, {
              name: `review${index}`,
            }) as FormFieldsSettingProps;
            return (
              <div
                className="md:w-[260px] lg:w-[360px] xs:w-full rounded-[20px] p-[30px] mr-2 text-center box-border shadow-xl shadow-black/5"
                key={title}
              >
                <div className="w-full mt-2">
                  <EditableText
                    {...reviewContentField}
                    onInputChange={onInputChange}
                  />
                </div>
                <div className="mt-2">
                  <RatingStars />
                </div>
                <div className="mt-4 flex items-center justify-center sm:mt-2">
                  <p className="text-center text-sm font-bold leading-[32px] tracking-tight">
                    {author}
                  </p>
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
}

export default ImportProductReviews;
