import * as React from 'react';

import { cn } from '@/utils/cn';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, placeholder, ...props }, ref) => (
    <input
      type={type}
      className={cn(
        'flex h-9 w-full text-white rounded-md border border-solid indent-6 border-purple-700 bg-transparent py-2 px-0 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-white disabled:cursor-not-allowed disabled:opacity-50 outline-none',
        className
      )}
      ref={ref}
      placeholder={placeholder ?? 'Enter your text'}
      {...props}
    />
  )
);
Input.displayName = 'Input';

export { Input };
