import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { BiSolidInfoCircle } from 'react-icons/bi';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
  'inline-flex items-center rounded-sm border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-white hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  tooltip?: string;
}

function Badge({ className, variant, tooltip, ...props }: BadgeProps) {
  return (
    <div className="relative">
      {tooltip && (
        <>
          <span
            data-tooltip-id="checkbox-group-tooltip"
            className="absolute -right-2 -top-2"
          >
            <BiSolidInfoCircle className="text-sm text-white bg-[#292729] rounded-full p-[2px]" />
          </span>
          <ReactTooltip
            id="checkbox-group-tooltip"
            place="bottom"
            content={tooltip}
            className="!text-xs !font-normal !rounded-md !p-1 !px-2 !bg-white !text-black"
          />
        </>
      )}
      <div className={cn(badgeVariants({ variant }), className)} {...props} />
    </div>
  );
}

export { Badge, badgeVariants };
