import React from 'react';

interface ProductAppLayoutProps {
  children: React.JSX.Element;
}
function ProductAppLayout({ children }: ProductAppLayoutProps) {
  return (
    <div className="flex flex-col items-center w-[90%] px-[30px] py-[30px] text-white">
      {children}
    </div>
  );
}

export default ProductAppLayout;
