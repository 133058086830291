import React from 'react';

import Button from '../Button';

import { cn } from '@/lib/utils';

export default function MenuItem({
  icon,
  title,
  action,
  isActive = null,
}: any) {
  return (
    <Button
      className={cn(
        'editor-menu-item bg-transparent border-none outline-none p-2 text-lg',
        isActive && isActive() && 'text-primary'
      )}
      icon={icon}
      iconPosition="before"
      text=""
      title={title}
      onClick={action}
    />
  );
}
