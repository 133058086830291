import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type PlanState = {
  subscriptionCurrencyNoti?: boolean;
  currency?: string;
};

const initialState: PlanState = {
  subscriptionCurrencyNoti: false,
  currency: 'EUR',
};

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPlanState: (state, { payload }: PayloadAction<PlanState>) => ({
      ...state,
      ...payload,
    }),
    resetPlanState: () => initialState,
  },
});

export const { setPlanState, resetPlanState } = planSlice.actions;

export default planSlice.reducer;
