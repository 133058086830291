import { apiSlice } from './apiSlice';

export interface ScratchDataProps {
  productId: number | string;
  emailType: string;
  chosenSeason?: string;
  language?: string;
}

const scratchEventsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.mutation<any, ScratchDataProps>({
      query: (scratchData) => ({
        url: 'scratch/events',
        method: 'POST',
        body: scratchData,
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetEventsMutation } = scratchEventsSlice;
