/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';

import { cn } from '@/lib/utils';

interface InputSentenceProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  initialValue: string;
  className?: string;
}

export function InputSentence({
  className,
  initialValue,
  ...props
}: InputSentenceProps) {
  const [value, setValue] = useState(initialValue);
  return (
    <div className="inline-block relative mx-2 min-w-2">
      <span className="whitespace-pre invisible px-2">{value}</span>
      <input
        className={cn(
          'p-0 m-0 border-0 border-b border-dotted border-zinc-700 absolute align-top top-0 left-0 w-full text-center bg-white bg-opacity-10 text-white outline-none text-sm font-bold',
          className
        )}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        {...props}
      />
    </div>
  );
}
