import { apiSlice } from './apiSlice';

export interface ProductExistProps {
  status?: number;
  notFound?: boolean;
  success?: boolean;
  id?: number;
}

const addProductSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addProduct: builder.mutation<ProductExistProps, any>({
      query: (productData) => ({
        url: 'products/add',
        method: 'POST',
        body: productData,
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useAddProductMutation } = addProductSlice;
