/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Checkbox from '@/components/common/CheckBox';

interface CheckBoxGroupProps {
  text: string;
  icon?: React.JSX.Element;
  tooltipText?: string;
  className?: string;
  checked?: boolean;
  onCheckedChange: (checked: boolean) => void;
}

function CheckBoxGroup({
  text,
  tooltipText,
  icon,
  className,
  checked = false,
  onCheckedChange,
}: CheckBoxGroupProps) {
  const [checkedState, setCheckedState] = useState<boolean>(checked);

  useEffect(() => {
    onCheckedChange(checkedState);
  }, [checkedState]);

  return (
    <div
      className={`text-white flex justify-between border-solid rounded-xl border-2 border-neutral-800 ${className}`}
    >
      <div className="flex items-center">
        <div className="flex me-2 ms-5">
          <a data-tooltip-id="checkbox-group-tooltip" className="flex ms-1">
            {icon}
          </a>
          <ReactTooltip
            id="checkbox-group-tooltip"
            place="bottom"
            content={tooltipText}
            className="!text-xs !font-normal !rounded-md !p-1 !px-2 !bg-white !text-black"
          />
        </div>
        <p className="font-semibold text-[#a238d5]">{text}</p>
      </div>
      <div className="flex items-center me-5">
        <Checkbox
          id="test1"
          className="text-white bg-transparent border-solid p-3"
          checked={checkedState}
          onCheckedChange={(checkedValue) =>
            setCheckedState(checkedValue as boolean)
          }
        />
      </div>
    </div>
  );
}
export default CheckBoxGroup;
