/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import './components/css/ppc.css';

function LegalMentions() {
  const [legals, setLegals] = useState([]);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'common' });
  const { language } = i18n;

  const fetchLegals = async () => {
    try {
      const response = await fetch(`${process.env.VITE_APP_API_URL}/legals`);

      if (!response.ok) return;
      const data = await response.json();
      setLegals(data);
    } catch (error) {
      console.error('Error making request: ', error);
    }
  };

  useEffect(() => {
    fetchLegals();
  }, []);

  return (
    <div>
      <Header />
      <div className="privacy-policy-content">
        <div className="privacy-policy-sub-content">
          <h1>{t('legalNoticeGZ')}</h1>
          {legals.map((legal) => (
            <>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    language === 'fr'
                      ? legal.legal_title_fr
                      : legal.legal_title_en,
                }}
              />
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    language === 'fr'
                      ? legal.legal_description_fr
                      : legal.legal_description_en,
                }}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LegalMentions;
