import React from 'react';

import Skeleton from '../common/Skeleton';

function ItemLoading({ name, image }: Record<string, any>) {
  return (
    <div className="w-full max-w-sm mx-auto">
      <div className="flex space-x-4">
        {image ? (
          <img className="product-lil-img" src={image} alt={name} />
        ) : (
          <Skeleton className="rounded-lg h-[60px] w-[60px]" />
        )}
        <div className="flex-1 py-1 space-y-6">
          {name ? (
            <h5 className="m-0 italic">{name}</h5>
          ) : (
            <Skeleton className="h-2 rounded" />
          )}
          <div className="!mt-2 space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <Skeleton className="h-2 col-span-2 rounded" />
              <Skeleton className="h-2 col-span-1 rounded" />
            </div>
            <Skeleton className="h-2 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemLoading;
