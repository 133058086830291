import axios from 'axios'; // Make sure to install axios
import React, { useState } from 'react';

function MaintenanceToggle({ onToggle }) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace 'your-maintenance-password' with the password you expect
    if (password === 'your-maintenance-password') {
      try {
        // If you're using a backend to control the maintenance mode
        await axios.post(`${process.env.VITE_APP_API_URL}/toggle-maintenance`);
        onToggle(); // Toggle maintenance mode in the parent component
      } catch (error) {
        console.error('Error toggling maintenance mode:', error);
        setError('Could not toggle maintenance mode.');
      }
    } else {
      setError('Incorrect password.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter password"
        />
        <button type="submit">Toggle Maintenance</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
}

export default MaintenanceToggle;
