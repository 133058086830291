import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../reduxStore';

export interface UserResponse {
  error?: string;
  token?: string;
  code?: string;
}

export interface LoginRequest {
  email: string;
  password: string;
  recaptchaToken: string;
}

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.VITE_APP_API_URL,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = localStorage.getItem('productly');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
