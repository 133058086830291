/* eslint-disable react/forbid-component-props */
import {
  FileCopyOutlined,
  ThumbUpAltOutlined,
  ThumbDownAltOutlined,
} from '@material-ui/icons';
import {
  FileTextIcon,
  EnvelopeClosedIcon,
  MagicWandIcon,
  InfoCircledIcon,
  Pencil2Icon,
} from '@radix-ui/react-icons';
import { Label } from '@radix-ui/react-label';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Typewriter from 'typewriter-effect';

import { Card, CardContent, CardTitle } from '../common/Card';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '../common/NavigationMenu';

import ProductlyIcon from '@/components/assets/productly.png';

interface ProductFeatureProps {
  product: Record<string, any>;
}

// eslint-disable-next-line react/function-component-definition
const FeatureDemo: React.FC<ProductFeatureProps> = ({ product }) => {
  const [contentToShow, setContentToShow] = useState('');
  const contentRef = useRef<HTMLDivElement>(null);
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'home' });
  const [selectedMenuItem, setSelectedMenuItem] = useState<number>(1);

  const [demoData, setDemoData] = useState<any>({
    description:
      language === 'fr' ? product?.description_fr : product?.description_en,
    anglesmarketing:
      language === 'fr'
        ? product?.anglesmarketing_fr
        : product?.anglesmarketing_en,
    objections:
      language === 'fr' ? product?.objections_fr : product?.objections_en,
    email1: language === 'fr' ? product?.email1_fr : product?.email1_en,
    email2: language === 'fr' ? product?.email2_fr : product?.email2_en,
    email3: language === 'fr' ? product?.email3_fr : product?.email3_en,
    faq: language === 'fr' ? product?.faq_fr : product?.faq_en,
    briefinflu:
      language === 'fr' ? product?.briefinflu_fr : product?.briefinflu_en,
  });

  const menuItems = [
    {
      id: 1,
      label: t('featuredemoProduct1Label'),
      title: t('featuredemoProduct1Title'),
      icon: <FileTextIcon className="w-[16px] h-[18px] pr-2 ml-1 text-white" />,
    },
    {
      id: 2,
      label: t('featuredemoProduct2Label'),
      title: t('featuredemoProduct2Label'),
      icon: (
        <MagicWandIcon className="w-[16px] h-[18px] pr-2 ml-1 text-white" />
      ),
    },
    {
      id: 3,
      label: t('featuredemoProduct3Label'),
      title: t('featuredemoProduct3Title'),
      icon: (
        <EnvelopeClosedIcon className="w-[16px] h-[18px] pr-2 ml-1 text-white" />
      ),
    },
    {
      id: 4,
      label: t('featuredemoProduct4Label'),
      title: t('featuredemoProduct4Title'),
      icon: (
        <InfoCircledIcon className="w-[16px] h-[18px] pr-2 ml-1 text-white" />
      ),
    },
    {
      id: 5,
      label: t('featuredemoProduct5Label'),
      title: t('featuredemoProduct5Title'),
      icon: <Pencil2Icon className="w-[16px] h-[18px] pr-2 ml-1 text-white" />,
    },
  ];

  useEffect(() => {
    setDemoData(() => ({
      description:
        language === 'fr' ? product?.description_fr : product?.description_en,
      anglesmarketing:
        language === 'fr'
          ? product?.anglesmarketing_fr
          : product?.anglesmarketing_en,
      objections:
        language === 'fr' ? product?.objections_fr : product?.objections_en,
      email1: language === 'fr' ? product?.email1_fr : product?.email1_en,
      email2: language === 'fr' ? product?.email2_fr : product?.email2_en,
      email3: language === 'fr' ? product?.email3_fr : product?.email3_en,
      faq: language === 'fr' ? product?.faq_fr : product?.faq_en,
      briefinflu:
        language === 'fr' ? product?.briefinflu_fr : product?.briefinflu_en,
    }));
  }, [product, language, selectedMenuItem]);

  useEffect(() => {
    const contentMap = {
      1: {
        key: product.id,
        content: demoData.description,
      },
      2: {
        key: demoData.anglesmarketing,
        content: `${demoData.anglesmarketing} ${demoData.objections}`,
      },
      3: {
        key: demoData.email1,
        content: `${demoData.email1} ${demoData.email2} ${demoData.email3}`,
      },
      4: {
        content: demoData.faq,
        key: demoData.faq,
      },
      5: {
        content: demoData.briefinflu,
        key: demoData.briefinflu,
      },
    };

    const menuKey = selectedMenuItem as keyof typeof contentMap;
    const contentData = contentMap?.[menuKey] ?? {};
    setContentToShow(contentData?.content ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItem, language, demoData]);

  const handleMenuItemClick = (menuItem: number) => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
    setSelectedMenuItem(menuItem);
  };

  const copyContentToClipboard = (): string => {
    let text = '';

    switch (selectedMenuItem) {
      case 1:
        text += demoData.description;
        break;
      case 2:
        text += `${demoData.anglesmarketing} ${demoData.objections}`;
        break;
      case 3:
        text += `${demoData.email1} ${demoData.email2} ${demoData.email3}`;
        break;
      case 4:
        text += demoData.faq;
        break;
      case 5:
        text += demoData.briefinflu;
        break;
      default:
        text += demoData.description;
    }

    return text;
  };

  return (
    <Card className="mt-[60px] xs:w-[290px] 2xs:w-[320px] 3xs:w-[360px] 4xs:w-[450px] sm:w-[550px] md:w-[650px] lg:w-[850px] rounded-[20px]  flex flex-row items-start justify-center h-[400px] bg-[#121212] text-white border-[0.6px] border-solid border-primary/50 font-['Poppins'] shadow-lg shadow-[#A037D3]/10">
      <div className="h-full text-center border-solid border-[0.6px] border-l-0 border-y-0 border-primary/50 xs:w-[124px] sm:w-[193px] lg:w-fit">
        <img
          className="py-[25px]"
          src={ProductlyIcon}
          width={80}
          alt="Productly icon"
        />
        <NavigationMenu className="xs:text-[10px] sm:text-xs text-white max-w-full">
          <NavigationMenuList className="flex flex-col px-3 m-0 list-none">
            {menuItems.map((item) => (
              <NavigationMenuItem
                key={item.label}
                className={clsx(
                  'w-full mx-3 py-[6px] my-2 rounded-sm',
                  item.id === selectedMenuItem && 'bg-primary'
                )}
                onClick={() => handleMenuItemClick(item.id)}
              >
                <NavigationMenuLink className="flex flex-row items-center pl-[2px] cursor-pointer text-white">
                  {item.icon}
                  <Label className="text-left text-white">{item.label}</Label>
                </NavigationMenuLink>
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      <div className="flex-1">
        <div className="border-solid border-x-0 border-t-0 border-[0.6px] border-primary/50">
          <CardTitle className="text-center xs:px-2 xs:text-[13px] sm:text-[17px] font-semibold leading-[31.5px]">
            {language === 'fr' ? product.name_fr : product.name_en}
          </CardTitle>
        </div>
        <CardContent
          id="cardContent"
          key={product.id}
          className="min-h-[240px] xs:px-5 sm:px-10 xs:py-2 sm:py-5 text-xs max-h-[240px] overflow-y-auto overflow-x-hidden xs:leading-[20px] sm:leading-[30px]"
          ref={contentRef}
        >
          <Typewriter
            key={nanoid()}
            onInit={(typewriter) =>
              typewriter.typeString(contentToShow).start()
            }
            options={{
              delay: 1,
              cursor: '',
            }}
          />
        </CardContent>
        <div className="flex gap-4 xs:pl-5 sm:pl-10 xs:pt-5 sm:pt-0 md:pt-5">
          <CopyToClipboard
            text={copyContentToClipboard()}
            onCopy={() => toast.success('Copy to clipboard')}
          >
            <FileCopyOutlined className="cursor-pointer opacity-65 !text-base" />
          </CopyToClipboard>
          <ThumbUpAltOutlined className="cursor-pointer opacity-65 !text-base" />
          <ThumbDownAltOutlined className="cursor-pointer opacity-65 !text-base" />
        </div>
      </div>
    </Card>
  );
};

export default FeatureDemo;
