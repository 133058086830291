/* eslint-disable react/no-danger */
import { size, map } from 'lodash';
import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaXmark } from 'react-icons/fa6';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { PiCopyLight } from 'react-icons/pi';
import { TbAlignBoxCenterMiddle } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import GenerateForm from './GenerateForm';

import Beauboug from '@/components/assets/Beaubourg avocats.png';
import Productly from '@/components/assets/productly.png';
import Presentation from '@/components/assets/term-and-legal.mp4';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/common/Accordion';
import Button from '@/components/common/Button';
import { ScrollArea } from '@/components/common/ScrollArea';
import TextLoadingState from '@/components/common/TextLoadingState';
import ProductAppLayout from '@/components/products/Layout';
import { setApplicationState } from '@/shared/reducers/applicationReducer';
import { RootState } from '@/shared/reduxStore';
import { useLazyGetApplicationQuery } from '@/shared/slices/applicationSlice';
import { PlansLevel } from '@/shared/types/user';

function EcomApplication() {
  const { t } = useTranslation('translation');
  const { t: applicationT } = useTranslation('translation', {
    keyPrefix: 'application',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { generateFormActive } = useSelector(
    (state: RootState) => state?.application
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const userPlanAllow =
    (PlansLevel[user.plan] as unknown as number) >= PlansLevel.Pro;

  const [getApplicationData, { currentData: applicationData, isFetching }] =
    useLazyGetApplicationQuery();

  useLayoutEffect(() => {
    getApplicationData(null, false);
  }, [generateFormActive, getApplicationData]);

  useEffect(() => {
    if (!applicationData?.data) return;
    const dataSize = size(applicationData?.data);
    if (!dataSize && userPlanAllow) {
      dispatch(setApplicationState({ generateFormActive: true }));
    }
  }, [applicationData, dispatch, userPlanAllow]);

  const copyToClipBoard = (value: string) => {
    if (!value || value?.length <= 0) return;
    navigator.clipboard.writeText(value);
    toast.success(t('common.copiedSuccess'));
  };

  const noGenerateData =
    !generateFormActive && !isFetching && size(applicationData?.data) <= 0;
  const allAccordionExpand = map(
    applicationData?.data,
    (_, shopName) => shopName
  );

  return (
    <ProductAppLayout>
      <div className="flex flex-col items-center w-full max-w-[800px]">
        <div className="w-full flex text-center flex-col justify-center items-center">
          <div className="text-xl md:text-4xl font-semibold">
            {t('memberArea.partnership')}
          </div>
          <div className="flex items-center space-x-5">
            <img
              className="h-[14px] p-[5px_0px] cursor-pointer md:h-[28px] lg:h-[30px]"
              alt="Productly"
              src={Productly}
            />
            <FaXmark className="font-bold text-4xl" />
            <img
              className="h-[40px] p-[5px_0px] bg cursor-pointer md:h-[68px] lg:h-[90px]"
              alt="Productly"
              src={Beauboug}
            />
          </div>
          <h3>{t('memberArea.safelyGenerateLegal')}</h3>
          <p className="opacity-80">{t('memberArea.ensureProtectedLegal')}</p>
          <div className="flex items-center mb-5">
            <div className="opacity-80">
              {t('memberArea.bookACall')}{' '}
              <a
                target="_blank"
                href="https://beaubourg-avocats.fr/"
                rel="noreferrer"
              >
                <i>{t('memberArea.here')}</i>
              </a>
            </div>
          </div>
          {userPlanAllow &&
            (generateFormActive ? (
              <Button
                className="bg-defaultBlackLight border-0 outline-none text-white rounded-lg py-4 px-4"
                text={applicationT('button.backToApplicationListButton')}
                iconPosition="before"
                icon={<MdKeyboardArrowLeft className="text-xl mr-2" />}
                onClick={() =>
                  dispatch(setApplicationState({ generateFormActive: false }))
                }
              />
            ) : (
              <Button
                className="bg-primary border-0 outline-none text-white rounded-lg py-4 px-4"
                text={applicationT('button.toGenerateApplicationButton')}
                iconPosition="before"
                icon={<TbAlignBoxCenterMiddle className="text-xl mr-2" />}
                onClick={() =>
                  dispatch(setApplicationState({ generateFormActive: true }))
                }
              />
            ))}
          <video className="xs:max-w-[300px] md:max-w-[450px] mt-5" controls>
            <source src={Presentation} type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>
        <div className="product-container" />
        <div className="text-left max-w-[800px] w-full">
          {generateFormActive && <GenerateForm />}
          {!generateFormActive && isFetching && <TextLoadingState />}
          {noGenerateData && (
            <div className="text-center">
              {!userPlanAllow ? (
                <>
                  <h3 className="font-normal text-white/50">
                    {applicationT('needUpgradePlan')}
                  </h3>
                  <Button
                    className="cursor-pointer border border-solid border-[#ffffff4d] rounded-sm uppercase outline-none py-3 px-4 bg-white text-white bg-opacity-5"
                    text={t('common.upgradePlan')}
                    onClick={() => navigate('/upgrade')}
                  />
                </>
              ) : (
                <h3 className="font-normal text-white/50">
                  {applicationT('emptyApplicationList')}
                </h3>
              )}
            </div>
          )}
          {!generateFormActive && !isFetching && (
            <Accordion
              type="multiple"
              value={allAccordionExpand}
              className="'w-full"
            >
              {map(applicationData?.data, (itemArray, shopName) => (
                <AccordionItem value={shopName}>
                  <div className="text-white uppercase border border-solid rounded-md outline-none flex flex-1 items-center justify-between py-3 px-5 text-sm font-bold transition-all hover:underline bg-black border-primary">
                    {shopName}
                  </div>
                  {itemArray.map((object: Record<string, any>) => (
                    <div className="pl-5">
                      <Accordion type="single" collapsible className="w-full">
                        <AccordionItem value={object.type}>
                          <AccordionTrigger className="border-0 [&[data-state=open]]:bg-transparent px-0 capitalize">
                            {object.type === 'legal' && 'Mentions légales'}
                            {object.type === 'term' &&
                              'conditions générales de vente'}
                            {object.type === 'influ' &&
                              'Contrat de partenariat'}
                          </AccordionTrigger>
                          <AccordionContent className="mt-3">
                            <ScrollArea className="h-[500px] bg-white text-defaultBlack rounded-lg px-4">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: object?.content_fr,
                                }}
                              />
                            </ScrollArea>
                            <div className="mt-2">
                              <Button
                                className="text-2xl font-bold text-gray-300 hover:text-primary bg-transparent outline-none border-0"
                                text=""
                                icon={<PiCopyLight />}
                                iconPosition="before"
                                onClick={() =>
                                  copyToClipBoard(object?.content_fr)
                                }
                              />
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  ))}
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </div>
      </div>
    </ProductAppLayout>
  );
}

export default EcomApplication;
