import { apiSlice } from './apiSlice';

import { setProductsState } from '../reducers/productsReducer';
import { RootState, store } from '../reduxStore';

export interface ProductListProps {
  page?: number;
  limit?: number;
  reset?: boolean;
  filter?: number;
}

export interface ProductDetailProps {
  productId?: number;
}

export interface ProductFormattedProps {
  productId?: number;
}

export interface ProductReviewsProps {
  productId?: number;
}

export interface ProductListResponseProps {
  generatedProducts?: number;
  totalProducts?: number;
  regeneratedProducts?: number;
  stock?: number;
  products?: Record<string, any>[];
  archiver?: boolean;
}

export interface serviceDetailProps {
  productId?: number;
  service: string | null;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ['PRODUCTS', 'PRODUCT_DETAIL'],
});
const productSlice = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProductDetail: builder.query<any, ProductDetailProps>({
      query: ({ productId }) => ({
        url: `products/detail/${productId}`,
        method: 'GET',
      }),
      onQueryStarted: async ({ productId }, api) => {
        const { dispatch } = api;
        // Load the product reviews right away
        dispatch(
          productSlice.endpoints.getProductReviews.initiate({ productId })
        );
      },
      providesTags: ['PRODUCT_DETAIL'],
    }),
    getProductReviews: builder.query<any, ProductReviewsProps>({
      query: ({ productId }) => ({
        url: `products/${productId}/reviews`,
        method: 'GET',
      }),
      providesTags: ['PRODUCT_DETAIL'],
    }),
    getProductList: builder.query<ProductListResponseProps, ProductListProps>({
      query: ({ page, limit = 6, reset = false, filter = 0 }) => ({
        url: `products?page=${page}&limit=${limit}&filter=${filter}`,
        method: 'GET',
      }),
      onQueryStarted: async ({ page = 1, limit = 6, reset = false }, api) => {
        const { dispatch, queryFulfilled, getState } = api;
        const { data } = await queryFulfilled;
        const { products: productsData } =
          (getState() as RootState)?.products ?? {};
        const fetchProductData = data?.products ?? [];

        let productState: Record<string, any>[] = productsData ?? [];
        if (fetchProductData && fetchProductData?.length > 0) {
          productState = [...(productsData ?? []), ...fetchProductData];
        }

        if (reset) {
          productState = fetchProductData;
        }

        dispatch(
          setProductsState({
            ...data,
            products: productState,
            limit,
            page: reset ? 1 : page,
            hasMore: page * limit < (data?.totalProducts ?? 0),
          })
        );
      },
      providesTags: ['PRODUCTS'],
    }),
    getServiceDetail: builder.query<any, serviceDetailProps>({
      query: ({ productId = 0, service }) => ({
        url: `products/detail/${productId}/${service}`,
        method: 'GET',
      }),
    }),
    getFormattedInfo: builder.query<any, ProductFormattedProps>({
      query: ({ productId }) => ({
        url: `products/format/${productId}`,
        method: 'GET',
      }),
    }),
    archiveProduct: builder.mutation<ProductFormattedProps, any>({
      query: (productData) => ({
        url: 'products/archive',
        method: 'POST',
        body: productData,
      }),
    }),
  }),
});

export const getProductServiceDescription = (productId: number) =>
  productSlice.endpoints.getServiceDetail.select({
    productId,
    service: 'description',
  })(store.getState() as any)?.data;

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetProductListQuery,
  useLazyGetProductListQuery,
  useGetProductDetailQuery,
  useLazyGetProductDetailQuery,
  useGetProductReviewsQuery,
  useLazyGetProductReviewsQuery,
  useGetServiceDetailQuery,
  useLazyGetServiceDetailQuery,
  useLazyGetFormattedInfoQuery,
  useArchiveProductMutation,
} = productSlice;
