import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Productly from './assets/productly.png';
import LanguageButton from './Buttons/LanguageButton';

import { displayFeature, FeatureFlags } from '@/shared/configs/featureFlags';

function Footer() {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const displayLangugageChangeButton = displayFeature({
    featureFlag: FeatureFlags.PRODLY_355_STAGING_LANGUGAGE_CHANGE_20240612,
  });
  return (
    <footer className="text-white border-0 border-t border-solid border-white/10 pt-10">
      <div className="flex justify-center">
        <div className="grid grid-cols-3 lg:space-x-36">
          <div>
            <NavLink className="flex" to="/">
              <img
                className="h-[16px] p-[20px_0px] cursor-pointer md:h-[18px] lg:h-[30px]"
                alt="Productly"
                src={Productly}
              />
            </NavLink>
          </div>
          <div>
            <h4>{t('link')}</h4>
            <ul className="list-none p-0 [&_a]:text-sm [&_a]:no-underline">
              <li>
                <Link to="/">{t('home')}</Link>
              </li>
              <li>
                <Link to="/app">Services</Link>
              </li>
              <li>
                <Link to="/upgrade">{t('price')}</Link>
              </li>
              <li>
                <HashLink to="/#faq">FAQ</HashLink>
              </li>
            </ul>
          </div>
          <div>
            <h4>Legal</h4>
            <ul className="list-none p-0 [&_a]:text-sm [&_a]:no-underline">
              <li>
                <Link to="/conditions-generales-de-ventes">
                  {t('termAndCondition')}
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">{t('privacyPolicy')}</Link>
              </li>
              <li>
                <Link to="/legal-mentions">{t('legalNotice')}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container max-w-[800px] py-6">
        <div className="flex justify-center items-center xs:flex-col md:flex-row">
          {displayLangugageChangeButton && <LanguageButton />}
          <p>
            &copy; {new Date().getFullYear()} Productly. {t('allrights')}
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
