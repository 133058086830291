import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type ApplicationState = {
  active?: boolean;
  generateFormActive?: boolean;
};

const initialState: ApplicationState = {
  active: false,
  generateFormActive: false,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationState: (
      state,
      { payload }: PayloadAction<ApplicationState>
    ) => ({
      ...state,
      ...payload,
    }),
    resetApplicationState: () => initialState,
  },
});

export const { setApplicationState, resetApplicationState } =
  applicationSlice.actions;

export default applicationSlice.reducer;
