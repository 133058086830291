import React from 'react';

function ChevronIcon({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.0519 9.96387L7.39486 4.32583L1.73779 9.96387L0 8.22814L7.39486 0.842059L14.7897 8.22814L13.0519 9.96387Z"
        fill="white"
      />
    </svg>
  );
}

export default ChevronIcon;
