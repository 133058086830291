/* eslint-disable import/prefer-default-export */
export interface MainMenuProps {
  text: string;
  hasLink?: string;
  navLink?: string;
}

export const mainMenu: MainMenuProps[] = [
  { text: 'headerMenu1', hasLink: '/#contact' },
  { text: 'headerMenu2', hasLink: '/#faq' },
  { text: 'headerMenu3', navLink: '/upgrade' },
];
