import React from 'react';

import Skeleton from './Skeleton';

function TextLoadingState() {
  return (
    <div className="space-y-4 opacity-20 w-250 md:w-350 xl:w-800">
      <Skeleton className="w-8/12 h-4" />
      <Skeleton className="w-full h-4" />
      <Skeleton className="w-full h-4" />
      <Skeleton className="w-10/12 h-4" />
      <Skeleton className="w-10/12 h-4" />
      <Skeleton className="w-8/12 h-4" />
      <Skeleton className="w-full h-4" />
    </div>
  );
}

export default TextLoadingState;
