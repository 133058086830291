import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { GenerateServices, GenerateType } from '../configs/generateServices';

export interface OverviewTextData {
  title: string;
  subTitle: string;
}

type FromScratchState = {
  showScratchButton?: boolean;
  active?: boolean;
  projectName?: string;
  productId?: number;
  generateService?: string;
  generateType?: string;
  mainStepperBtn?: boolean;
  stepperNextBtnDisable?: boolean;
  seasonsData?: any;
  overViewData?: OverviewTextData[];
  language?: string;
};

const initialState: FromScratchState = {
  showScratchButton: false,
  active: false,
  productId: 0,
  projectName: '',
  generateService: GenerateServices.EMAIL_MARKETING,
  generateType: GenerateType.ONE_CLICK,
  mainStepperBtn: true,
  stepperNextBtnDisable: false,
  seasonsData: [
    {
      text_en: 'Winter',
      text_fr: 'Hiver',
    },
    {
      text_en: 'Spring',
      text_fr: 'Printemps',
    },
    {
      text_en: 'Summer',
      text_fr: 'Eté',
    },
    {
      text_en: 'Autumn',
      text_fr: 'Automne',
    },
  ],
  overViewData: [],
};

const fromScratchSlice = createSlice({
  name: 'fromScratch',
  initialState,
  reducers: {
    setFromScratch: (state, { payload }: PayloadAction<FromScratchState>) => ({
      ...state,
      ...payload,
    }),
    resetFromScratch: () => initialState,
  },
});

export const { setFromScratch, resetFromScratch } = fromScratchSlice.actions;

export default fromScratchSlice.reducer;
