import React from 'react';

interface IconProps {
  className?: string;
}

function PlusThickIcon({ className }: IconProps) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M26.52 16.432H16.692V26.52H9.828V16.432H0V10.088H9.828V0H16.692V10.088H26.52V16.432Z"
        fill="white"
      />
    </svg>
  );
}

export default PlusThickIcon;
