/* eslint-disable no-case-declarations */
import jwtDecode from 'jwt-decode';
import React from 'react';
import { toast } from 'react-toastify';

export const accessToken = () => {
  try {
    return localStorage.getItem('productly');
  } catch (err) {
    return null;
  }
};

export const amazonAsinExtract = (urlString) => {
  const validDomains = ['fr', 'com', 'com.be'];
  const [__, urlDomain] = urlString.match(/.*on\.(.*?)\//);
  const isValidDomain = validDomains.some((domain) => domain === urlDomain);

  if (!isValidDomain) return false;

  try {
    const [_, asinCode] = urlString.match(/dp\/([A-Z0-9]{10})/);
    if (!asinCode) return false;
    const asinFormat = asinCode.replace('/dp', '').trim();
    if (asinFormat.slice(0, 2) !== 'B0') {
      return {
        asin_code: asinFormat,
      };
    }
    return asinFormat;
  } catch {
    return false;
  }
};

export const apiErrorHandler = (error) => {
  toast.error(error);
};

export const productionLog = (message) => {
  if (localStorage.getItem('production-debug')) {
    console.log(message);
  }
};

export const stringToParagraphs = (text) => {
  let textReturn = '';
  try {
    textReturn = text
      .split('\n')
      .filter((line) => line.length > 0)
      .map((line, index) => (
        <React.Fragment key={Math.random(index)}>
          {line}
          {index < text.length - 1 && <br />}
        </React.Fragment>
      ));
  } catch (error) {
    console.error('There was an error ', error);
  }

  return textReturn;
};

export const resendVerifyEmail = async (email) => {
  try {
    const response = await fetch(
      `${process.env.VITE_APP_API_URL}/auth/verify/resend`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
        }),
      }
    );

    if (response.ok) {
      return response.ok;
    }
    const errorResponse = await response.text();
    productionLog(errorResponse);
    return errorResponse;
  } catch (error) {
    productionLog(error);
    return error;
  }
};
export const loginSessionCheck = (token) => {
  if (!token) return false;
  try {
    const decodedLoginToken = jwtDecode(token);
    if (decodedLoginToken) {
      return true;
    }
  } catch (error) {
    localStorage.removeItem('productly');
  }
  return false;
};

export default apiErrorHandler;

export const shuffle = (array) => array.sort(() => Math.random() - 0.5);

export const checkGenerateContent = ({
  baseServices,
  servicesMap,
  productDetail,
}) =>
  baseServices.filter(({ api_key: apiKey, key }) => {
    const { keyV3 } =
      servicesMap.find(({ key: serviceMapKey }) => key === serviceMapKey) ?? {};
    const basicServiceFilled =
      !['reviews', 'ecomApplication'].includes(key) && !productDetail[apiKey];
    const v3ServiceFilled = keyV3 ? productDetail[keyV3]?.length <= 0 : true;
    return basicServiceFilled && v3ServiceFilled;
  })?.length <= 0;

export const URLSearchParamsToObject = (queryParams) =>
  Object.fromEntries(new URLSearchParams(queryParams));

export const stringToHtml = (html) =>
  new DOMParser().parseFromString(html, 'text/html');

export const stripTags = (html) => stringToHtml(html).body.textContent || '';
