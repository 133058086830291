import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInfluencerFieldsConfigs from './hook/useInfluencerFieldsConfig';
import ApplicationTextField from './TextField';

import CheckBoxGroupLTR from '@/components/common/CheckBoxGroupLTR';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/common/shadcn-ui/Form';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/components/common/shadcn-ui/RadioGroup';
import { EditedDateField } from '@/components/EditedRadioGroup/EditedLabelItem';

interface TermAndLegalProps {
  form: any;
}

function Influencer({ form }: TermAndLegalProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'application' });
  const [influencerLegalEntity, setInfluencerLegalEntity] = useState('');
  const [influencerIsSoleTrader, setInfluencerIsSoleTrader] = useState(false);

  const dateFormat = 'dd/MM/yyyy';
  const today = format(new Date(), dateFormat);
  const [currentDate, setCurrentDate] = useState(`${today}`);

  const {
    influencerFields,
    influencerIndividualEntity,
    influencerCompanyEntity,
    isSoleTraderField,
  } = useInfluencerFieldsConfigs();

  return (
    <>
      <h2 className="font-normal">{t('influencerInfor')}</h2>
      <div className="border-solid rounded-xl border-2 border-neutral-800 p-5 space-y-5">
        <FormField
          control={form.control}
          name="lastUpdate"
          render={() => (
            <FormItem>
              <FormLabel className="text-base">
                {t('influencer.lastUpdate.label')}
              </FormLabel>
              <FormControl className="w-full flex items-center justify-between">
                <div className="flex h-9 w-full text-white rounded-md border border-solid border-neutral-800 indent-3 bg-transparent py-2 px-0 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-white disabled:cursor-not-allowed disabled:opacity-50 outline-none">
                  <EditedDateField
                    className="!bg-transparent !border-0"
                    value={currentDate}
                    index={1}
                    handleDateValueChange={(value) => {
                      const dateString = format(value, 'dd/MM/yyyy');
                      setCurrentDate(dateString);
                      form.setValue('lastUpdate', dateString);
                    }}
                    calendarIcon
                  />
                </div>
              </FormControl>
              <FormMessage className="text-red-400 italic" />
            </FormItem>
          )}
        />
        <FormField
          key="siteUrl"
          control={form.control}
          name="siteUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-base">
                {t('termAndLegal.siteUrl.label')}
              </FormLabel>
              <FormControl className="w-full">
                <Input
                  className="placeholder:opacity-50 h-auto indent-3 py-3 border-zinc-600/60 focus:border-primary"
                  placeholder={t('termAndLegal.siteUrl.placeHolder')}
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-red-400 italic" />
            </FormItem>
          )}
        />
        <ApplicationTextField form={form} fieldsCongfig={influencerFields} />
        <FormField
          control={form.control}
          name="returnCostOn"
          render={() => (
            <FormItem>
              <FormLabel className="text-base">{t('entityType')}</FormLabel>
              <FormControl className="w-full">
                <RadioGroup
                  defaultValue="0"
                  className="flex"
                  onValueChange={(value) => {
                    setInfluencerLegalEntity(value);
                    form.setValue('influLegalEntityType', value);
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="0" id="InfluIndividual" />
                    <Label htmlFor="r1">{t('individual')}</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="1" id="InfluCompany" />
                    <Label htmlFor="r2">{t('company')}</Label>
                  </div>
                </RadioGroup>
              </FormControl>
              <FormMessage className="text-red-400 italic" />
            </FormItem>
          )}
        />
        {influencerLegalEntity === '1' ? (
          <ApplicationTextField
            form={form}
            fieldsCongfig={influencerCompanyEntity}
          />
        ) : (
          <ApplicationTextField
            form={form}
            fieldsCongfig={influencerIndividualEntity}
          />
        )}
        <CheckBoxGroupLTR
          className=" !text-white"
          text="Sole Trader"
          tooltipText="Promo Info"
          checked={influencerIsSoleTrader ?? false}
          onCheckedChange={(checked: boolean) => {
            setInfluencerIsSoleTrader(checked);
            form.setValue('isSoleTrader', checked ? '1' : '0');
          }}
          id="Sole Trader"
        />
        {influencerIsSoleTrader && (
          <ApplicationTextField form={form} fieldsCongfig={isSoleTraderField} />
        )}
      </div>
    </>
  );
}

export default Influencer;
