/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Label } from '@/components/common/Label';
import { SelectCustom } from '@/components/common/Select';
import {
  GenerateLanguages,
  GenerateServices,
} from '@/shared/configs/generateServices';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { RootState } from '@/shared/rootReducers';

function ScratchCategory() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { generateService, language: productLanguage } = useSelector(
    (state: RootState) => state?.fromScratch
  );

  const categories = [
    { value: GenerateServices.EMAIL_MARKETING, text: 'Email Marketing' },
  ];

  const languages = [
    { value: GenerateLanguages.FRENCH, text: 'French' },
    { value: GenerateLanguages.ENGLISH, text: 'English' },
  ];

  useEffect(() => {
    dispatch(setFromScratch({ stepperNextBtnDisable: false }));
  }, []);

  return (
    <>
      <Label htmlFor="category">
        <h2 className="font-normal">{t('scratch.categorySelection')}</h2>
      </Label>
      <SelectCustom
        optionsData={categories}
        placeholder="Select category"
        defaultValue={generateService ?? ''}
        onSelectData={(value) =>
          dispatch(
            setFromScratch({ generateService: value as GenerateServices })
          )
        }
      />
      <Label htmlFor="languages">
        <h2 className="font-normal">Language</h2>
      </Label>
      <SelectCustom
        optionsData={languages}
        placeholder="Please choose the language"
        defaultValue={productLanguage}
        onSelectData={(value) =>
          dispatch(setFromScratch({ language: value as GenerateLanguages }))
        }
      />
    </>
  );
}

export default ScratchCategory;
