/* eslint-disable react-hooks/exhaustive-deps */
import _findIndex from 'lodash/findIndex';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from './Button';
import Progress from './Progress';

import cn from '@/utils/cn';

export interface StepDataProps {
  id: string;
  title?: string;
  name: string;
  component: React.ReactElement;
  percengate: number;
  hideMark?: boolean;
  backButtonText?: string;
  nextButtonText?: string;
  hideNextStepBtn?: boolean;
}

export interface StepperProps {
  stepData: StepDataProps[];
  currentStep?: StepDataProps;
  setCurrentStep?: (stepState: StepDataProps) => void;
  lastStepAction: () => void;
  nextBtnDisabled?: boolean;
  showStepButton?: boolean;
  showStepHeader?: boolean;
  showStepMark?: boolean;
  showProgress?: boolean;
  showProgressTooltip?: boolean;
}

function Stepper({
  stepData,
  currentStep,
  setCurrentStep,
  lastStepAction,
  nextBtnDisabled = false,
  showStepButton = true,
  showStepHeader = true,
  showStepMark = true,
  showProgress = true,
  showProgressTooltip = true,
}: StepperProps) {
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(0);
  const handleStepState = (next = true) => {
    if (!currentStep || !setCurrentStep) return;
    const continuedStep = next ? stepIndex + 1 : stepIndex - 1;
    const stepState = stepData[continuedStep];
    const currentStepIndex = _findIndex(stepData, { id: stepState.id });
    if (currentStepIndex >= stepData.length) {
      lastStepAction();
      return;
    }

    if (!stepState) return;
    setCurrentStep(stepState);
  };

  useEffect(() => {
    if (!currentStep || !setCurrentStep) return;
    const currentStepIndex = _findIndex(stepData, { id: currentStep.id });
    setCurrentStep(currentStep);
    setStepIndex(currentStepIndex);
  }, [currentStep]);

  if (!currentStep) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {showStepHeader && (
        <h2 className="mb-5 font-semibold text-left text-white">
          {currentStep?.title ?? currentStep?.name}
        </h2>
      )}
      {showStepMark && (
        <>
          <div className="md:hidden xs:flex mb-2">
            {stepIndex + 1}/{stepData?.length} {currentStep?.name}
          </div>
          <div className="flex justify-center">
            <ol className="md:flex items-center w-10/12 text-xs text-gray-900 sm:text-base p-0 xs:hidden">
              {stepData?.map(({ id, hideMark, name }, index) => (
                <li
                  key={id}
                  className={cn(
                    'flex relative text-white',
                    stepData.length - 1 !== index &&
                      `w-full after:content-[''] after:w-full after:h-0.5 after:inline-block after:absolute after:top-4 after:left-7 after:border-0 after:border-t-2 after:border-dotted after:border-primary`,
                    hideMark && 'hidden'
                  )}
                >
                  <div className="z-10 block whitespace-nowrap">
                    <span
                      className={cn(
                        'w-6 h-6 border-solid border-4 border-primary bg-black rounded-full flex justify-center items-center mx-auto mb-3 text-sm text-white lg:w-6 lg:h-6',
                        stepIndex >= index && `bg-primary`
                      )}
                    />
                    {name}
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </>
      )}
      {showProgress && (
        <Progress
          value={currentStep?.percengate ?? 0}
          tooltip={showProgressTooltip}
        />
      )}
      <div>{currentStep?.component}</div>
      {showStepButton && (
        <div className="flex justify-end mt-10">
          {stepIndex > 0 && (
            <Button
              className="py-3 mr-3 text-white bg-transparent border-white border-solid px-7 border-1 rounded-xl"
              text={currentStep?.backButtonText ?? t('common.back')}
              onClick={() => handleStepState(false)}
            />
          )}
          {!currentStep?.hideNextStepBtn && (
            <Button
              className={cn(
                'py-3 px-7 text-white border-1 border-solid border-primary bg-primary rounded-xl',
                nextBtnDisabled &&
                  'disabled:bg-opacity-40 disabled:border-opacity-10 disabled:cursor-not-allowed'
              )}
              text={currentStep?.nextButtonText ?? t('common.continue')}
              onClick={handleStepState}
              disabled={nextBtnDisabled}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Stepper;
