/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useRef } from 'react';
import { FaCircle } from 'react-icons/fa6';

import { OptionGroupProps } from './OptionGroup';

import ClassicEventButton from '../Buttons/ClassicEventButton';
import { RadioButtonGroup, RadioGroupItem } from '../Buttons/RadioButtonGroup';
import { InputDiv } from '../common/InputDiv';
import { Label } from '../common/Label';
import Skeleton from '../common/Skeleton';

import cn from '@/utils/cn';

interface ListCheckBoxGroupProps
  extends Omit<
    OptionGroupProps,
    'handleResetAiProposional' | 'onOptionChange'
  > {
  selectedOption: string | undefined;
  setSelectedOption: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAiGeneratedOptions: any;
  disabled?: boolean;
  button?: React.JSX.Element;
  className?: string;
}

function SkeletonLoadingItems() {
  return (
    <div>
      <Skeleton className="h-3 rounded-full mb-2 w-9/12 opacity-10" />
      <Skeleton className="h-3 rounded-full mb-2 w-9/12 opacity-10" />
      <Skeleton className="h-3 rounded-full mb-2 w-9/12 opacity-10" />
      <Skeleton className="h-3 rounded-full mb-2 w-8/12 opacity-10" />
      <Skeleton className="h-3 rounded-full mb-2 w-8/12 opacity-10" />
    </div>
  );
}

function ListCheckBoxGroup({
  aiGeneratedOptions,
  aiGeneratedHeader,
  staticOptionsHeader,
  staticOptions,
  monthList,
  selectedMonth,
  selectedOption,
  handleMonthChange,
  setSelectedOption,
  setAiGeneratedOptions,
  disabled = false,
  button,
  isLoading = false,
  className,
  optionEditable,
}: ListCheckBoxGroupProps) {
  const editableDivRef = useRef<HTMLDivElement[]>([]);
  return (
    <div className={`${className} my-3`}>
      <div className="flex justify-between items-center">
        <h3
          className={disabled || isLoading ? 'text-zinc-600' : 'text-primary'}
        >
          {aiGeneratedHeader}
        </h3>
        {button}
      </div>
      <RadioButtonGroup
        value={selectedOption}
        onValueChange={(value) => setSelectedOption(value)}
        disabled={disabled}
        className={cn(
          'data-[disabled]:text-zinc-600',
          isLoading ? 'animate-pulse text-zinc-600' : ''
        )}
      >
        {isLoading && !aiGeneratedOptions && <SkeletonLoadingItems />}
        {aiGeneratedOptions &&
          aiGeneratedOptions.map((value, index) => (
            <div key={value} className="flex items-center">
              <RadioGroupItem
                className="p-0 h-3.5 w-3.5 bg-transparent rounded-xl border-2 border-solid border-white data-[state=checked]:bg-transparent data-[state=checked]:border-primary"
                value={value}
                id={value}
                onFocus={() => {
                  if (editableDivRef?.current[index]) {
                    editableDivRef?.current[index]?.focus();
                  }
                }}
                icon={
                  <FaCircle className="h-2 w-2 text-primary border-transparent border border-solid rounded-xl" />
                }
              />
              <Label
                className="font-normal text-base ms-1 w-full"
                htmlFor={value}
              >
                {optionEditable && !disabled && !isLoading ? (
                  <InputDiv
                    ref={(element) => {
                      if (!element) return null;
                      editableDivRef.current[index] = element;
                      return element;
                    }}
                    className="p-0 m-0 border-0 text-white outline-none focus:border-b forcus:border-zinc-700 focus:border-dotted focus:bg-white focus:bg-opacity-10"
                    onBlur={(event) => {
                      const { textContent } = event.currentTarget;
                      setAiGeneratedOptions((prev: string[]) =>
                        prev.map((prevValue, prevIndex) =>
                          index === prevIndex ? textContent : prevValue
                        )
                      );
                      setSelectedOption(textContent ?? '');
                    }}
                    value={value}
                  />
                ) : (
                  value
                )}
              </Label>
            </div>
          ))}
        {!isLoading && !aiGeneratedOptions && <div>There is no data</div>}
        {staticOptionsHeader && (
          <>
            <div className="flex justify-between items-center">
              <h3
                className={
                  disabled || isLoading ? 'text-zinc-600' : 'text-primary'
                }
              >
                {staticOptionsHeader}
              </h3>
              {monthList && (
                <ClassicEventButton
                  selectValue={selectedMonth}
                  monthList={monthList}
                  handleMonthChange={handleMonthChange}
                  disabled={disabled}
                />
              )}
            </div>
            {isLoading && !aiGeneratedOptions && <SkeletonLoadingItems />}
            {staticOptions &&
              staticOptions.map(({ id, event }) => (
                <div key={id} className="flex items-center">
                  <RadioGroupItem
                    className="p-0 h-3.5 w-3.5 bg-transparent rounded-xl border-2 border-solid border-white data-[state=checked]:bg-transparent data-[state=checked]:border-primary"
                    value={event}
                    id={id}
                    icon={
                      <FaCircle className="h-2 w-2 text-primary border-transparent border border-solid rounded-xl" />
                    }
                  />
                  <Label className="font-normal text-base ms-1" htmlFor={id}>
                    {event}
                  </Label>
                </div>
              ))}
          </>
        )}
      </RadioButtonGroup>
    </div>
  );
}
export default ListCheckBoxGroup;
