/* eslint-disable import/prefer-default-export */
import { useTranslation } from 'react-i18next';

export const useFeaturesHook = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'upgrade' });
  const features = [
    t('products'),
    t('productDescription'),
    t('productBenefit'),
    t('creatives'),
    t('faqHeadline'),
    t('adCopies'),
    t('customersOpinion'),
    t('ecomApplication'),
    t('shopify'),
    t('emailMarketing'),
    t('smsMarketing'),
    t('upsellsBundles'),
    t('influencerBriefs'),
    t('afterSalesService'),
  ];
  return features;
};
