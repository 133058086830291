/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import './components/css/ppc.css';

function PrivacyPolicy() {
  const [privacies, setPrivacies] = useState([]);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'common' });
  const { language } = i18n;

  const fetchPrivacies = async () => {
    try {
      const response = await fetch(`${process.env.VITE_APP_API_URL}/privacy`);
      if (!response.ok) return;

      const data = await response.json();
      setPrivacies(data);
    } catch (error) {
      console.error('Error making requests: ', error);
    }
  };

  useEffect(() => {
    fetchPrivacies();
  }, []);

  return (
    <div>
      <Header />
      <div className="privacy-policy-content">
        <div className="privacy-policy-sub-content">
          <h1>{t('privacyPolicy')}</h1>
          {privacies.map((privacy) => (
            <>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    language === 'fr'
                      ? privacy.privacy_title_fr
                      : privacy.privacy_title_en,
                }}
              />
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    language === 'fr'
                      ? privacy.privacy_description_fr
                      : privacy.privacy_description_en,
                }}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
