/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Label } from '@/components/common/Label';
import {
  EditedRadioGroup,
  EditedRadioItem,
} from '@/components/EditedRadioGroup';
import { setEmailScratchInfo } from '@/shared/reducers/emailFromScratchReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { RootState } from '@/shared/rootReducers';

function EmailLength() {
  const {
    t,
    i18n: { language: languagei18 },
  } = useTranslation();
  const {
    emailFromScratch: { emailLength },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const [emailLengthOptions, setEmailLengthOptions] = useState<
    Record<string, any>[]
  >([
    { value_en: `50 Wwords`, value_fr: `50 mots`, id: 'r1' },
    {
      value_en: '50-125 words',
      value_fr: `50-125 mots`,
      id: 'r2',
      badge: {
        text_en: 'recommended',
        text_fr: 'recommandée',
        tooltip_en:
          'This is the optimized email length for the best-focused content',
        tooltip_fr: `Voici la longueur d'email optimisée pour un contenu le plus ciblé`,
      },
    },
    {
      value_en: `125-250 words`,
      value_fr: `125-250 mots`,
      id: 'r3',
    },
    {
      value_en: `250-500`,
      value_fr: `250-500`,
      id: 'r4',
    },
    {
      value_en: `500-750`,
      value_fr: `500-750`,
      id: 'r5',
    },
    {
      value_en: 'From ~750 to ~1000',
      id: 'r6',
      value_fr: `De ~750 à ~1000 mots`,
      inputProps: {
        type: 'number',
        max: 1000,
      },
    },
  ]);

  const onEmailLengthChange = (value: string) => {
    dispatch(setEmailScratchInfo({ emailLength: value }));
  };

  useEffect(() => {
    const changeOptionLanguage = (options: Record<string, any>[]) =>
      options.map((option) => {
        const badge = option?.badge;
        return {
          ...option,
          ...(badge && {
            badge: {
              ...badge,
              text: languagei18 === 'fr' ? badge.text_fr : badge.text_en,
              tooltip:
                languagei18 === 'fr' ? badge.tooltip_fr : badge.tooltip_en,
            },
          }),
          value: languagei18 === 'fr' ? option.value_fr : option.value_en,
        };
      });

    setEmailLengthOptions((prevOptions) => changeOptionLanguage(prevOptions));
  }, [languagei18]);

  useEffect(() => {
    let stepperNextBtnDisable = false;
    if (!emailLength || emailLength?.length <= 0) {
      stepperNextBtnDisable = true;
    }
    dispatch(setFromScratch({ stepperNextBtnDisable }));
  }, [emailLength]);

  return (
    <>
      <Label htmlFor="emailType">
        <h2 className="font-normal">{t('scratch.emailLength')}</h2>
      </Label>
      <EditedRadioGroup
        selectValue={emailLength ?? '50 words'}
        onSelectChange={onEmailLengthChange}
        className="mt-5"
      >
        {emailLengthOptions.map((item) => (
          <EditedRadioItem
            value={item.value}
            key={item.value}
            onSelectChange={onEmailLengthChange}
            {...item}
          />
        ))}
      </EditedRadioGroup>
    </>
  );
}

export default EmailLength;
