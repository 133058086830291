import React from 'react';

import { MainNav } from './MainNav';

function Header() {
  return (
    <div>
      <div className="relative flex flex-col items-center justify-center after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[1px] after:bg-gradient-to-r after:from-transparent after:from-[9%] after:via-[rgba(255,255,255,0.2)] after:via-[51%] after:to-transparent after:to-[91%]">
        <div className="p-[20px] min-w-[90%] lg:justify-around lg:min-w-[100%] ">
          <MainNav />
        </div>
      </div>
    </div>
  );
}

export default Header;
