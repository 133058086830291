/* eslint-disable import/prefer-default-export */
export const FeatureFlags = {
  PRODLY_272_GENERATE_FROM_SCRATCH_20240411: { production: false },
  PRODLY_302_UX_ACCOUNT_PAGE_20240515: { production: false },
  PRODLY_336_IMPORT_TO_SHOPIFY_20240612: { production: true },
  PRODLY_355_STAGING_LANGUGAGE_CHANGE_20240612: { production: true },
  PRODLY_368_SHOPIFY_CONNECT_WIZARD: { production: true },
};

export const displayFeature = ({
  featureFlag,
}: {
  featureFlag: Record<string, any>;
}) => {
  const { production } = featureFlag ?? {};
  if (process.env.VITE_ENV === 'development') return true;
  return production;
};
