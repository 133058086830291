import { apiSlice } from './apiSlice';

export interface ProductExistProps {
  productId: number;
  generateContentId: number;
  selectedService: string;
}

const regenerateContent = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    regenerateContent: builder.mutation<ProductExistProps, any>({
      query: (productData) => ({
        url: 'products/regenerate',
        method: 'POST',
        body: productData,
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useRegenerateContentMutation } = regenerateContent;
