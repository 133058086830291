import React from 'react';

interface ArrowToRightIconProps {
  className?: string;
  iconSize: string;
}

function ArrowToRightIcon({ className, iconSize }: ArrowToRightIconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      strokeWidth="10"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  );
}

export default ArrowToRightIcon;
