import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { FormFieldsSettingProps } from '.';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/common/Popover';
import { Button } from '@/components/common/shadcn-ui/Button';
import { cn } from '@/lib/utils';
import { RootState } from '@/shared/reduxStore';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';

interface ImportProductLongBenefitsImageProps extends FormFieldsSettingProps {
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportProductLongBenefitsImage({
  id,
  name,
  text: imageSrc,
  onInputChange,
  editable = true,
}: ImportProductLongBenefitsImageProps) {
  const { currentProductId } = useSelector(
    (state: RootState) => state?.products
  );
  const { data: shopifyTemplateData } = useGetShopifyTemplateDataQuery(
    {
      productId: currentProductId,
    },
    {
      skip: !currentProductId,
    }
  );

  const { productImages } = shopifyTemplateData ?? [];
  return (
    <div className="col-span-12 mx-auto w-10/12 rounded-3xl lg:col-span-7 lg:max-w-md">
      <Popover>
        <PopoverTrigger
          className={cn(
            'relative border border-solid border-white outline-none p-0',
            editable &&
              'cursor-pointer hover:border-dashed hover:border-black/10 bg-transparent [&>_svg]:hover:flex'
          )}
        >
          <MdModeEdit className="absolute top-0 right-0 text-lg hidden text-primary ml-2 w-[24px]" />
          <img
            className="w-full hover:opacity-40"
            src={imageSrc}
            alt="product feature"
          />
        </PopoverTrigger>
        <PopoverContent className="w-fit bg-defaultBlackLight">
          <div className=" grid grid-cols-4 gap-4">
            {productImages.map((image: string) => (
              <Button
                className="h-fit w-fit p-0 border-0 outline-none cursor-pointer hover:opacity-80"
                disabled={!editable}
                onClick={() => {
                  onInputChange({ value: image, id, name });
                }}
              >
                <img className="w-[80px]" src={image} alt="product feature" />
              </Button>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default ImportProductLongBenefitsImage;
