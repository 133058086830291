/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TfiReload } from 'react-icons/tfi';

import ListCheckBoxGroup from './ListCheckBoxGroup';

import Button from '../common/Button';
import { Input } from '../common/Input';

export interface StaticOptionsProps {
  id: string;
  event: string;
}

export interface OptionGroupProps {
  aiGeneratedOptions: string[];
  aiGeneratedHeader?: string;
  staticOptions?: StaticOptionsProps[];
  staticOptionsHeader?: string;
  inputPlaceholder?: string;
  monthList?: Record<string, any>[];
  selectedMonth?: string;
  handleMonthChange?: (month: string) => void;
  handleResetAiProposional: () => void;
  onOptionChange: (value: string) => void;
  isLoading?: boolean;
  headerText?: string;
  optionEditable?: boolean;
}

function OptionGroup({
  aiGeneratedOptions,
  aiGeneratedHeader,
  staticOptionsHeader,
  inputPlaceholder = '',
  staticOptions,
  monthList,
  selectedMonth,
  handleMonthChange,
  handleResetAiProposional,
  onOptionChange,
  isLoading = false,
  headerText = 'Tapez votre promotion saisonnière vous-même.',
  optionEditable = false,
}: OptionGroupProps) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [optionDisabled, setOptionDisabled] = useState<boolean>(false);
  const [_aiGeneratedOptions, _setAiGeneratedOptions] =
    useState<string[]>(aiGeneratedOptions);

  useEffect(() => {
    onOptionChange(selectedOption ?? '');
  }, [selectedOption]);

  useEffect(() => {
    setOptionDisabled(!!inputValue);
  }, [inputValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target ?? {};
    setSelectedOption(value);
    setInputValue(value);
  };

  // Keeping the current value until new data response available
  useEffect(() => {
    if (aiGeneratedOptions !== undefined) {
      _setAiGeneratedOptions(aiGeneratedOptions);
    }
  }, [aiGeneratedOptions]);

  return (
    <div className="border-solid rounded-xl border-2 border-neutral-800 p-5">
      <div>
        <h3 className="mt-0 text-primary ms-3">{headerText}</h3>
        <Input
          className="bg-white text-gray-500 border-0 ms-0 p-1 placeholder:text-gray-400 placeholder:text-sm placeholder:text-left"
          placeholder={inputPlaceholder}
          data-testid="tryit-url"
          type="url"
          id="amazon-url"
          required
          onBlur={handleInputChange}
          autoComplete="off"
        />
      </div>
      <ListCheckBoxGroup
        className="ms-3"
        button={
          <Button
            onClick={() => {
              handleResetAiProposional();
              setSelectedOption('');
            }}
            className="h-8 w-8 border-0 rounded-lg bg-primary text-white disabled:bg-zinc-600 disabled:cursor-not-allowed"
            disabled={optionDisabled}
            icon={
              <TfiReload
                className={isLoading ? 'animate-reverse-spin' : ''}
                strokeWidth={0.5}
                size={15}
              />
            }
          />
        }
        isLoading={isLoading}
        aiGeneratedOptions={_aiGeneratedOptions}
        staticOptions={staticOptions}
        selectedMonth={selectedMonth}
        handleMonthChange={handleMonthChange}
        monthList={monthList}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        setAiGeneratedOptions={_setAiGeneratedOptions}
        disabled={optionDisabled}
        aiGeneratedHeader={aiGeneratedHeader}
        staticOptionsHeader={staticOptionsHeader}
        optionEditable={optionEditable}
      />
    </div>
  );
}

export default OptionGroup;
