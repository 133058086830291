import { createContext, useContext, useState, useEffect } from 'react';

const GoogleAuthContext = createContext(null);

export const useGoogleAuth = () => useContext(GoogleAuthContext);

export function GoogleAuthProvider({ children }) {
  const [googleAuth, setGoogleAuth] = useState(null);

  useEffect(() => {
    const initGoogleAuth = async () => {
      await window.gapi.load('auth2', async () => {
        const auth2 = await window.gapi.auth2.init({
          client_id:
            '689409297766-7ovd6dldioql37vflprphmdkmk57sslj.apps.googleusercontent.com',
        });
        setGoogleAuth(auth2);
      });
    };

    initGoogleAuth();
  }, []);

  return (
    <GoogleAuthContext.Provider value={googleAuth}>
      {children}
    </GoogleAuthContext.Provider>
  );
}
