/* eslint-disable import/prefer-default-export */
export enum GenerateServices {
  EMAIL_MARKETING = 'email_marketing',
}

export enum GenerateLanguages {
  FRENCH = 'french',
  ENGLISH = 'english',
}

export enum GenerateType {
  ONE_CLICK = 'one_click_generation',
  CUSTOMIZE = 'customize_generation',
}

export enum EmailTypes {
  SEASONAL_PROMOTIONS = 'Seasonal Promotions',
  LIMITED_TIME_OFFERS = 'Limited Time Offers',
  EXCLUSIVE_SALES = 'Exclusive sales',
  REMINDERS_OF_EXPIRED_OFFERS = 'Reminders of Expired Offers',
  IMMINENT_STOCK_OUT = 'Imminent Stock Out',
  RESTOCKING_ANNOUNCEMENTS = 'Restocking announcements',
  PRODUCT_LAUNCH = 'Product Launch',
}
