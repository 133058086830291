/* eslint-disable react/forbid-dom-props */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { IoMdArrowDropup } from 'react-icons/io';

interface ProgressProps {
  value: number;
  tooltip?: boolean;
}

function Progress({ value, tooltip }: ProgressProps) {
  return (
    <>
      <div
        className="flex w-full h-2 bg-white rounded-full overflow-hidden"
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div
          className="flex flex-col justify-center rounded-full overflow-hidden bg-primary text-xs text-white text-center whitespace-nowrap transition duration-500"
          style={{ width: `${value}%` }}
        />
      </div>
      {tooltip && (
        <div
          className="inline-block mt-[-7px]"
          style={{ marginInlineStart: `calc(${value}% - 1.80rem)` }}
        >
          <IoMdArrowDropup className="-mb-[10px] ml-auto mr-auto w-full text-white" />
          <div className="py-1 px-4 bg-white border text-xs font-bold text-primary rounded-sm">
            {value}%
          </div>
        </div>
      )}
    </>
  );
}

export default Progress;
