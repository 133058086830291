/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { map } from 'lodash';
import React from 'react';
import { BiSolidInfoCircle } from 'react-icons/bi';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { Input } from '@/components/common/Input';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/common/shadcn-ui/Form';

interface ApplicationTextFieldProps {
  form: any;
  fieldsCongfig: Record<string, any>;
}

function ApplicationTextField({
  form,
  fieldsCongfig,
}: ApplicationTextFieldProps) {
  return map(
    fieldsCongfig,
    ({ label, placeHolder, tooltip, subLabel }, name) => (
      <FormField
        key={name}
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <div className="flex">
              <FormLabel className="text-base">{label}</FormLabel>
              {tooltip && (
                <div className="flex me-2 ms-5">
                  <a data-tooltip-id={name} className="flex ms-1">
                    <BiSolidInfoCircle className="text-xl text-white bg-[#a238d5] rounded-full p-1" />
                  </a>
                  <ReactTooltip
                    id={name}
                    place="bottom"
                    content={tooltip}
                    className="!text-xs !font-normal !rounded-md !p-1 !px-2 !bg-white !text-black"
                  />
                </div>
              )}
            </div>
            {subLabel && (
              <p
                className="text-xs italic text-orange-300/80 ml-2"
                dangerouslySetInnerHTML={{
                  __html: subLabel,
                }}
              />
            )}
            <FormControl className="w-full">
              <Input
                className="placeholder:opacity-50 h-auto indent-3 py-3 border-zinc-600/60 focus:border-primary"
                placeholder={placeHolder}
                {...field}
              />
            </FormControl>
            <FormMessage className="text-red-400 italic" />
          </FormItem>
        )}
      />
    )
  );
}

export default ApplicationTextField;
