/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Productly from './assets/productly.png';
import RenderAccessButton from './Buttons/RenderAccessButton';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from './common/NavigationMenu';
import { SheetTrigger, SheetContent, Sheet, SheetClose } from './common/Sheet';
import MenuIcon from './common/svg/MenuIcon';

import { mainMenu, MainMenuProps } from '@/shared/configs/mainMenu';

interface CustomNavLinkProps {
  menu: MainMenuProps;
}
function CustomNavLink({ menu }: CustomNavLinkProps) {
  const { hasLink, navLink, text } = menu ?? {};
  const { t } = useTranslation('translation', { keyPrefix: 'home' });
  return (
    <>
      {hasLink && (
        <HashLink className="text-sm no-underline p-7 lg:text-lg" to={hasLink}>
          {t(text)}
        </HashLink>
      )}
      {navLink && (
        <NavLink className="text-sm no-underline p-7 lg:text-lg" to={navLink}>
          {t(text)}
        </NavLink>
      )}
    </>
  );
}

export function MainNav() {
  return (
    <div className="flex justify-around md:block">
      <div className="flex flex-row items-center justify-around">
        <NavLink className="flex" to="/">
          <img
            className="h-[16px] p-[20px_0px] cursor-pointer md:h-[18px] lg:h-[30px]"
            alt="Productly"
            src={Productly}
          />
        </NavLink>
        <NavigationMenu className="hidden md:flex">
          <NavigationMenuList className="!ps-0">
            {mainMenu.map((menu) => (
              <NavigationMenuItem key={menu.text}>
                <CustomNavLink menu={menu} />
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
        <div className="items-center hidden md:flex">
          <RenderAccessButton
            className="text-[14px] px-3 !py-2 lg:text-lg lg:px-6 lg:!py-3"
            iconSize="16"
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="md:hidden">
          <RenderAccessButton
            className="!text-[10px] !px-3 !py-1 !rounded-md !font-thin me-2 md:font-normal md:!text-sm md:!py-3 md:!px-3"
            iconSize="8"
          />
        </div>
        <Sheet>
          <SheetTrigger asChild>
            <span className="md:hidden">
              <MenuIcon className="flex font-light text-white" />
            </span>
          </SheetTrigger>
          <SheetContent
            className="bg-[#121212] flex flex-col items-center pt-4 px-2"
            side="left"
          >
            {mainMenu.map((menu) => (
              <SheetClose asChild key={menu.text}>
                <CustomNavLink menu={menu} />
              </SheetClose>
            ))}
            <SheetClose className="text-lg text-white bg-transparent border-0">
              {`<`} Back
            </SheetClose>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
}
