import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as React from 'react';

import { cn } from '@/lib/utils';

interface RadioGroupItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  icon?: React.JSX.Element;
}

const RadioButtonGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Root
    className={cn('grid gap-2', className)}
    {...props}
    ref={ref}
  />
));
RadioButtonGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioButtonGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <RadioGroupPrimitive.Item
    ref={ref}
    className={cn(
      'text-primary py-3 px-7 w-max data-[state=checked]:bg-primary data-[state=checked]:text-white bg-white rounded-lg border-0 mr-2 font-medium border-primary shadow focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
      className
    )}
    {...props}
  >
    {children}
  </RadioGroupPrimitive.Item>
));
RadioButtonGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, icon, ...props }, ref) => (
  <RadioButtonGroupItem ref={ref} className={className} {...props}>
    <RadioGroupPrimitive.Indicator
      className={cn('flex items-center justify-center text-current')}
    >
      {icon}
    </RadioGroupPrimitive.Indicator>
  </RadioButtonGroupItem>
));

export { RadioButtonGroup, RadioButtonGroupItem, RadioGroupItem };
