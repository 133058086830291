interface LogProps {
  page: string;
  functionName: string;
  payload?: any;
  apiResult?: any;
}

export const logsTheActions = async ({
  page,
  functionName,
  payload = {},
  apiResult = {},
}: LogProps) => {
  fetch(`${process.env.VITE_APP_API_URL}/logs/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('productly')}`,
    },
    body: JSON.stringify({
      page,
      functionName,
      payload: typeof payload === 'object' ? JSON.stringify(payload) : payload,
      apiResult:
        typeof apiResult === 'object' ? JSON.stringify(apiResult) : apiResult,
    }),
  });
};

export default logsTheActions;
