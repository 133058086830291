/* eslint-disable react/button-has-type */
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React from 'react';

import MenuBar from './MenuBar';

import { ScrollArea } from '../ScrollArea';

interface TipTapProps {
  content: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export default function Tiptap({
  content,
  onChange,
  disabled = false,
}: TipTapProps) {
  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          class: 'focus:outline-none !text-sm',
        },
      },
      extensions: [
        TaskList,
        TaskItem,
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false,
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false,
          },
        }),
      ],
      content,
      onUpdate({ editor: textEditor }) {
        onChange(textEditor.getHTML());
      },
    },
    [content]
  );
  return (
    <div className="rounded-lg mt-2 bg-white text-defaultBlack">
      {editor && <MenuBar editor={editor} />}
      <ScrollArea className="h-[500px]">
        <EditorContent
          className="editor__content outline-none px-2"
          editor={editor}
          disabled={disabled}
        />
      </ScrollArea>
    </div>
  );
}
