/* eslint-disable react/button-has-type */
import clsx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';
import { LuLoader2 } from 'react-icons/lu';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  type?: 'button' | 'submit';
  icon?: React.JSX.Element;
  iconPosition?: 'before' | 'after';
  onClick?: () => void;
  children?: any;
}

function Button({
  text = '',
  type = 'button',
  className,
  disabled = false,
  icon,
  loading = false,
  loadingText = '...',
  iconPosition = 'after',
  onClick,
  children,
  ...props
}: ButtonProps) {
  return loading ? (
    <button
      type="button"
      className={clsx(
        'flex justify-center items-center bg-zinc-700 !text-white',
        className
      )}
      disabled
      {...props}
    >
      <LuLoader2
        data-testid="loader-spin"
        className="animate-spin h-5 w-5 !text-white"
      />
      <div className="ml-1">{loadingText}</div>
    </button>
  ) : (
    <button
      type={type}
      className={clsx(
        'cursor-pointer',
        icon && 'flex justify-center items-center',
        className
      )}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children && children}
      {!children && (
        <>
          {iconPosition === 'before' && icon}
          {text}
          {iconPosition === 'after' && icon}
        </>
      )}
    </button>
  );
}

export default Button;
