/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import useCountDown from 'components/hooks/useCountDown';
import React, { useEffect, useState } from 'react';
import '../../components/css/register.css';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Animation from '../../components/common/Animation';

import Header from '@/components/Header';
import { loginSessionCheck } from '@/utils/helpers';

function Verify() {
  const navigate = useNavigate();
  const [searchTokenParams, _] = useSearchParams();
  const [verificationSuccess, setVerificationSuccess] =
    useState<boolean>(false);
  const verifyCode = searchTokenParams.get('token');
  const { timeDown: countDown, setTimeDown: setCountDown } = useCountDown(3);
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const verifyProcess = async () => {
    if (!searchTokenParams) {
      return;
    }
    try {
      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/auth/verify`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            token: verifyCode,
            date: new Date().toISOString().slice(0, 10),
          }),
        }
      );

      if (response.status === 200) {
        toast.success(`${t('verificationPassed')}`);
        setVerificationSuccess(true);
        setTimeout(() => navigate('/login'), 3000);
        return;
      }

      toast.error(`${t('errors.invalidOrExpiredCode')}`);
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('productly');
    const isLogin = loginSessionCheck(token);
    if ((token && isLogin) || !verifyCode) {
      navigate('/app');
      return;
    }
    verifyProcess();
    setCountDown(3);
  }, []);

  return (
    <div>
      <Header />
      <Animation className="form-container">
        <div className="flex flex-col text-center text-white form-parent">
          <div className="text-lg">
            {verificationSuccess
              ? `${t('veryfySuccess')} (${countDown}).`
              : `${t('verifyFailed')}`}
          </div>
        </div>
      </Animation>
    </div>
  );
}

export default Verify;
